import { Box, Dialog } from "@mui/material";
import BetTimer from "../betTimer";

export default function BetLoading({ open, betDelay, isBrowser }) {
  return (
    <Box>
      {isBrowser && open ? (
        <Box className={`betslip-overlay`}>
          <BetTimer betDelay={betDelay} />
        </Box>
      ) : (
        <Dialog
          open={open}
          className="show-bet-loading"
          PaperProps={{
            sx: {
              margin: 0,
              borderRadius: "10px",
              maxWidth: "none",
              width: "51.1%",
            },
          }}
        >
          <BetTimer betDelay={betDelay} />
        </Dialog>
      )}
    </Box>
  );
}
