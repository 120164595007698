import { Box } from "@mui/material";


// LinkText.prototype = {
//     sx:SxProps
// }

export default function LinkText({children, onClick=()=>{},sx={}})
{
    return(
        <Box
            sx={{
                color:"#ffecc6",
                fontSize:14,
                cursor:"pointer",
                "&:hover":{
                    textDecoration:"underline",
                    color:"white"
                },
                ...sx,
            }}
            onClick={onClick}
        >
            {children}
        </Box>
    )
}