import { io } from "socket.io-client";
import { SOCKET_LINK,API_LINK } from "../constants/constants";
import { authHeader } from "../helpers/authHeader";
import { socketOptions } from './../constants/constants';
import { socket as mainSocket } from "./socketServices"
let socket;

const connect = (handleConnection) =>{
    socket = mainSocket
    if(socket) handleConnection(true);
    // socket = io(`${SOCKET_LINK}/v1/event`,{
    //     ...socketOptions,
    //     extraHeaders:{
    //         Authorization:authHeader(),
    //     }
    // })
    // return new Promise((res,rej)=>{
    //     socket.on('connect', function(socket) {
    //         console.log("Event connected");
    //         handleConnection(true)
    //         // setTimeout(()=>{
    //         //     joinUser();
    //         // },500)
    //         res(socket);
    //     });
    //     socket.on('disconnect',function(){
    //         handleConnection(false)
    //     });
    //     socket.once('connect_error', function() {
    //         console.log("error")
    //     });
    //     socket.once('connect_timeout', function() {
    //     });
    // })
}

const joinEvent = (data) =>{
    console.log("event_data",data);
    socket?.emit("join-event-room",JSON.stringify(data))
}

const leaveEvent = (data) =>{
    console.log("event_data",data);
    socket?.emit("event-leave",JSON.stringify(data))
}

const getEventDetails = (setEventData) =>{
    socket?.on("get-event-details",(data)=>{
        if(data && !data.response_error && data.data)
        {
            setEventData(data.data)
        }
    })
}

const joinSport = (data) =>{
    socket?.emit("join-sport-room",JSON.stringify(data))
}

const leaveSport = (data) =>{
    socket?.emit("sport-leave",JSON.stringify(data))
}

const getSportDetails = (setSportData) =>{
    socket?.on("get-sport-details",(data)=>{
        if(data && !data.response_error && data.data)
        {
            setSportData(data.data)
        }
    })
}

const chekUserConnected = (connectToRooms=()=>{}) =>{
    console.log("fhzjdskhzjksd")
    socket?.on('token-verified',(data)=>{
        console.log("IN users-connected - EVENT",data)
        if(data.data.isVerified){
            connectToRooms();
        }
        else{
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace("/");
        }
    })
}

const disconnect = () =>{
    socket?.disconnect();
}

const eventSocket = {
    connect,
    joinEvent,
    leaveEvent,
    getEventDetails,
    joinSport,
    leaveSport,
    disconnect,
    getSportDetails,
    chekUserConnected
}

export default eventSocket;