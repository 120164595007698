import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import accountServices from "../../services/accountServices";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import betServices from "../../services/betServices";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";

export default function BettingPnL(){
    const [groupedBets,setGroupedBets] = useState([]);
    const [marketDetails,setMarketDetails] = useState();
    const {marketId} = useParams();
    const {state} = useLocation();
    const navigate = useNavigate();
    useEffect(()=>{
        if(marketId && state)
        {   
            getBettingPNLByMarket(state.is_casino)
        }
        else{
            getBettingPNL();
        }
    },[marketId,state])

    const getBettingPNL = async() =>{
        const res = await accountServices.getBettingPNL();
        if(res && res.length > 0) {
            setGroupedBets(res);
        }
    }
    const getBettingPNLByMarket = async(is_casino) =>{
        let res;
        if(is_casino)
        {
            res = await accountServices.getBettingPNLByCasino(marketId);
        }
        else{
            res = await accountServices.getBettingPNLByMarket(marketId);
        }
        if(res) {
            setMarketDetails(res);
        }
    }

    const getOutCome = (outcome) =>{
        if(outcome === "won") return "WON";
        if(outcome === "voided") return "VOIDED";
        return "LOST"
    }

    const getVoidReportLink = async(bet_id) =>{
        try {
            const res = await betServices.getVoidReportLink(bet_id);
            console.log(res);
            if(res){
                const response = await fetch(res);
                const html = await response.text();

                // Render the HTML in a hidden div (you can also use a component)
                const container = document.createElement('div');
                container.innerHTML = html;
                container.style.position = 'absolute';
                container.style.left = '-9999px'
                document.body.appendChild(container);
                console.log(html)
                console.log(container)
                html2canvas(container).then((canvas)=>{
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p','mm','a4',true);
                    const pdfW = pdf.internal.pageSize.getWidth();
                    const pdfH = pdf.internal.pageSize.getHeight();
                    const imgW = canvas.width;
                    const imgH = canvas.height;
                    const ratio = Math.min(pdfW/imgW,pdfH/imgH);
                    const imgX = (pdfW-imgW*ratio) / 2;
                    const imgY = 0;
                    console.log(imgData);
                    pdf.addImage(imgData,'PNG',imgX,imgY,imgW*ratio,imgH*ratio);
                    pdf.save("Void-Report.pdf");
                    document.body.removeChild(container);
                })
            }
        } catch (error) {
            
        }
    }
    return(
        <>
            <Box padding={1}>
                <Box className={"page-title"}>Betting Profit & Loss</Box>
                { 
                    marketId ?
                    <Box className={"pnl-breakdown-page"}>
                        <Box className={"pnl-title"}> {marketDetails?.market.event_name} - {marketDetails?.market.market_name} </Box>

                        {/* loop bet */}
                        {
                            marketDetails?.bets?.map((bet)=>{
                                return(
                                    <Box className={"order"}>
                                        <Box className={"wrapper"}>
                                            <Box className={"pnl-info"}>
                                                <Box className={`${bet.type} column truncate`}>
                                                    <Box component={"strong"}> Selection: {bet.runner_name} </Box>
                                                </Box>
                                                <Box className={"bet-info"}>
                                                    <Box component={"strong"}> Bet ID: </Box>  {bet.bet_id_ui} 
                                                </Box>
                                                <Box className={"bet-info"}>
                                                    <Box component={"strong"}> Placed: </Box>  {moment.unix(bet.matched_at).format("DD/M/YYYY HH:mm:ss")} 
                                                </Box>
                                            </Box>
                                            <Box className={"pnl-titles"}>    
                                                <Box>Status:</Box>
                                                <Box>Profit/Loss:</Box>
                                            </Box>
                                            <Box className={"pnl-numbers"}>
                                                <Box><Box component={`span`} className={`${bet.is_win ?"positive" :"negative"}`}> {getOutCome(bet.outcome)} </Box></Box>
                                                <Box><Box component={`span`} className={`${bet.is_win ?"positive" :"negative"}`}> {bet.is_win?bet.profit:bet.liability} </Box></Box>
                                            </Box>
                                        </Box>
                                        <Box className={"wrapper-full-screen"}>
                                            <Box className={"row bet-props"}>
                                                <Box className={"col -width-30"}>
                                                    <Box component={"strong"}>TYPE</Box>
                                                </Box>
                                                <Box className={"col -width-30"}>
                                                    <Box component={"strong"}>Odds</Box>
                                                </Box>
                                                <Box className={"col -width-30"}>
                                                    <Box component={"strong"}>Stake</Box>
                                                </Box>
                                            </Box>
                                            <Box className={"row"}>
                                                <Box sx={{textTransform:"capitalize"}} className={"col -width-30"}>{bet.type}</Box>
                                                <Box className={"col -width-30"}>{bet.odds}</Box>
                                                <Box className={"col -width-30"}>{bet.bet_amount}</Box>
                                            </Box>
                                        </Box>
                                        {/* {
                                            bet.outcome === "voided" &&
                                            <Stack direction="row-reverse" mt={2}>
                                                <Button onClick={()=>getVoidReportLink(bet.bet_id)} variant="contained" >Download Report</Button>
                                            </Stack>
                                        } */}
                                    </Box>
                                )
                            })
                        }
                        

                        {/* summary */}
                        <Box className={"summary"}>
                            <Box className={"subtotal"}>
                                {
                                    marketDetails?.total.details?.reverse().map((type)=>{
                                        return(
                                        <Box className={"row"}>
                                            <Box sx={{"::first-letter":{textTransform:"capitalize"}}} className={"col -width-70 pnl-label"}> {type.type} subtotal: </Box>
                                            <Box className={"col -width-70 number"}>{type.totalProfitLoss}</Box>
                                        </Box>
                                        )
                                    })
                                }
                                {
                                    !state?.is_casino &&
                                    <>
                                        <Box className={"row"}>
                                            <Box className={"col -width-70 pnl-label"}>Market subtotal:</Box>
                                            <Box className={"col -width-70 number"}>{marketDetails?.total.totalAfterProfitLossBeforeCommission}</Box>
                                        </Box>
                                        <Box className={"row"}>
                                            <Box className={"col -width-70 pnl-label"}>Commission:</Box>
                                            <Box className={"col -width-70 number"}>{marketDetails?.total.totalCommission || 0.00}</Box>
                                        </Box>
                                    </>
                                }
                            </Box>
                            <Box className={"row net-market"}>
                                <Box className={"col -width-70 pnl-label"}>
                                    <Box component={"strong"}>Net Market Total:</Box>
                                </Box>
                                <Box className={"col -width-70 number"}>
                                    <Box component={"strong"}>{marketDetails?.total.totalProfitLossAfterCommission}</Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    :
                  groupedBets.length > 0 ?
                    <Box className={"pnl-list"}>
                        {
                            groupedBets.map((group)=>{
                                return(
                                    <Box className={"date-section"}>
                                        <Box className={"date"}>
                                            <Box component={"span"}>{moment(group._id).format("Do MMMM YYYY")}</Box>
                                            <Box component={"span"} className="total">P&L: 
                                            <Box component={"span"} className={`value ${group.profitAndLoss>=0?"positive":"negative"}`}> {group.profitAndLoss}</Box></Box>
                                        </Box>
                                        {
                                            group.markets?.map((market)=>{
                                                return(
                                                    <Box className="wrapper">
                                                        <Box className="pnl-info">
                                                            <Box className="truncate">
                                                                <Box onClick={()=>navigate(market.provider_market_id,{state:{is_casino:market.is_casino}})} component={"strong"}>{market.event_name} - {market.market_name}</Box>
                                                            </Box>
                                                            <Box className="market-info">
                                                                <Box component={"strong"}>Start time: </Box>  {moment.unix(market.event_time).format("DD/M/YYYY HH:mm:ss")}
                                                            </Box>
                                                            <Box className="market-info">
                                                                <Box component={"strong"}>Settled Time: </Box>  {moment.unix(market.settled_at).format("DD/M/YYYY HH:mm:ss")} 
                                                            </Box>
                                                        </Box>
                                                        <Box className="pnl-titles">
                                                            <Box><Box component={"strong"}>Comm:</Box></Box>
                                                            <Box><Box component={"strong"}>Net Win:</Box></Box>
                                                        </Box>
                                                        <Box className="pnl-numbers">
                                                            <Box><Box component={`span`} className={`${market.commission_amount>0?"positive":""} ${market.commission_amount<0?"negative":""}`}>{market.commission_amount?.toFixed(2)}</Box></Box>
                                                            <Box>
                                                                <Box component={"span"} className={`${(market.profitAndLoss)>0?"positive":""} ${(market.profitAndLoss)<0?"negative":""}`}>
                                                                    {market.profitAndLoss}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    :
                <Typography lineHeight={0} fontSize={"14px"}>There are no settled bets in the last 7 days.</Typography>
                }
            </Box>
        </>
    )
}