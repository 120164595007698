import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function HomeBanner() {
  const navigate = useNavigate();

  const images = [
    {
      image: 'https://d2eb1cys5jgnub.cloudfront.net/b/a/instant-games.webp',
      caption: 'instant games',
      link: '/live-casino/tab/100003',
    },
    {
      image: 'https://d2eb1cys5jgnub.cloudfront.net/b/a/casino-aviator.webp',
      caption: 'aviator',
      link: '/live-casino/tab/100003',
    },
    {
      image: 'https://d2eb1cys5jgnub.cloudfront.net/b/a/football.webp',
      caption: 'Football',
      link: '/sport/1',
    },
    {
      image: 'https://d2eb1cys5jgnub.cloudfront.net/b/a/cricket.webp',
      caption: 'Cricket',
      link: '/sport/4',
    },
    {
      image: 'https://d2eb1cys5jgnub.cloudfront.net/b/a/horse-racing.webp',
      caption: 'Horse Racing',
      link: '/racing/7',
    },
    {
      image: 'https://d2eb1cys5jgnub.cloudfront.net/b/live-casino-1.webp',
      caption: 'Live Casino',
      link: '/live-casino',
    },
  ];

  return (
    <Box
      sx={{
        height: '150px',
        backgroundColor: 'transparent',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Swiper
        className="main-banners-style"
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        cssMode={true}
      >
        {images.map((item, index) => (
          <SwiperSlide
            key={`${item.caption}-${index}`}
            style={{
              height: '150px',
              overflow: 'auto',
            }}
          >
            <Box onClick={() => navigate(item.link)}>
              <img
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  objectFit: 'cover',
                  height: '150px',
                }}
                src={item.image}
                alt={item.caption}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
