import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    user_socket:{
        isConnected:false,
    },
    odds_socket:{
        isConnected:false,
    },
    event_socket:{
        isConnected:false,
    },
    sport_socket:{
        isConnected:false,
    }
};

const socketSlice = createSlice({
    name:"socket",
    initialState:initialState,
    reducers:{
        userConnected:(state,action)=>{
            state.user_socket.isConnected = true;
        },
        userDisconnected:(state,action)=>{
            state.user_socket.isConnected = false;
        },
        oddsConnected:(state,action)=>{
            state.odds_socket.isConnected = true;
        },
        oddsDisconnected:(state,action)=>{
            state.odds_socket.isConnected = false;
        },
        eventConnected:(state,action)=>{
            state.event_socket.isConnected = true;
        },
        eventDisconnected:(state,action)=>{
            state.event_socket.isConnected = false;
        },
        sportConnected:(state,action)=>{
            state.sport_socket.isConnected = true;
        },
        sportDisconnected:(state,action)=>{
            state.sport_socket.isConnected = false;
        },
    }
})

export const {userConnected,userDisconnected,oddsConnected,oddsDisconnected,eventConnected,eventDisconnected,sportConnected,sportDisconnected} = socketSlice.actions;

export default socketSlice.reducer;