import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSportById } from "../utils/functions";
// import { compitionData, raceData } from "../config/data";
import { useSelector } from "react-redux";
// import { getLeaguesBySport } from "../services/eventServices";
// import eventServices from "../services/eventServices";
import eventServices from "../services/eventServices";

import NestedItem from "./Nesteditem";
// import { getGameByMenuId } from "../services/gameService";
// import { BusinessMenuContext } from "../context/business-menu-context";
import CustomIcon from "./customIcon";
import moment from "moment";
// import { Racescontext } from "../context/races-context";
import { findByKeyInArray, groupBy } from "../utils/functions";
import { isMobile } from "react-device-detect";
export default function SideMenu() {
  // const { businessMenu } = useContext(BusinessMenuContext);
  const { menu } = useSelector((state) => state.sports);
  const { sports } = useSelector((state) => state.sports);
  const [openLeauge, setOpenLeague] = useState(true);
  const navigate = useNavigate();
  // const { sportId, compId,marketId, eventId } = useParams();
  const params = useParams();
  const styles = {
    floating: {
      position: "absolute",
      top: "0",
      right: "20px",
      width: "312px",
    },
    static: {
      display: "table-cell",
      verticalAlign: "top",
      width: "312px",
    },
    switchOn: {
      ".toggle-alternate input ~ :checked ~ .switch": { left: "30.25px" },
    },
    sticky: {
      position: "fixed",
      width: "312px",
      top: "0",
    },
  };
  // console.log("useParams",params)
  // console.log(params)
  const [sport, setSport] = useState();
  const [showMainMenu, setShowMainMenu] = useState(true);
  const [isRace, setIsRace] = useState(false);
  const { isLogginIn } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const sportsData = useSelector((state) => state.sportsData.desktop);
  const [compitionData, setCompitionData] = useState([]);

  // const {races} = useContext(Racescontext)
  const [races, setRaces] = useState([]);
  const [currentSport, setCurrentSport] = useState();
  useEffect(() => {
    if (sports && sports.length > 0) {
      let sport = findByKeyInArray(sports, "provider_game_id", params?.sportId);
      // console.log(sport);
      if (sport) setCurrentSport(sport);
    }
  }, [sports, params]);
  const getLeagueBySportIdFunc = async (game_id) => {
    try {
      // console.log('getLeagueBySportIdFunc', game_id);
      const response = await eventServices.getLeaguesBySport(game_id);
      // console.log('response',response)
      setCompitionData(response);
    } catch (error) {
      console.error(`Error Fetching League By Sport Id: `, error);
    }
  };
  const getRaces = async (game_id) => {
    const res = await eventServices.getRacesBySport(currentSport?.game_id);
    if (res && res.length > 0) {
      setRaces([...res]);
    }
  };
  const handleGameClick = (item) => {
    let link = item.link;
    if (!item.link) {
      if (item.type === "Casino") link = `/live-casino/${item.provider_id}`;
      else if (item.type === "Sport") {
        if (item.provider_id === "7" || item.provider_id === "4339") {
          let linkrace = "/sport/racing/";
          if (isMobile) {
            linkrace = "/racing/";
          }
          link = `${linkrace}${item.provider_id}`;
        } else {
          link = `/sport/${item.provider_id}`;
        }
      }
    }

    return link;
  };

  useEffect(() => {
    if (sport) {
      if (isRace) {
        // console.log("isRace", sport.game_id);
        getRaces(sport.game_id);
      } else {
        getLeagueBySportIdFunc(sport.game_id);
      }
    }
  }, [sport, isRace]);

  useEffect(() => {
    const paths = pathname.split("/");
    // console.log(params, 'params', params?.sportId);
    // console.log("params...............",sports)
    if (
      params?.sportId &&
      params?.sportId !== "10" &&
      params?.sportId !== "9"
    ) {
      console.log("params...............", sportsData);
      setShowMainMenu(false);
      if (getSportById(params?.sportId, sports))
        setSport(getSportById(params?.sportId, sports));
    } else if (paths.includes("account")) {
      setSport(null);
      setShowMainMenu(false);
    } else setShowMainMenu(true);
    if (params?.sportId === "4339" || params?.sportId === "7") setIsRace(true);
    else setIsRace(false);
  }, [params, pathname, sportsData.sportsData]);

  const renderMainMenuItem = (item) => {
    return (
      <Box
        key={item.menu_name}
        sx={{
          paddingLeft: "0",
          minHeight: "60px",
          boxShadow: "inset 0 -1px 0 rgba(73, 87, 105, 0.1)",
          cursor: "pointer",
          width: "100%",
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          ":hover": {
            transform: "scale(1.1)",
            transformOrigin: "center center",
            boxShadow:
              "inset 0 1px 0 rgba(73, 87, 105, 0.1), inset 0 -1px 0 rgba(73, 87, 105, 0.1)",
            transition: "transform ease 0.1s",
            background: "#f5f5f5",
          },
        }}
        onClick={() => navigate(handleGameClick(item))}
      >
        <Stack
          direction={"row"}
          pl={"20px"}
          spacing={"10px"}
          alignItems={"center"}
        >
          {/* <Box sx={{ width: 18, height: 18, bgcolor: "grey" }}></Box> */}
          <CustomIcon
            name={item.icon}
            className={"lm-" + item?.icon}
            fontSize={18}
          />
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              lineHeight: "16px",
              textTransform: "capitalize",
            }}
          >
            {item.menu_name}
          </Typography>
        </Stack>
      </Box>
    );
  };
  return (
    <List
      sx={{
        height: "100%",
        width: "186px",
        borderLeft: "1px solid #ddd",
        borderRight: "1px solid #ddd",
        background: "white",
        overflow: "hidden",
        display: "table-cell",
        verticalAlign: "top",
        py: 0,
      }}
    >
      {showMainMenu ? (
        <>
          {/* {
          isLogginIn && renderMainMenuItem({menu_name:"Favorites",icon:"favorite",link:"/favorite"})
        } */}
          {menu.map((item, index) => {
            return renderMainMenuItem(item);
          })}
        </>
      ) : (
        <>
          {/* {isLogginIn && (
            <Box className={"groupitem"} onClick={() => navigate("/favorite")}>
              <Stack
                direction={"row"}
                pl={"13px"}
                spacing={"10px"}
                alignItems={"center"}
              >
                <CustomIcon name={"favorite"} fontSize={18} />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 700, lineHeight: "16px" }}
                >
                  Favourites
                </Typography>
              </Stack>
            </Box>
          )} */}
          {isLogginIn && (
            <Box
              className={"groupitem"}
              onClick={() => navigate("/sport/my-markets")}
            >
              <Stack
                direction={"row"}
                pl={"13px"}
                spacing={"10px"}
                alignItems={"center"}
              >
                <CustomIcon name={"mymarkets"} fontSize={18} />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 700, lineHeight: "16px" }}
                >
                  My Markets
                </Typography>
              </Stack>
            </Box>
          )}
          <Box className={"groupitem"} onClick={() => navigate("/sport")}>
            <Stack
              direction={"row"}
              pl={"13px"}
              spacing={"10px"}
              alignItems={"center"}
            >
              <CustomIcon name={"home"} fontSize={18} />
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, lineHeight: "16px" }}
              >
                Sports
              </Typography>
            </Stack>
          </Box>
          {sport && (
            <Box
              className={"groupitem"}
              onClick={() =>
                navigate(
                  params?.sportId == 7 || params?.sportId == 4339
                    ? `/sport/racing/${params?.sportId}`
                    : `/sport/${params?.sportId}`
                )
              }
              sx={{
                bgcolor: "#8C1000",
                ":hover": { bgcolor: `#780e00 !important` },
              }}
            >
              <Stack
                direction={"row"}
                pl={"13px"}
                spacing={"10px"}
                alignItems={"center"}
              >
                {/* <Box sx={{ width: 18, height: 18, bgcolor: "white" }}></Box> */}
                <CustomIcon
                  name={sport.game_name}
                  fontSize={18}
                  color={"#fff"}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    color: "#fff",
                    lineHeight: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {sport.game_name}
                </Typography>
              </Stack>
            </Box>
          )}
          {isRace
            ? races.map((race) => {
                return (
                  <Box
                    onClick={() =>
                      navigate(
                        `/sport/racing/${params?.sportId}/${race.game_event_id}/${race.market_id}`
                      )
                    }
                    className={`group-name ${
                      race.market_id.toString() === params.marketId
                        ? "active"
                        : ""
                    }`}
                    sx={{
                      borderLeftColor:
                        race.market_id.toString() === params.marketId
                          ? `${sport.bgcolor} !important`
                          : "none",
                      bgcolor:
                        race.market_id.toString() === params.marketId
                          ? `${sport.bgcolor}12 !important`
                          : "none",
                    }}
                  >
                    {race.market_time &&
                      moment.unix(race.market_time).format("HH:mm")}{" "}
                    {race.event_name}
                  </Box>
                );
              })
            : sport && compitionData
            ? compitionData.map((comp) => {
                return (
                  <NestedItem
                    openLeauge={openLeauge}
                    setOpenLeague={setOpenLeague}
                    sportId={params?.sportId}
                    comp={comp}
                    compId={params?.leagueId}
                    sport={sport}
                  />
                );
              })
            : ""}
        </>
      )}
    </List>
  );
}
