import { API } from "../constants/constants";
import { handleResponse } from "../utils/functions";
import { server } from "../utils/server"



const login = (data) =>{
    return server.post(API.LOGIN,data)
    .then((res)=>{
        return res.data;
    }).catch((err)=>{
        console.log(err);
    })
}

const updateDeafultPassword = (userId,data) =>{
    return server.put(`${API.CHANGE_DEFAULT_PASSWORD}${userId}`,data)
    .then((res)=>{
        return res.data;
    }).catch((err)=>{
        console.log(err);
    })

}
const updatePassword = (userId,data) =>{
    return server.put(`${API.CHANGE_PASSWORD}${userId}`,data)
    .then((res)=>{
        return res.data;
    }).catch((err)=>{
        console.log(err);
        if(err && err.response && err.response.data) return err.response.data
    })
}

const acceptCondition = (userId,data) =>{
    return server.put(`${API.ACCEPT_CONDITION}${userId}`,data)
    .then((res)=>{
        return res.data;
    }).catch((err)=>{
        console.log(err);
    })
}

const getBalance = () =>{
    return server.get(`${API.BALANCE}`)
    .then((res)=>handleResponse(res.data))
    .catch((err)=>{
        console.log(err);
    })
}

const updateUserSettings = (userId,data) =>{
    return server.put(`${API.CHANGE_USER_STAKE}${userId}`,data)
    .then((res)=>handleResponse(res.data))
    .catch((err)=>{
        console.log(err);
    })
}

const generateTokenForTwoFactor = () =>{
    return server.get(`${API.TWO_FACTOR_GENERATE}`)
    .then((res)=>handleResponse(res.data))
    .catch((err)=>{
        console.log(err);
    })
}

const activateTwoAuth = (data) =>{
    return server.post(`${API.TWO_FACTOR_ACTIVATE}`,data)
    .then((res)=>handleResponse(res.data))
    .catch((err)=>{
        console.log(err);
        throw err;
    })
}

const deactivateTwoAuth = (data) =>{
    return server.post(`${API.TWO_FACTOR_DEACTIVATE}`,data)
    .then((res)=>handleResponse(res.data))
    .catch((err)=>{
        console.log(err);
        throw err;
    })
}

const loginTwoAuth = (data) =>{
    return server.post(API.TWO_FACTOR_LOGIN,data)
    .then((res)=>{
        return res.data;
    }).catch((err)=>{
        console.log(err);
    })
}

const authServices = {
    login,
    updateDeafultPassword,
    acceptCondition,
    updatePassword,
    getBalance,
    updateUserSettings,
    generateTokenForTwoFactor,
    activateTwoAuth,
    deactivateTwoAuth,
    loginTwoAuth
}

export default authServices;