import axios from "axios";
import { API_LINK } from "../constants/constants";


export const server = axios.create({
  baseURL: `${API_LINK}/api/`,
  responseType: 'json',
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // Authorization: authHeader(),
  },
});