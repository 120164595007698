import React, { useEffect, useRef, useState, useContext } from "react";
import { Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import BetSlip from "./betSlip";
import MyBets from "../pages/profilePages/myBets";
import { BetSlipContext } from "../context/bet-slip-context";
import { isBrowser } from "react-device-detect";
import CustomIcon from "./customIcon";
import {
  changeActiveOneClickStake,
  changeOneClickStake,
  changeOneClickStatus,
} from "../redux/reducers/stakes";
import authServices from "../services/authServices";
import { calcProfit } from "../utils/functions";

export default function RightSidePane() {
  const [openBets, setOpenBets] = useState(0);
  const { currentTab, changeTab } = useContext(BetSlipContext);
  const { sportId } = useParams();
  const [floating, setFloating] = useState(false);
  const [openSlip, setOpenBetSlip] = useState();
  const [sticky, setSticky] = useState(false);
  const [stickyTop, setStickyTop] = useState(0);
  const stickyRef = useRef();
  const [bettrue, setbettrue] = useState(false);
  const [prevExpo, setPrevExpo] = useState();
  const { user: userData, balanceData } = useSelector((state) => state.user);
  const [balance, setBalance] = useState({
    available_credit: 0,
    credit_limit: 0,
    total_net_exposure: 0,
    winnings: 0,
  });
  const [exposure, setExposure] = useState();

  const handleChange = (event, newValue) => {
    changeTab(newValue);
  };

  const { bets } = useSelector((state) => state.bets);
  const { pathname } = useLocation();
  useEffect(() => {
    // Function to reinitialize or reset the component
    const reinitializeComponent = () => {
      // Add your reinitialization logic here
      console.log("Route changed. Reinitializing component...");
      // Example: Resetting betCount or any other state
      setbettrue(false);
      setTimeout(() => {
        setbettrue(true);
      }, 200);
    };

    reinitializeComponent();
  }, [pathname]); // The effect will run when the location changes

  useEffect(() => {
    if (bets.length > 0) {
      setOpenBetSlip(bets[0]?.props?.openSlip);
    }
  }, [bets]);

  useEffect(() => {
    if (stickyRef.current) {
      const stickyEl = stickyRef.current.getBoundingClientRect();
      setStickyTop(stickyEl.top + window.scrollY);
    }
  }, []);

  useEffect(() => {
    // if (!stickyTop) return;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setSticky(scrollTop >= stickyTop + 25);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stickyTop]);

  const styles = {
    floating: {
      position: "absolute",
      top: "0",
      right: "20px",
      width: "312px",
      zIndex: "9",
    },
    static: {
      display: "table-cell",
      verticalAlign: "top",
      width: "312px",
      height: "100%",
    },
    sticky: {
      position: "fixed",
      width: "312px",
      top: "0px",
    },
  };
  const calculateExposure = (stake, odds, runner_id, market_id, side) => {
    let profit = 0;
    console.log(stake, odds, runner_id, market_id, "lkfjdslkf");
    let temp = {};
    if (
      bets[0]?.props?.market?.market_id &&
      bets[0]?.props?.market?.market_id === market_id
    ) {
      bets[0].props.market?.runners.forEach((runner) => {
        if (runner.runner_id.toString() === runner_id) {
          if (side === "back") {
            profit =
              calcProfit(
                odds,
                stake,
                bets[0].props.market.market_type === "match_odd"
              ) || 0;
          } else if (side === "lay") {
            profit =
              -calcProfit(
                odds,
                stake,
                bets[0].props.market.market_type === "match_odd"
              ) || 0;
          }
        } else {
          if (side === "back") {
            profit = -stake || 0;
          } else if (side === "lay") {
            profit = stake || 0;
          }
        }
        if (prevExpo && prevExpo[runner.runner_id]) {
          profit = profit + prevExpo[runner.runner_id];
        }
        temp[runner.runner_id] = profit;
      });

      setExposure(temp);
    } else {
      setExposure(null);
    }
  };
  useEffect(() => {
    if (balanceData) setBalance(balanceData);
  }, [balanceData]);

  useEffect(() => {
    const path = pathname.split("/");
    if (path.includes("account") || sportId === "10") {
      setFloating(true);
    } else {
      setFloating(false);
    }
  }, [pathname, sportId]);

  return (
    <Box className="bet-m" sx={styles.static}>
      <AvailableCredit balance={balance} />
      {!floating && (
        <Box
          ref={stickyRef}
          sx={floating ? styles.floating : sticky ? styles.sticky : ""}
        >
          <OneClickSetting />
          <Typography
            sx={{
              padding: "10px 0",
              marginBottom: "10px",
              borderBottom: "1px solid rgba(15, 35, 39, 0.4)",
              color: "#0f2327",
              lineHeight: "19px",
              fontSize: "0.875rem",
            }}
          >
            Betslip
          </Typography>
          <Box className="account-overview">
            <Tabs
              value={currentTab}
              onChange={handleChange}
              className="nav-tabs-right"
            >
              <Tab
                disableRipple
                label="Betslip"
                className={`nav-tab-right ${
                  currentTab === "BETSLIP" ? "active" : ""
                }`}
                value="BETSLIP"
              />
              <Tab
                disableRipple
                value="BETS"
                label={`Open Bets (${openBets})`}
                className={`nav-tab-right ${
                  currentTab === "BETS" ? "active" : ""
                }`}
              />
            </Tabs>
            <Box
              className="betslip-ui"
              display={currentTab === "BETSLIP" ? "block" : "none"}
            >
              {bets.length > 0 && isBrowser && bets[0].props.odds !== "-" ? (
                <Box>
                  <BetSlip
                    slipSide={bets[0].props?.side}
                    isFancy={bets[0].props?.market?.market_type === "fancy"}
                    wholeOdds={
                      bets[0].props?.market?.market_type === "match_odd"
                    }
                    onCancel={() => setOpenBetSlip(null)}
                    line={
                      bets[0].props?.market?.market_type === "fancy"
                        ? bets[0].props.odds
                        : null
                    }
                    event={bets[0].props?.event}
                    market={bets[0].props?.market}
                    runner={bets[0].props?.runner}
                    odds={
                      bets[0].props?.market?.market_type === "fancy"
                        ? bets[0].props.size
                        : bets[0].props.odds
                    }
                    calculateExposure={calculateExposure}
                    oddsChange={
                      bets[0].props?.market?.market_type === "match_odd"
                        ? true
                        : false
                    }
                  />
                </Box>
              ) : (
                <Box className="empty-list-info">
                  Click on the odds to add selections to the betslip.
                </Box>
              )}
            </Box>
            {bettrue ? (
              <Box display={currentTab === "BETS" ? "block" : "none"}>
                <MyBets className="mybet-side" betCount={setOpenBets} />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const AvailableCredit = ({ balance }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const handleClick = (e) => {
    if (!open) setOpen(true);
    else {
      ref?.current?.classList.remove("-animate");
      ref?.current?.classList.add("hidden");
      setTimeout(() => {
        setOpen(false);
        ref?.current?.classList.remove("hidden");
      }, 200);
    }
  };

  return (
    <Box className="account-overview">
      <Box
        onClick={handleClick}
        className={`toggleable-list-title ${open ? "list-opened" : ""}`}
      >
        <Box component="span">
          <Box component="strong">Available Credit:</Box>
          <Box component="span" className="value">
            &nbsp;{balance?.available_credit}
          </Box>
        </Box>
        <Box className="toggle-icon">
          <CustomIcon
            name={open ? "sm-up-arrow" : "sm-down-arrow"}
            color="#1f5058"
            fontSize={14}
          />
        </Box>
      </Box>
      <Box
        ref={ref}
        className={`list-wrap ${open ? "shown -animate" : ""}`}
        component="ul"
      >
        <Box component="li">
          <Box component="strong">Credit Limit:</Box>&nbsp;
          {balance?.credit_limit}
        </Box>
        <Box component="li">
          <Box component="strong">Winnings:</Box>&nbsp;
          <Box
            component="span"
            className={balance?.winnings > -1 ? "-positive" : "-negative"}
          >
            {balance?.winnings}
          </Box>
        </Box>
        <Box component="li">
          <Box component="strong">Total Net Exposure:</Box>&nbsp;
          <Box component="span" className="-negative">
            {balance?.total_net_exposure}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const OneClickSetting = () => {
  const [openOneClick, setOpenOneClick] = useState(false);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const { user: userData } = useSelector((state) => state.user);
  const { active_one_click_stake } = useSelector((state) => state.stake);
  const { user_id, stake, mobile_stake } = userData;
  const { is_one_click_bet_enable, one_click_stake } = useSelector(
    (state) => state.stake
  );

  const [newValues, setNewValues] = useState(one_click_stake);
  const [editMode, setEditMode] = useState(false);

  const styles = {
    switchOn: {
      ".toggle-alternate input ~ :checked ~ .switch": { left: "30.25px" },
    },
  };

  const handleChange = (value, index) => {
    const temp = newValues;
    temp[index] = value;
    setNewValues([...temp]);
  };

  const saveValues = () => {
    updateOneClick(true);
  };

  const cancelValues = () => {
    setEditMode(false);
    setNewValues(one_click_stake);
  };

  const handleActiveClick = (value) => {
    setSelected(value);
    dispatch(changeActiveOneClickStake({ active_one_click_stake: value }));
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleChangeStatus = () => {
    updateOneClick();
  };

  const updateOneClick = async (is_one_click_enable) => {
    try {
      const data = {
        is_one_click_bet_enable: is_one_click_enable,
        stake: stake,
        mobile_stake: mobile_stake,
        one_click_stake: newValues,
        user_id,
      };
      const res = await authServices.updateUserSettings(user_id, data);
      dispatch(changeOneClickStatus(is_one_click_enable));
      dispatch(changeOneClickStake(newValues));
      setEditMode(false);
    } catch (error) {
      console.error("Failed to update one click settings", error);
    }
  };

  const handleEditStakeToggleClick = async () => {
    await updateOneClick(!is_one_click_bet_enable);
  };

  return (
    <Box
      className={`oneClick-wrapper one-click-bet-settings ${
        editMode ? "edit-mode-on" : "select-mode-on"
      } ${is_one_click_bet_enable ? "one-click-active" : ""}`}
    >
      <Box
        onClick={handleEditStakeToggleClick}
        className="oneClick-switch small"
        sx={is_one_click_bet_enable ? styles.switchOn : {}}
      >
        <Box
          className={`toggle-bg toggle-alternate ${
            is_one_click_bet_enable ? "on" : "off"
          }`}
        >
          <Box
            component="label"
            className={is_one_click_bet_enable ? "on" : "off"}
          >
            {is_one_click_bet_enable ? "on" : "off"}
          </Box>
          <input type="radio" checked={false} />
          <input type="radio" checked={true} />
          <Box
            component="span"
            className={`switch ${is_one_click_bet_enable ? "on" : "off"}`}
          />
        </Box>
      </Box>
      <Box className="one-click-settings-animation-wrap" sx={{ pb: "40px" }}>
        <Box component="strong" className="one-click-title">
          1 Click Betting {is_one_click_bet_enable}
        </Box>
        <Box className="one-click-bet-form">
          <Box className="select-mode-fields">
            <Box component="ul" className="settings-list">
              {one_click_stake.map((v, i) => (
                <Box key={i} component="li" className="setting-select-only">
                  <Button
                    onClick={() => dispatch(changeActiveOneClickStake(i))}
                    variant="contained"
                    className={`small ${
                      active_one_click_stake === i ? "selected" : ""
                    }`}
                    sx={{ height: "auto" }}
                  >
                    {v}
                  </Button>
                </Box>
              ))}
              <Button
                onClick={() => {
                  setEditMode(true);
                  setNewValues([...one_click_stake]);
                }}
                variant="success"
                className="small"
                sx={{
                  height: "auto",
                  float: "right",
                  color: "var(--white)",
                  "&:hover": {
                    color: "var(--primary)",
                  },
                }}
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box className="edit-mode-fields">
            <Box
              component="ul"
              className="settings-list"
              sx={{ display: "flex" }}
            >
              {newValues.map((v, i) => (
                <Box key={i} component="li" className="setting-input-wrap">
                  <TextField
                    type="number"
                    value={v}
                    onChange={(e) => handleChange(e.target.value, i)}
                    className="setting-input small"
                    variant="outlined"
                    sx={{
                      flex: 1,
                      height: "auto",
                      "& .MuiInputBase-input": {
                        padding: "3px",
                        paddingLeft: "4px",
                      },
                    }}
                  />
                </Box>
              ))}
              <Button
                onClick={saveValues}
                variant="success"
                className="small"
                sx={{
                  height: "auto",
                  float: "right",
                  color: "var(--white)",
                  "&:hover": {
                    color: "var(--primary)",
                  },
                }}
              >
                Save
              </Button>
              <Box
                onClick={() => setEditMode(false)}
                className="btn-cancel-editing"
                sx={{ display: is_one_click_bet_enable ? "block" : "none" }}
              >
                Cancel
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
