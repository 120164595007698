import { API } from "../constants/constants";
import { handleCatch, handleResponse } from "../utils/functions";
import { server } from "../utils/server";



const createbet = (data) =>{
    return server.post(`${API.CREATE_BET}`,data)
    .then((res)=>handleResponse(res.data))
    .catch(handleCatch)
}

const getUserTotalExposure = () =>{
    return server.get(`${API.USER_EXPORSURE}`)
    .then((res)=>handleResponse(res.data))
    .catch(handleCatch)
}

const getUserOpenBets = (bet_type) =>{
    return server.get(`${API.OPEN_BETS}${bet_type}`)
    .then((res)=>handleResponse(res.data))
    .catch(handleCatch)
}

const cancelUnmatchBets = (bet_id)=>{
    return server.get(`${API.CANCEL_BETS}${bet_id}`)
    .then((res)=>res.data)
    .catch(handleCatch)
}

const getVoidReportLink = (bet_id) =>{
    return server.get(`${API.VOIDREPORT}?betId=${bet_id}`)
    .then((res)=>handleResponse(res.data))
    .catch(handleCatch)
}

export const openBet = async (bet_type) => {
    try {
      const response = await server.get(
        `/api/bet/open-bet?bet_type=${bet_type}`
      );
      return handleResponse(response.data, true);
    } catch (error) {
      console.error(`Error Fetching Open Bets: ${error}`);
    }
  };
const betServices = {
    createbet,
    getUserTotalExposure,
    getUserOpenBets,
    cancelUnmatchBets,
    getVoidReportLink
}

export default betServices;