import {useContext, useEffect, useState } from "react"
import eventServices from "../services/eventServices"
import { useDispatch, useSelector } from "react-redux";
import { saveSports,saveCategories,saveCasino, saveMenu } from "../redux/reducers/sport";
// import {
//     getGameBusinessMenu,
//     getGameByMenuId,
//   } from "../services/gameService";
import axios from "axios";
import { clearUser } from "../redux/reducers/user";
import { updateSportsData } from "../redux/reducers/sports";
import { updateMenuData} from "../redux/reducers/menu";
import { sports } from "../config/data";
import { clearStake } from "../redux/reducers/stakes";
import { userDisconnected } from "../redux/reducers/socket";
import userSocket from "../services/userSocket";
import { useNavigate } from "react-router";
import { getDomainName, lightenHexColor } from "../utils/functions";
import { Box, Skeleton, Stack } from "@mui/material";
import LoadingSkelaton from "./loadingSkelaton";


export default function Initialization(){
    const dispatch = useDispatch();
    const { category } = useSelector(state=>state.sports);
    const { isLogginIn } = useSelector(state=>state.user);
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    useEffect(()=>{
        getMenu();
        getAllCategories();
        getConfigJson();
        getFavIcon();
        
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        detectDevTools();
        // Cleanup to prevent memory leaks
        return () => {
            clearInterval(detectDevTools);
            document.removeEventListener('contextmenu', event => event.preventDefault());
        };
    },[isLogginIn])

    useEffect(()=>{
        // console.log("fbgdhfghjfg");
        if(category && category.length > 0)
        {
           let casino =  category.find((c)=>c.game_type === "casino");
           let sport =  category.find((c)=>c.game_type === "sport");

           if(sport) getAllSports(sport.menu_id);
           if(casino) getAllCasino(casino.menu_id);
        }
    // eslint-disable-next-line
    },[category])

    const getAllSports = async() =>{
        const res = await eventServices.getAllSports();
        if(res && res.length > 0)
        {
            dispatch(saveSports(res));
        }
    }

    const getAllCategories = async() =>{
        const res = await eventServices.getAllCategories();
        if(res && res.length > 0)
        {
            dispatch(saveCategories(res));
        }
    }
    
    const getAllCasino = async(menu_id) =>{
        const res = await eventServices.getAllSportsByCategory(menu_id);
        if(res && res.length > 0)
        {
            let temp = res.sort((a,b)=>Number(a.provider_game_id)-Number(b.provider_game_id))
            dispatch(saveCasino(temp));
        }
    }

    // const getBusinessMenu = async () => {
    //     const response = await getGameBusinessMenu();
    //     if (response) {
    //       setbusinessMenu(response);
    //     }
    //   };
    
    //   useEffect(() => {
    //     getBusinessMenu();
    //   }, []);
    

    const getMenu = async() =>{
        const res = await eventServices.getMenu();
        // console.log("res........",res)
        if(res ){
            dispatch(saveMenu(res))
        }
    }
    const menuData = useSelector((state) => state.menuDesktop.desktop);
    const getMenuData = async () => {
        try {
          const response = await eventServices.getMenu();
          dispatch(updateMenuData(response));
          const sportsResponse = await getGameByMenuIdFunc(
            response.find((game) => game.type === "sport").provider_id
          );

        } catch (error) {
          console.error("Error Fetching Menu Data", error);
        }
      };

    const getGamesByMenuId = async (menuId, actionCreator) => {
        try {
          
          const response = await getGameByMenuIdFunc(menuId);
          // console.log("response1",menuId)
 
          const modifiedResponse = response.map((res) => {
            return {
              ...res,
              bgcolor: sports.find((sport) => sport.id === res.provider_game_id)
                ?.bgcolor,
              hoverColor: sports.find((sport) => sport.id === res.provider_game_id)
                ?.hoverColor,
            };
          });
          dispatch(actionCreator(modifiedResponse));
        } catch (error) {
          console.error(`Error Fetching Games for Menu Id ${menuId}`, error);
        }
      };
    
      const getGameByMenuIdFunc = async (menuId) => {
        try {
          const response = await eventServices.getLeaguesBySport(menuId);
          // console.log("getLeaguesBySport",response)
          return response;
        } catch (error) {
          console.error("Error Fetching Game By Menu Id", error);
        }
      };
      useEffect(() => {
        // console.log("menuData",menuData.menuData[0])

        if (menuData.menuData) {
          const sportsMenu = menuData.menuData.find(
            (menu) => menu.type === "Sport"
          );
        // console.log("sportsMenu",sportsMenu)
          const casinoMenu = menuData.menuData.find(
            (menu) => menu.game_type === "casino"
          );
    
          if (sportsMenu) {
            getGamesByMenuId(sportsMenu.provider_id, updateSportsData);
          }
    
          if (casinoMenu) {
            // getCasinoGames();
            // getGamesByMenuId(casinoMenu.menu_id, updateSportsData);
          }
        }
      }, [dispatch, menuData.menuData]);
    
      useEffect(() => {
        getMenuData();
      }, []);

    const getConfigJson = async(defaultDomain)=>{
        try {
            const domain = getDomainName();
            setLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_CONFIG_BUCKET_LINK}/${defaultDomain ? defaultDomain :domain}/business.json`,{headers:{cache:"no-cache"}})
            if(res && res.data){
              document.title = res.data.title
                const data =  res.data

            // purple theme
            //   const data =  {
            //     "business":"Kumkum",
            //     "baseUrl":"https://maverick777.com",
            //     "primary_color":"#440a57",
            //     "secondary_color":"#560d6e",
            //     "accent_color":"#fab841",
            //     "btn_color":"#560d6e",
            //     "btn_text_color":"#FFFFFF",
            //     "header_btn_color":"#fab841",
            //     "header_btn_text_color":"#000",
            //     "is_b2b":true,
            //     "template_name":"lotusbook365-b2b",
            //     "title":"Kumkum"
            // }
            // coral theme
            //   const data =  {
            //     "business":"Kumkum",
            //     "baseUrl":"https://maverick777.com",
            //     "primary_color":"#010230",
            //     "secondary_color":"#132C6C",
            //     "accent_color":"#FEE600",
            //     "header_btn_color":"#FEE600",
            //     "header_btn_text_color":"#000",
            //     "is_b2b":true,
            //     "template_name":"lotusbook365-b2b",
            //     "title":"Kumkum"
            // }
            // coral theme
            // const data =  {
            //     "business":"Kumkum",
            //     "baseUrl":"https://maverick777.com",
            //     "primary_color":"#084D8E",
            //     "secondary_color":"#07294C",
            //     "accent_color":"#E03B49",
            //     "btn_color":"#084D8E",
            //     "btn_text_color":"#FFFFFF",
            //     "header_btn_color":"#FFBD2F",
            //     "header_btn_text_color":"#084D8E",
            //     "is_b2b":true,
            //     "template_name":"lotusbook365-b2b",
            //     "title":"Kumkum"
            // }
             
              const r = document.querySelector(':root');
              //primary colors
            r.style.setProperty('--primary', data.primary_color);
            r.style.setProperty('--header-background', data.primary_color);
            r.style.setProperty('--event-tab-background-color', data.primary_color);
            r.style.setProperty('--super-casino-lobby-bg', data.primary_color);

            // secondary colors
            r.style.setProperty('--secondary', data.secondary_color);
            
            //accent_color
            r.style.setProperty('--accent_color', data.accent_color);


            r.style.setProperty('--btn_color', data.btn_color);
            r.style.setProperty('--btn_text_color', data.btn_text_color);
            r.style.setProperty('--header-right-button-menu-bg-color', data.header_btn_color);
            r.style.setProperty('--header-right-button-signup-color', data.header_btn_text_color);
            
            r.style.setProperty('--event-tab-color', "#fdfdff8a");
            // r.style.setProperty('--login-background', `url(abc)`);
            setLoading(false)
            }
        } catch (error) {
            // if(defaultDomain !== "maverick777"){
            //     console.info(error,"fjdklfjkl")
            //     getConfigJson("maverick777")
            // }
            const data =  {
                "business":"Lotus",
                "baseUrl":window.location.origin,
                "primary_color":"#0f2327",
                "secondary_color":"#163439",
                "accent_color":"#2c4f58",
                "btn_color":"#2c4f58",
                "btn_text_color":"#FFFFFF",
                "header_btn_color":"#2c4f58",
                "header_btn_text_color":"#FFFFFF",
                "is_b2b":true,
                "template_name":"lotusbook365-b2b",
                "title":"Lotus"
            }
            document.title = data.title
              const r = document.querySelector(':root');
              //primary colors
            r.style.setProperty('--primary', data.primary_color);
            r.style.setProperty('--header-background', data.primary_color);
            r.style.setProperty('--event-tab-background-color', data.primary_color);
            r.style.setProperty('--super-casino-lobby-bg', data.primary_color);

            // secondary colors
            r.style.setProperty('--secondary', data.secondary_color);
            
            //accent_color
            r.style.setProperty('--accent_color', data.accent_color);


            r.style.setProperty('--btn_color', data.btn_color);
            r.style.setProperty('--btn_text_color', data.btn_text_color);
            r.style.setProperty('--header-right-button-menu-bg-color', data.header_btn_color);
            r.style.setProperty('--header-right-button-signup-color', data.header_btn_text_color);
            
            r.style.setProperty('--event-tab-color', "#fdfdff8a");
            setLoading(false)
        }
    }

    const getFavIcon = async(defaultDomain)=>{
        try {
            const domain = getDomainName();
            const result = await fetch(`${process.env.REACT_APP_CONFIG_BUCKET_LINK}/${defaultDomain ? defaultDomain :domain}/favicon.ico`, { method: 'HEAD',cache: 'no-cache' });
            if(result.ok){
                const faviconDoc = document.getElementById("favicon");
                if(faviconDoc){
                    faviconDoc.href = `${process.env.REACT_APP_CONFIG_BUCKET_LINK}/${defaultDomain ? defaultDomain :domain}/favicon.ico`
                }
            }
            else{
                getFavIcon("maverick777")
            }
        } catch (error) {
            console.info(error,"fjdklfjkl")
            getFavIcon("maverick777")
        }
    }
const detectDevTools = () =>{
    document.addEventListener('contextmenu', event => event.preventDefault());
    setInterval(()=>checkLoggedIn(isLogginIn),1000)
}


const checkLoggedIn = (isLogginIn) =>{
    const before = Math.round(performance.now());
    // eval("debugger;");
    // eval("console.info('hello world')");
    const after = Math.round(performance.now());

    if(after - before >1){
        // console.info(isLogginIn,"fhfdkjhkjdfhjksdhf")
        if(isLogginIn){
            // handleLogout();
        }
        // alert("logout")
    }
}

const handleLogout = () =>{
    dispatch(clearUser());
    dispatch(clearStake());
    dispatch(userDisconnected());
    userSocket.disconnect();
    navigate("",{replace:true})
    window.location.reload();
}


if(loading){
    return(
        <Box
            sx={{
                position:"fixed",
                width:"100%",
                height:"100%",
                bgcolor:"white",
                zIndex:9999
            }}
        >
            <Stack spacing={0.5}>
                <Skeleton variant='rectangular' height={63} />
                <Skeleton variant='rectangular' height={150} />
                <LoadingSkelaton />
                <LoadingSkelaton />
                <LoadingSkelaton />
            </Stack>
        </Box>
    )
}
return (<></>)
}