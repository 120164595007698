import { useEffect, useState } from "react"
import eventServices from "../../services/eventServices"
import { Box, Stack } from "@mui/material"
import CustomIcon from "../../components/customIcon"
import { useNavigate } from "react-router-dom"
import { getIP } from "../../utils/functions"
import sportBook from "../../services/sportsbook"



export default function SportBook(){
    const [iframeLink,setIframeLink] = useState("");
    const [loading,setLoading] = useState(false);
    const getSportbooklink = async ()=>{
        try {
            setLoading(true);
            // const ip = await getIP();
            // const data = {
            //     clientIp:ip,
            // }
            // const res = await eventServices.getSportbook(data)
            // setIframeLink(res.url);
            
            const res = await sportBook.getSportLink();
            setLoading(false);
            console.log(res);
            setIframeLink(res.url);
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        getSportbooklink();
    },[])

    return (
        <Box className={`casino-page ${iframeLink?"open":""}`}>
            {
                loading &&
                <Loader />
            }
            <Header />
            {iframeLink &&
                <Box component={"iframe"}
                    width={"100%"}
                    height={"100%"}
                    src={`${iframeLink}`}
                    flex={1}
                />
            }
                
        </Box>
    )
}

const Header = () =>{
    let navigate = useNavigate();
    const handleBackClick = () =>{
            navigate("/");
    }
    return(
        <Box className="casino-header">
            <Box className="title-wrap">
                <Stack width={60} justifyContent={"center"} alignItems={"center"}>
                    <CustomIcon onClick={handleBackClick} name={"back-arrow"} color={"#fff"} />
                </Stack>
                {
                    <Box>Back to Site</Box>
                }
            </Box>
        </Box>
    )
}

const Loader = () =>{
    return(
        <Box
            sx={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: 'var(--spinner-background-color)',
                zIndex: '999'
            }}
        >
            <Box
            sx={{
                position:"absolute",
                width:40,
                height:40,
                left:"50%",
                top:"50%",
                transform:"translate(-50%,-50%)"
            }}
            >
            <CustomIcon className={"spinner"} name={"loading"} fontSize={28} color={"white"} />
            </Box>
        </Box>
    )
}