import { configureStore } from '@reduxjs/toolkit';
import user from './reducers/user';
import stakes from './reducers/stakes';
import bets from './reducers/bets';
import menu from './reducers/menu';
import sports from "./reducers/sport"
import sockets from './reducers/socket';
import sportsData from './reducers/sports';

const persistedState = sessionStorage.getItem('reduxState') 
                       ? JSON.parse(sessionStorage.getItem('reduxState'))
                       : {}

const store = configureStore({
    reducer:{
        user:user,
        stake:stakes,
        bets:bets,
        sports:sports,
        menuDesktop: menu,
        sockets:sockets,
        sportsData:sportsData
    },
    preloadedState:persistedState
});


store.subscribe(()=>{
    // save a copy to sessionStorage
    sessionStorage.setItem('reduxState', JSON.stringify(store.getState()))
  })

export default store;