import { Box, Typography } from "@mui/material";
import SideMenu from "../components/sideMenu";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { getCompNameById, getSportNameById } from "../utils/functions";
import { useEffect, useState } from "react";
import RightSidePane from "../components/rightSidePane";
import { useSelector } from "react-redux";
import eventServices from "../services/eventServices";

// import { getEventsByLeague } from "../services/eventServices";
// import RaceProvider from "../context/races-context";

export default function MenuLayout() {
  const params = useParams();
  // console.log('sportId', params);
  const [title, setTitle] = useState(
    params?.compId
      ? getCompNameById(params?.compId)
      : params?.sportId
      ? getSportNameById(params?.sportId)
      : ""
  );

  const [showTitle, setShowTitle] = useState();

  const { pathname } = useLocation();
  // console.log('sportId', pathname);

  const { isLogginIn } = useSelector((state) => state.user);

  const pageTitles = [
    {
      link: "sport",
      title: "Cricket",
    },
    {
      link: "mybets",
      title: "My Bets",
    },
    {
      link: "betProfitLoss",
      title: "Betting Profit and Loss",
    },
    {
      link: "accountStatement",
      title: "Account Statement",
    },
    {
      link: "transferStatement",
      title: "Transfer Statement",
    },
    {
      link: "security",
      title: "Security",
    },
    {
      link: "changePassword",
      title: "Change Password",
    },
    {
      link: "favorite",
      title: "Favorites",
    },
  ];

  useEffect(() => {
    const path = pathname.split("/").slice(-1).pop();
    if (params?.eventId || path === "race") setShowTitle(false);
    else setShowTitle(true);
  }, [pathname, params?.eventId]);

  const getLeagueBySportIdFunc = async (comp_id) => {
    try {
      const response = await eventServices.getEventsByLeague(comp_id);
      setTitle(response[0].league_name);
      //   setCompitionData(response);
    } catch (error) {
      console.error(`Error Fetching League By Sport Id: `, error);
    }
  };

  useEffect(() => {
    if (params?.compId) {
      getLeagueBySportIdFunc(params?.compId);
    } else {
      setTitle(
        params?.sportId ? getSportNameById(params?.sportId) : params?.sportId
      );
    }
  }, [params, title]);

  return (
    <Box
      sx={{
        display: "table",
        margin: "auto",
        overflow: "hidden",
        zIndex: "1",
        position: "relative",
        minHeight: "100vh",
        height: "100%",
        width: "100%",
        padding: "0",
        maxWidth: "1254px",
      }}
    >
      {/* <RaceProvider > */}
      <SideMenu />
      <Box
        sx={{
          display: "table-cell",
          verticalAlign: "top",
          padding: "0 20px 20px",
          maxWidth: "calc(1254px - 186px - 312px)",
        }}
      >
        {/* {showTitle && (
            <Typography
              variant="h1"
              sx={{
                color: "#0f2327",
                marginBottom: "10px",
                padding: "10px 0 10px 0",
                borderBottom: "1px solid rgba(15, 35, 39, 0.4)",
              }}
            >
              {params?.compId?getCompNameById(params?.compId):params?.sportId ? getSportNameById(params?.sportId):pageTitles.find(p=>p.link === pathname.split("/").slice(-1).pop())?.title}
            </Typography>
          )} */}

        <Outlet />
      </Box>
      {/* </RaceProvider> */}
      {isLogginIn && <RightSidePane />}
    </Box>
  );
}
