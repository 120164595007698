
import { io } from "socket.io-client";
import { API_LINK, SOCKET_LINK } from "../constants/constants";
import { authHeader } from "../helpers/authHeader";
import { parse } from "cookie";

import { socket as mainSocket } from "./socketServices"

let socket;

const connect = (handleConnection) => {
    socket = mainSocket;
    if(socket) handleConnection(true)
    // socket = io(`${API_LINK}/v1/user`, {
    //     ...socketOptions,
    //     withCredentials: true,
    //     extraHeaders: {
    //         Authorization: authHeader(),
    //     }
    // })

    // const COOKIE_NAME = process.env.SECRET_KEY;

    // socket.io.on("open", () => {
    //     socket.io.engine.transport.on("pollComplete", () => {
    //         const request = socket.io.engine.transport.pollXhr.xhr;
    //         const cookieHeader = request?.getResponseHeader("set-cookie");
    //         if (!cookieHeader) {
    //             return;
    //         }
    //         cookieHeader.forEach(cookieString => {
    //             if (cookieString.includes(`${COOKIE_NAME}=`)) {
    //                 const cookie = parse(cookieString);
    //                 socket.io.opts.extraHeaders = {
    //                     cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`
    //                 }
    //             }
    //         });
    //     });
    // });


    // return new Promise((res, rej) => {
    //     socket.on('connect', function (socket) {
    //         console.log("user connected");
    //         handleConnection(true)
    //         // setTimeout(()=>{
    //         //     joinUser();
    //         // },500)
    //         res(socket);
    //     });
    //     socket.on('disconnect', function () {
    //         handleConnection(false)
    //     });
    //     socket.once('connect_error', function () {
    //         console.log("error")
    //     });
    //     socket.once('connect_timeout', function () {
    //     });
    // })
}

const joinUser = () => {
    // setTimeout(() => {
        socket?.emit("join-user-room", "")
    // }, 500)
}

const getBalanceData = (setBalance) => {
    // console.log("skjhsjkafhjska")
    socket?.on("get-user-balance", (data) => {
        console.info(data, "user-balance");
        if (data && data.data) {
            setBalance(data.data)
        }
    })
}

const getExposureAndOpenBets = (handleChange) => {
    socket?.on("refresh-match-list", (data) => {
        handleChange();
    })
}

const logoutUser = (handleLogout) => {
    socket?.on("log-out", (data) => {
        console.log(data, "log-out");
        if (data && data.data && data.data.log_out) {
            handleLogout()
        }
    })
}

const userError = (handleLogout) => {
    socket?.on("user-error", (data) => {
        console.log(data, "user-error");
        if (data && data.status_code == 401) {
            handleLogout()
        }
    })
}

const disconnect = () => {
    socket?.emit("user-leave", "")
    // socket.disconnect();
}

const chekUserConnected = (connectToRooms=()=>{}) =>{
    socket?.on('token-verified',(data)=>{
        console.log("IN users-connected - SPORT",data)
        if(data.data.isVerified){
            connectToRooms(true);
        }
        else{
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace("/");
        }
    })
}


const userSocket = {
    connect,
    getBalanceData,
    getExposureAndOpenBets,
    joinUser,
    disconnect,
    logoutUser,
    userError,
    chekUserConnected
}

export default userSocket;