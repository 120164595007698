import { API } from "../constants/constants";
import { handleCatch, handleResponse } from "../utils/functions";
import { server } from "../utils/server";



const getCasinoGame = (data) =>{
    return server.post(`${API.CASINO_GAME}`,data)
    .then((res)=>handleResponse(res.data))
    .catch(handleCatch)
}

const getEvolIframe = (game_id,clientIp,redirectURl) =>{
    return server.post(`${API.EVOLUTION_GAME}`,{gameId:game_id?.toString(),clientIp,redirectUrl:redirectURl})
    .then((res)=>handleResponse(res.data))
    .catch((err)=>{
        console.log(err);
    })
}

const casinoServices = {
    getCasinoGame,
    getEvolIframe
}

export default casinoServices;