import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import CustomIcon from "../components/customIcon";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeftSideBarTabs } from "../utils/sidebar";
import eventServices from "../services/eventServices";
import {
  addNewField,
  calcProfit,
  findByKeyInArray,
  groupBy,
} from "../utils/functions";
import { useSelector } from "react-redux";
import moment from "moment";
import { RUNNERSTATUS } from "../constants/constants";
import BetButton from "../components/betButton";
import LoadingSkelaton from "../components/loadingSkelaton";
import socketServices from "../services/socketServices";
import BetSlip from "../components/betSlip";
import { LoginContext, ToastContext } from "../context/context";
import sportSocket from "../services/sportSocket";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function Racing() {
  const [selectedTab, setSelectedTab] = useState();
  const [loading, setLoading] = useState(false);
  const [currentSport, setCurrentSport] = useState();
  const { sports } = useSelector((state) => state.sports);
  const [races, setRaces] = useState([]);
  const [selectedRace, setSelectedRace] = useState();
  const [upcomingRaces, setUpcomingRaces] = useState([]);
  const { isLogginIn } = useSelector((state) => state.user);
  const params = useParams();
  const handleSportDataRef = useRef();
  const handleReconnectionRef = useRef();

  handleSportDataRef.current = function (data) {
    if (data && data.length > 0) {
      let newArray = addNewField(data, "time", (obj) =>
        moment.unix(obj.market_time).format("HH:mm")
      );
      let temp = newArray;
      if (
        !selectedTab ||
        (selectedTab.index === 0 &&
          selectedTab.market_id !== temp[0]?.market_id)
      ) {
        setSelectedTab({
          market_id: temp[0]?.market_id,
          event_id: temp[0]?.game_event_id,
          index: 0,
        });
      }
      setRaces(temp);
    }
  };

  useEffect(() => {
    if (handleSportDataRef && handleSportDataRef.current) {
      sportSocket.getSportDetails((data) => handleSportDataRef.current(data));
    }
  }, [handleSportDataRef]);

  useEffect(() => {
    if (sports && sports.length > 0) {
      let sport = findByKeyInArray(sports, "provider_game_id", params?.sportId);
      if (sport) setCurrentSport(sport);
    }
  }, [sports, params]);

  useEffect(() => {
    if (currentSport) {
      getEventsBySport();
      joinSportRoom(currentSport.game_id);
    }
    // eslint-disable-next-line
  }, [currentSport]);
  useEffect(() => {
    handleReconnectionRef.current();
    sportSocket.chekUserConnected(handleReconnectionRef.current);
    return () => {
      if (currentSport) {
        const data = {
          gameId: currentSport?.game_id,
        };
        sportSocket.leaveSport(data);
      }
    };
    // eslint-disable-next-line
  }, [currentSport, isLogginIn]);

  useEffect(() => {
    if (selectedTab || params?.marketId) {
      console.log(selectedTab, "jsfhdj");
      getMakets();
    }
  }, [selectedTab, params]);

  handleReconnectionRef.current = function () {
    console.log("IN UseEffect Sport", currentSport, isLogginIn);
    if (currentSport && isLogginIn) {
      joinSportRoom(currentSport.game_id);
    }
  };
  // const joinSport = () =>{
  // }

  const getEventsBySport = async () => {
    setLoading(true);
    const res = await eventServices.getRacesBySport(currentSport?.game_id);
    // setLoading(false);
    if (res && res.length > 0) {
      handleSportDataRef.current(res);
      // setEvents(groupBy(res,"event_name"))
    }
  };

  const getMakets = async () => {
    // console.log("isRace", params?.eventId);
    // console.log("isRace", params?.marketId);
    try {
      setLoading(true);
      const res = await eventServices.getRacesRunnersBySport(
        params?.eventId ? params?.eventId : selectedTab.event_id,
        params?.marketId ? params?.marketId : selectedTab.market_id
      );
      setLoading(false);
      console.log(res);
      if (res && res.market_details) {
        setSelectedRace(res.market_details);
        let newArray = addNewField(res.market_list, "time", (obj) =>
          moment.unix(obj.market_time).format("HH:mm")
        );
        setUpcomingRaces(newArray);
        // let temp = newArray.filter((_event)=>moment.unix(_event.market_time).isSameOrAfter(moment())).sort((a,b)=>Number(a.market_time)-Number(b.market_time));

        // setSelectedTab({market_id:temp[0].market_id,event_id:temp[0].game_event_id})
        // setRaces(temp)
        // setEvents(groupBy(res,"event_name"))
      }
    } catch (error) {}
  };

  const onSelectRace = (data) => {
    setSelectedTab(data);
  };

  const joinSportRoom = (gameId) => {
    const data = {
      gameId,
    };
    console.log(data);
    sportSocket.joinSport(data);
  };

  const data = [
    {
      time: "14:23",
      name: "Belmont Park (AUS)",
    },
    {
      time: "15:25",
      name: "Kenilworth (ZAF)",
    },
    {
      time: "16:00",
      name: "Kenilworth (ZAF)",
    },
    {
      time: "16:35",
      name: "Kenilworth (ZAF)",
    },
    {
      time: "17:10",
      name: "Kenilworth (ZAF)",
    },
    {
      time: "17:10",
      name: "Kempton (ZAF)",
    },
  ];
  return (
    <>
      <Box className={"sport-name-wrap"}>
        <CustomIcon
          name={
            LeftSideBarTabs.filter(
              (_sports) => _sports.id === Number(params?.sportId)
            )[0]?.icon
          }
          fontSize={"22px"}
        />
        <Typography sx={{ fontSize: "14px", marginLeft: "18px" }}>
          {
            LeftSideBarTabs.filter(
              (_sports) => _sports.id === Number(params?.sportId)
            )[0]?.name
          }
        </Typography>
      </Box>
      {isBrowser ? (
        <></>
      ) : (
        <Tabs
          variant="scrollable"
          value={selectedTab}
          onChange={(e, v) => onSelectRace(v)}
          aria-label="scrollable auto tabs example"
          className="nav-tabs-group-container"
        >
          {races?.map((_data, i) => {
            return (
              <Tab
                value={{
                  market_id: _data.market_id,
                  event_id: _data.game_event_id,
                  index: i,
                }}
                sx={{
                  padding: "0 15px !important",
                  minWidth: "fit-content",
                  maxWidth: "fit-content",
                }}
                disableRipple={true}
                label={`${_data.time} ${_data.event_name.toUpperCase()}`}
                className={`nav-tab ${
                  selectedTab.market_id === _data.market_id ? "active" : ""
                }`}
              />
            );
          })}
        </Tabs>
      )}
      {loading ? (
        <LoadingSkelaton />
      ) : (
        <Box padding={isMobile ? 1 : 0}>
          <Box className={"market-title"}>
            <Typography fontWeight={600} fontSize={"14px"}>
              {selectedRace?.event_name}
            </Typography>
          </Box>
          <Box className={"market-alt-name"} sx={{ fontSize: "14px" }}>
            {selectedRace?.market_name}
          </Box>
          <RaceRunners selectedRace={selectedRace} />
          {upcomingRaces.length > 0 && (
            <Box className={"upcoming-races__wrapper"}>
              <Box
                className={"upcoming-races__header-wrapper"}
                fontSize={"14px"}
              >
                Upcoming {selectedRace?.event_name} races:
              </Box>
              {upcomingRaces.map((e) => {
                return (
                  <Box
                    onClick={() =>
                      onSelectRace({
                        market_id: e.market_id,
                        event_id: e.game_event_id,
                      })
                    }
                    className={"upcoming-races__item"}
                    fontSize={"14px"}
                  >
                    <Box>{e.time}</Box>
                    <Stack direction={"row"} spacing={0.5}>
                      <CustomIcon name={"stopwatch-race"} fontSize={"14px"} />
                      <CustomIcon name={"0percent"} fontSize={"14px"} />
                      <CustomIcon name={"right-arrow"} fontSize={"14px"} />
                    </Stack>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

const RaceRunners = ({ selectedRace }) => {
  const [slipSide, setSlipSide] = useState("back");
  const [openSlip, setOpenBetSlip] = useState();
  const [prevExpo, setPrevExpo] = useState();
  const [exposure, setExposure] = useState();
  const [liveOdds, setLiveOdds] = useState();
  const { isLogginIn } = useSelector((state) => state.user);
  const { setOpenLogin } = useContext(LoginContext);
  const { showToast } = useContext(ToastContext);
  useEffect(() => {
    if (selectedRace && isLogginIn) {
      let roomName = `${selectedRace?.provider_event_id}/${selectedRace?.provider_market_id}`;
      let data = {
        roomNames: roomName,
        marketType: "match_odd",
      };
      console.log(data);
      socketServices.joinRoom(data);
      socketServices.listenEvent(roomName, handleLiveOdds);
    }
  }, [selectedRace, isLogginIn]);

  const handleLiveOdds = (data) => {
    // console.log(data)
    if (data && !data.response_error) {
      setLiveOdds(data.data);
    }
  };

  useEffect(() => {
    if (!openSlip) calculateExposure();
  }, [openSlip]);

  const calculateExposure = (stake, odds, runner_id, market_id, side) => {
    let profit = 0;
    console.log(stake, odds, runner_id, market_id, "lkfjdslkf");
    let temp = {};
    if (selectedRace?.market_id === market_id) {
      selectedRace?.runners.forEach((runner) => {
        if (runner.runner_id.toString() === runner_id) {
          if (side === "back") {
            profit =
              calcProfit(
                odds,
                stake,
                selectedRace?.market_type === "match_odd"
              ) || 0;
          } else if (side === "lay") {
            profit =
              -calcProfit(
                odds,
                stake,
                selectedRace?.market_type === "match_odd"
              ) || 0;
          }
        } else {
          if (side === "back") {
            profit = -stake || 0;
          } else if (side === "lay") {
            profit = stake || 0;
          }
        }
        if (prevExpo && prevExpo[runner.runner_id]) {
          profit = profit + prevExpo[runner.runner_id];
        }
        temp[runner.runner_id] = profit;
      });

      setExposure(temp);
    } else {
      setExposure(false);
    }
  };

  const handleClick = (side, runner_id) => {
    if (isLogginIn) {
      setSlipSide(side);
      setOpenBetSlip(runner_id);
    } else {
      setOpenLogin(true);
    }
  };

  useEffect(() => {
    if (selectedRace) {
      getExposureByMarket();
    }
    // eslint-disable-next-line
  }, [selectedRace]);

  useEffect(() => {
    if (showToast && showToast.bet) getExposureByMarket();
    // eslint-disable-next-line
  }, [showToast]);

  const getExposureByMarket = async () => {
    const res = await eventServices.getExposureByMarket(
      selectedRace.provider_market_id
    );
    // console.log(res);
    if (res && res.length > 0) {
      const marketExpo = res[0];
      let temp = {};
      marketExpo.runners.forEach((r) => {
        temp[r.runner_id] = r.profit;
      });
      // console.log(temp);
      setPrevExpo(temp);
    } else {
      setPrevExpo(null);
    }
  };
  return (
    <Box className={"race-market"}>
      {liveOdds
        ? liveOdds?.runners?.map((e) => {
            return (
              <Box className={"bet-panel"}>
                <Box className={"market"}>
                  <Box className={"market-title-with-pnl"}>
                    <Box className={"runner-details"}>
                      <Box
                        className={"full-width-racing-title"}
                        sx={{ width: "100%" }}
                      >
                        {/* <h1>ahahah</h1> */}
                        {/* <Box className={"float-container number-ids"}>
                                            <Box className={"number-ids"}>
                                                <Typography fontSize={"11px"} variant="body3">2</Typography>
                                                <Typography fontSize={"11px"} variant="body3">{`(2)`}</Typography>
                                            </Box>
                                        </Box> */}
                        {/* <Box className={"float-container"}>
                                            <Box className={"race-symbol"} sx={{float:'left'}}>
                                                <img style={{maxWidth:"fit-content"}} className={"symbol-img"} src={"/assets/images/race-img.png"} alt="" />
                                            </Box>
                                        </Box> */}
                        <Box className={"float-container runner-name"}>
                          <Box className={"title truncate"}>
                            <Box className={"runner-name"}>{e.runner_name}</Box>
                          </Box>
                          {/* <Box className={"jockey-name"}>A Domeyr</Box> */}
                          <Box className={"pnl"}>
                            {prevExpo && (
                              <Box
                                component={"span"}
                                className={`${
                                  prevExpo[e.runner_id] > 0 ? "positive" : ""
                                } ${
                                  prevExpo[e.runner_id] < 0 ? "negative" : ""
                                }`}
                              >
                                {`${
                                  prevExpo[e.runner_id] != null
                                    ? Math.trunc(prevExpo[e.runner_id])
                                    : ""
                                }`}
                              </Box>
                            )}
                            {exposure && exposure[e.runner_id] && (
                              <Box
                                component={"span"}
                                className={`${
                                  exposure[e.runner_id] > 0 ? "positive" : ""
                                } ${
                                  exposure[e.runner_id] < 0 ? "negative" : ""
                                }`}
                              >
                                &nbsp;{`» ${Math.trunc(exposure[e.runner_id])}`}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {e.back && (
                    <Box className={"market-odds"}>
                      {/* <Box className={"odds-line"}> */}
                      {/* <Box className={"odds-line -swiped-to-left"}> */}
                      <Box className={"market-odds__container "}>
                        {RUNNERSTATUS.SUSPENDED === e.provider_status && (
                          <Box className={"suspended"}>
                            <Box className={"inner"}>SUSPENDED</Box>
                          </Box>
                        )}
                        <Box className={"bet-buttons not-selected scrollable"}>
                          <BetButton
                            event={selectedRace}
                            openSlip={openSlip}
                            market={selectedRace}
                            runner={e}
                            market_type={"match_odd"}
                            customClass={"odds-2"}
                            onClick={() => handleClick("back", e.runner_id)}
                            odds={e.back[0]?.price}
                            size={e.back[0]?.size}
                            side={"back"}
                          />
                        </Box>
                        <Box className={"bet-buttons not-selected scrollable"}>
                          <BetButton
                            event={selectedRace}
                            openSlip={openSlip}
                            market={selectedRace}
                            runner={e}
                            customClass={"odds-2"}
                            market_type={"match_odd"}
                            onClick={() => handleClick("lay", e.runner_id)}
                            odds={e.lay[0]?.price}
                            size={e.lay[0]?.size}
                            side={"lay"}
                          />
                        </Box>
                      </Box>
                      {/* </Box> */}
                    </Box>
                  )}
                </Box>
                {e.back && openSlip === e.runner_id && isMobile && (
                  <BetSlip
                    isFancy={selectedRace?.market_type === "fancy"}
                    wholeOdds={selectedRace?.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    event={selectedRace}
                    market={selectedRace}
                    runner={e}
                    calculateExposure={calculateExposure}
                    odds={e[slipSide][0].price}
                  />
                )}
              </Box>
            );
          })
        : selectedRace?.runners?.map((e) => {
            return (
              <Box className={"bet-panel"}>
                <Box className={"market"}>
                  <Box className={"market-title-with-pnl"}>
                    <Box className={"runner-details"}>
                      <Box
                        className={"full-width-racing-title"}
                        sx={{ width: "100%" }}
                      >
                        {/* <h1>ahahah</h1> */}
                        {/* <Box className={"float-container number-ids"}>
                                                <Box className={"number-ids"}>
                                                    <Typography fontSize={"11px"} variant="body3">2</Typography>
                                                    <Typography fontSize={"11px"} variant="body3">{`(2)`}</Typography>
                                                </Box>
                                            </Box> */}
                        {/* <Box className={"float-container"}>
                                                <Box className={"race-symbol"} sx={{float:'left'}}>
                                                    <img style={{maxWidth:"fit-content"}} className={"symbol-img"} src={"/assets/images/race-img.png"} alt="" />
                                                </Box>
                                            </Box> */}
                        <Box className={"float-container runner-name"}>
                          <Box className={"title truncate"}>
                            <Box className={"runner-name"}>{e.runner_name}</Box>
                          </Box>
                          {/* <Box className={"jockey-name"}>A Domeyr</Box> */}
                          <Box className={"pnl"}>
                            {prevExpo && (
                              <Box
                                component={"span"}
                                className={`${
                                  prevExpo[e.runner_id] > 0 ? "positive" : ""
                                } ${
                                  prevExpo[e.runner_id] < 0 ? "negative" : ""
                                }`}
                              >
                                {`${
                                  prevExpo[e.runner_id] != null
                                    ? Math.trunc(prevExpo[e.runner_id])
                                    : ""
                                }`}
                              </Box>
                            )}
                            {exposure && exposure[e.runner_id] && (
                              <Box
                                component={"span"}
                                className={`${
                                  exposure[e.runner_id] > 0 ? "positive" : ""
                                } ${
                                  exposure[e.runner_id] < 0 ? "negative" : ""
                                }`}
                              >
                                &nbsp;{`» ${Math.trunc(exposure[e.runner_id])}`}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {e.back && (
                    <Box className={"market-odds"}>
                      {/* <Box className={"odds-line"}> */}
                      {/* <Box className={"odds-line -swiped-to-left"}> */}
                      <Box className={"market-odds__container "}>
                        {RUNNERSTATUS.SUSPENDED === e.provider_status && (
                          <Box className={"suspended"}>
                            <Box className={"inner"}>SUSPENDED</Box>
                          </Box>
                        )}
                        <Box className={"bet-buttons not-selected scrollable"}>
                          <BetButton
                            event={selectedRace}
                            market={selectedRace}
                            runner={e}
                            market_type={"match_odd"}
                            customClass={"odds-2"}
                            onClick={() => handleClick("back", e.runner_id)}
                            odds={e.back[0]?.price}
                            size={e.back[0]?.size}
                            side={"back"}
                          />
                        </Box>
                        <Box className={"bet-buttons not-selected scrollable"}>
                          <BetButton
                            event={selectedRace}
                            market={selectedRace}
                            runner={e}
                            market_type={"match_odd"}
                            customClass={"odds-2"}
                            onClick={() => handleClick("lay", e.runner_id)}
                            odds={e.lay[0]?.price}
                            size={e.lay[0]?.size}
                            side={"lay"}
                          />
                        </Box>
                      </Box>
                      {/* </Box> */}
                    </Box>
                  )}
                </Box>
                {e.back && openSlip === e.runner_id && isMobile && (
                  <BetSlip
                    isFancy={selectedRace?.market_type === "fancy"}
                    wholeOdds={selectedRace?.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    event={selectedRace}
                    market={selectedRace}
                    runner={e}
                    calculateExposure={calculateExposure}
                    odds={e[slipSide][0].price}
                  />
                )}
              </Box>
            );
          })}
    </Box>
  );
};
