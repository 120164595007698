import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import BetSlip from '../betSlip';
import Odds from './odds';
import EventName from './eventName';
import { EVENTSTATUS } from '../../constants/constants';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';

export default function BetPanel({
  sport,
  openSlip,
  setOpenBetSlip,
  index,
  event,
  runners,
  market,
}) {
  const [slipSide, setSlipSide] = useState('back');
  const [runner, setRunner] = useState();
  const [newMarket, setNewMarket] = useState();
  const [isInPlay, setIsInplay] = useState(false);
  const handleClick = (side, runner) => {
    console.log(runner);
    console.log(side);
    console.log('event', event);
    console.log('market', market);
    if (event && market && runner && side) {
      if (
        !(
          market.market_type === 'match_odd' &&
          !isInPlay &&
          sport?.provider_game_id !== '4'
        )
      ) {
        setNewMarket({
          ...market,
          market_id: event.market_id,
          delay_timer: event.delay_timer,
        });
        setRunner(runner);
        setOpenBetSlip(`${runner?.runner_id}-${event.market_id}`);
        setSlipSide(side);
      }
    }
  };

  useEffect(() => {
    setIsInplay(event.provider_status === EVENTSTATUS.INPLAY);
  }, []);
  return (
    <Box className={'app-list'}>
      <Box className={'market-list-item'}>
        <Box className={'market'}>
          <EventName
            event={event}
            sportName={sport.game_name}
            sportId={sport.game_id}
          />
          {event.runners.length > 3 ? (
            <></>
          ) : (
            <Odds
              slipSide={slipSide}
              event={event}
              openSlip={openSlip}
              sport={sport}
              isInPlay={isInPlay}
              marketType={market?.market_type}
              runners={runners}
              onClick={(side, runner) => handleClick(side, runner)}
              market={market}
            />
          )}
        </Box>
        {openSlip === `${runner?.runner_id}-${event.market_id}` && isMobile && (
          <BetSlip
            odds={runner[slipSide][0].price}
            event={event}
            market={newMarket}
            runner={runner}
            slipSide={slipSide}
            onCancel={() => setOpenBetSlip(null)}
          />
        )}
      </Box>
    </Box>
  );
}
