import { Box, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import betServices from "../services/betServices";
import { BetContext, ToastContext } from "../context/context";
import BetConfirmation from "./modals/betConfirmation";
import BetLoading from "./modals/betLoading";
import { BetSlipContext } from "../context/bet-slip-context";
import {
  blockInvalidChar,
  calcProfit,
  floatWith2DecimalCheck,
  getIP,
  numberWithComma,
} from "../utils/functions";
import eventServices from "../services/eventServices";
import { changeConfirmBet, updateBalanceDetails } from "../redux/reducers/user";
import authServices from "../services/authServices";
import { clearBets } from "../redux/reducers/bets";
import {
  addExposure,
  updateExposure,
  removeAnExposure,
} from "../redux/reducers/exposure";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function BetSlip({
  slipSide,
  onCancel = () => {},
  event,
  runner,
  market,
  calculateExposure = () => {},
  odds,
  oddsChange = true,
  wholeOdds = true,
  isFancy = false,
  line,
}) {
  const [confirmBetModal, setConfirmBetModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showBetLoading, setShowBetLoading] = useState(false);
  const [stake, setStake] = useState();
  const [selectedOdds, setSelectedOdds] = useState();
  const [changedOdds, setChangedOdds] = useState();
  const [minMax, setMinMax] = useState();
  const { setShowToast } = useContext(ToastContext);
  const { updateBetslip } = useContext(BetContext);
  const { user, settings } = useSelector((state) => state.user);
  const { sports } = useSelector((state) => state.sports);
  const { values, is_one_click_bet_enable, active_one_click_stake } =
    useSelector((state) => state.stake);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { changeTab } = useContext(BetSlipContext);
  const [exposure, setExposure] = useState();
  useEffect(() => {
    setShow(true);
    // console.log("event.game_event_id", event.game_event_id);
    // console.log("market.provider_market_id", market.provider_market_id);
    // console.log("market.market_type", market.market_type);
    // console.log("runner.runner_id", runner.runner_id);
    // console.log("runner.runner_name", runner.runner_name);
    // console.log("slipSide", slipSide);
    // console.log("changedOdds", changedOdds);
    // console.log("stake", stake);
    // console.log("isFancy ? line : null", isFancy ? line : null);
    // console.log("is_one_click_bet_enable", is_one_click_bet_enable);
    // console.log("isFancy", isFancy);
    // console.log("wholeOdds", wholeOdds);
    // console.log("runner", runner);
    // console.log("odds", odds);
    setSelectedOdds(odds);
    setChangedOdds(odds);
    // eslint-disable-next-line
    if (isBrowser) {
      if (!isFancy) {
        getMinMaxMarket();
      } else {
        getFancyMinMaxMarket();
      }
    }
  }, [slipSide, runner.runner_id, updateBetslip, isFancy]);

  useEffect(() => {
    calculateExposure(
      stake,
      changedOdds,
      runner.runner_id,
      market.market_id,
      slipSide
    );
    // eslint-disable-next-line
  }, [stake, changedOdds, slipSide]);

  useEffect(() => {
    let c = 1;
    if (!isFancy) {
      getMinMaxMarket();
    } else {
      getFancyMinMaxMarket();
    }
  }, [isFancy]);

  useEffect(() => {
    if (slipSide !== "back" && slipSide !== "lay") {
      onCancel();
    }
  }, [slipSide]);

  const getMinMaxMarket = async () => {
    const res = await eventServices.getMinMaxMarket(
      market.market_id,
      runner.runner_id
    );
    if (res) {
      setMinMax(res);
    }
  };

  const getFancyMinMaxMarket = async () => {
    const res = await eventServices.getFancyMinMaxMarket(
      market.provider_market_id
    );
    if (res) {
      setMinMax(res);
    }
  };

  const addStake = (newValue) => {
    setStake(parseInt(stake || 0) + newValue);
    inputRef.current.children[0].children[0].focus();
  };

  const placeBet = async () => {
    try {
      if (!showBetLoading) {
        setShowBetLoading(true);
        if (isBrowser) {
          handleCloseConfirmBetModal();
        }
        const ip = await getIP();
        const data = {
          event_id: event.game_event_id,
          provider_market_id: market.provider_market_id,
          market_type: market.market_type,
          runner_id: runner.runner_id,
          runner_name: runner.runner_name,
          type: slipSide,
          odds: changedOdds,
          bet_amount: stake,
          line: isFancy ? line : null,
          fromOneClick: is_one_click_bet_enable ? 1 : 0,
          is_fancy: isFancy,
          fancy_category: market.category,
          is_fractional_odds: wholeOdds,
          totalSize: 10000,
          ip_address: ip,
        };
        // console.log("placing bet...", data);
        if (!validateBetData(data)) {
          const res = await betServices.createbet(data);
          setShowBetLoading(false);
          if (res) {
            getBalanceFromApi();
            setShowToast({
              error: false,
              color: res.status_type === "un-matched" ? "#c8592c" : "",
              message: res.message,
              bet: true,
            });
            onCancel();
            setShowBetLoading(false);
            handleCloseConfirmBetModal();
            dispatch(clearBets());
            changeTab("BETS");
          }
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      setShowBetLoading(false);
      handleCloseConfirmBetModal();
      setShowToast({
        error: true,
        message: error.toString() || "Something went wrong!",
      });
    }
  };

  const validateBetData = (data) => {
    const requiredKeys = [
      "event_id",
      "provider_market_id",
      "runner_id",
      "runner_name",
      "type",
      "odds",
      "bet_amount",
      "ip_address",
    ];
    let error = false;
    for (let key in data) {
      if (
        requiredKeys.includes(key) &&
        (data[key] === null || data[key] === "")
      ) {
        error = true;
        break;
      }
    }

    return error;
  };

  const getBalanceFromApi = async () => {
    try {
      const res = await authServices.getBalance();
      if (res) {
        dispatch(updateBalanceDetails(res));
      }
    } catch (error) {
      console.error("Failed to get balance", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePlaceBet(e);
    }
  };

  const handlePlaceBet = (e) => {
    e.preventDefault();
    if (stake && parseInt(changedOdds)) {
      if (settings.confirmBet) setConfirmBetModal(true);
      else placeBet(e);
    }
  };

  const increaseOdds = () => {
    const currentOdds = parseFloat(changedOdds);
    if (currentOdds < 2) {
      setChangedOdds((currentOdds + 0.01).toFixed(2));
    } else if (currentOdds < 3) {
      setChangedOdds((currentOdds + 0.02).toFixed(2));
    } else if (currentOdds < 4) {
      setChangedOdds((currentOdds + 0.05).toFixed(2));
    } else if (currentOdds < 6) {
      setChangedOdds((currentOdds + 0.1).toFixed(2));
    } else if (currentOdds < 10) {
      setChangedOdds((currentOdds + 0.2).toFixed(2));
    } else if (currentOdds < 20) {
      setChangedOdds(currentOdds + 0.5);
    } else if (currentOdds < 30) {
      setChangedOdds(currentOdds + 1);
    } else if (currentOdds < 50) {
      setChangedOdds(currentOdds + 2);
    } else if (currentOdds < 100) {
      setChangedOdds(currentOdds + 5);
    } else {
      setChangedOdds(currentOdds + 10);
    }
  };

  const decreaseOdds = () => {
    const currentOdds = parseFloat(changedOdds);
    if (currentOdds <= 2 && currentOdds > 1.01) {
      setChangedOdds((currentOdds - 0.01).toFixed(2));
    } else if (currentOdds <= 3 && currentOdds > 2) {
      setChangedOdds((currentOdds - 0.02).toFixed(2));
    } else if (currentOdds <= 4 && currentOdds > 3) {
      setChangedOdds((currentOdds - 0.05).toFixed(2));
    } else if (currentOdds <= 6 && currentOdds > 4) {
      setChangedOdds((currentOdds - 0.1).toFixed(2));
    } else if (currentOdds <= 10 && currentOdds > 6) {
      setChangedOdds((currentOdds - 0.2).toFixed(2));
    } else if (currentOdds <= 20 && currentOdds > 10) {
      setChangedOdds(currentOdds - 0.5);
    } else if (currentOdds <= 30 && currentOdds > 20) {
      setChangedOdds(currentOdds - 1);
    } else if (currentOdds <= 50 && currentOdds > 30) {
      setChangedOdds(currentOdds - 2);
    } else if (currentOdds <= 100 && currentOdds > 50) {
      setChangedOdds(currentOdds - 5);
    } else if (currentOdds > 100) {
      setChangedOdds(currentOdds - 10);
    }
  };

  const handleCloseConfirmBetModal = () => {
    setConfirmBetModal(false);
    if (is_one_click_bet_enable) {
      onCancel();
    }
  };

  useEffect(() => {
    if (is_one_click_bet_enable) {
      setStake(active_one_click_stake);
      setConfirmBetModal(true);
    }
  }, []);

  const setConfirmBet = () => {
    dispatch(changeConfirmBet());
  };

  const handleStakeChange = (event) => {
    const newValue = floatWith2DecimalCheck(event.target.value);
    if (newValue !== null) setStake(newValue);
  };

  const handleOddsChange = (event) => {
    const newValue = floatWith2DecimalCheck(event.target.value);
    console.log(event.target.value);
    if (newValue !== null && newValue < 1000) setChangedOdds(newValue);
  };
  const calculateProfit = (runnerId) => {
    if (runnerId === runner.runner_id) {
      return slipSide === "back"
        ? +calcProfit(runner.odds, runner.bet_amount, runner.is_fractional_odds)
        : -calcProfit(
            runner.odds,
            runner.bet_amount,
            runner.is_fractional_odds
          );
    }
    return slipSide === "back" ? -runner.bet_amount : runner.bet_amount;
  };
  const onCancleBetslip = () => {
    onCancel();
    dispatch(clearBets());
  };
  return (
    <>
      <BetConfirmation
        betData={{
          event_name: event.event_name,
          runner_name: runner.runner_name,
          type: slipSide,
          odds: changedOdds,
          bet_amount: stake,
          line,
          wholeOdds,
          isFancy,
        }}
        onCancel={handleCloseConfirmBetModal}
        onPlaceBet={placeBet}
        open={confirmBetModal}
      />
      <BetLoading
        open={showBetLoading}
        className="showBetLoading"
        isBrowser={isBrowser}
        betDelay={
          parseInt(minMax?.delay_timer) > 0 &&
          market.market_type === "match_odd"
            ? parseInt(minMax?.delay_timer)
            : 0
        }
      />
      {!is_one_click_bet_enable && (
        <Box
          sx={{
            height: isMobile
              ? odds !== selectedOdds && false
                ? 270
                : 280
              : "",
            overflow: "hidden",
            display: "block",
            zIndex: "1",
            outline: "none",
          }}
        >
          <BrowserView>
            <Box className={"bet-set-box d-flex"}>
              <Stack className={`bet-box ${slipSide}`}></Stack>
              <Typography
                sx={{
                  fontSize: "12px",
                  textTransform: "capitalize",
                  lineHeight: "1",
                }}
              >
                {" "}
                {slipSide}{" "}
              </Typography>
            </Box>
          </BrowserView>

          <Box className={"event-name"}>{event.event_name}</Box>
          <Box
            className={`bet-form ${slipSide}`}
            sx={{
              transform: `translateY(${show ? "2" : "-217"}px)`,
              transition: "all 0.15s ease-in-out",
            }}
          >
            <BrowserView>
              <Box>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography sx={{ fontSize: "12px" }}>
                    {runner.runner_name}
                  </Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Max Mkt: {numberWithComma(minMax?.max_bet_amount || 0)}
                  </Typography>
                </Stack>
              </Box>
            </BrowserView>
            <Box className={`betslip-box-set ${slipSide}`}>
              <Box className={`inputs ${slipSide}`}>
                <Box className={"item-block input-group odds-stepper-group "}>
                  <Box>
                    <Typography sx={{ fontSize: "10px" }}>
                      {isFancy === true ? "Runs" : "ODDS"}
                    </Typography>
                    {oddsChange ? (
                      <Box className={`stepper-input ${slipSide}`}>
                        <Box onClick={decreaseOdds} className={"minus"}>
                          <BrowserView>&#11205;</BrowserView>
                          <MobileView>-</MobileView>
                        </Box>
                        <input
                          onKeyDown={blockInvalidChar}
                          value={changedOdds}
                          onChange={handleOddsChange}
                          type="number"
                          className={`odds-input ${slipSide}`}
                        />
                        <Box onClick={increaseOdds} className={"plus"}>
                          <BrowserView>&#11206;</BrowserView>
                          <MobileView>+</MobileView>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{ textAlign: "center" }}
                        className={"odds-stepper-readonly"}
                      >
                        {isFancy ? line : changedOdds}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  className={"input-group-stake"}
                  sx={{ marginRight: "2px" }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography sx={{ fontSize: "10px" }}>STAKE</Typography>
                    <MobileView>
                      <Typography sx={{ fontSize: "10px" }}>
                        Max Mkt: {numberWithComma(minMax?.max_bet_amount || 0)}
                      </Typography>
                    </MobileView>
                  </Stack>
                  <TextField
                    onKeyDown={blockInvalidChar}
                    className={"input-stake"}
                    type="number"
                    value={stake}
                    id="focus"
                    ref={inputRef}
                    onChange={handleStakeChange}
                    onKeyPress={handleKeyPress}
                    sx={{ width: "100%" }}
                    placeholder={`Min: ${minMax?.min || 0}, Max: ${
                      minMax?.max || 0
                    }`}
                  ></TextField>
                </Box>
                <BrowserView className={"info-field input-group-stake"}>
                  <Box>
                    <Typography sx={{ fontSize: "10px" }}>
                      {slipSide === "lay" ? "Liability" : "Profit"}
                    </Typography>
                    <Box component={"span"} className="value">
                      {calcProfit(changedOdds, stake, wholeOdds)}
                    </Box>
                  </Box>
                </BrowserView>
              </Box>
              <Stack
                direction={"row"}
                spacing={1}
                className={"input-buttons bet-row-buttons"}
              >
                {values.map((amount) => {
                  return (
                    <Box onClick={() => addStake(amount)} class="button">
                      + {amount}
                    </Box>
                  );
                })}
              </Stack>
            </Box>

            {odds !== selectedOdds && false && (
              <Box className={"odds-has-changed-confirmation"}>
                <Box className={"odds-has-changed-confirmation__message"}>
                  The odds of your selection have changed
                </Box>
              </Box>
            )}
            <Stack
              direction={"row"}
              className={"input-buttons confirmation-buttons"}
            >
              <Box onClick={() => onCancleBetslip()} class="button cancel">
                {" "}
                Cancel{" "}
              </Box>
              <Box
                type="submit"
                class="button submit -middle -primary"
                disabled={
                  !stake || stake <= 0 || !changedOdds || changedOdds <= 0
                }
                onClick={handlePlaceBet}
              >
                <Box className={"bet-delay"}>
                  {parseInt(minMax?.delay_timer) > 0 &&
                    market.market_type === "match_odd" && (
                      <Box component={"span"}>{minMax?.delay_timer}s</Box>
                    )}
                </Box>
                <Stack
                  direction={"column"}
                  width={"100%"}
                  alignItems={"center"}
                  class="place-bet-btn__wrap"
                >
                  <Typography fontSize={"13px"} lineHeight={0.6}>
                    Place Bet
                  </Typography>
                  <Typography fontSize={"10px"}>
                    {slipSide === "back" ? "Profit" : "Liability"}:{" "}
                    {Math.trunc(calcProfit(changedOdds, stake, wholeOdds))}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <BrowserView>
              <Box sx={{ paddingTop: "10px" }}>
                <Box component={"label"} className="confirmation-checkbox">
                  <input
                    onChange={() => setConfirmBet()}
                    checked={settings.confirmBet}
                    type="checkbox"
                  />
                  Confirm bets before placing
                </Box>
              </Box>
            </BrowserView>
            <MobileView>
              <Box className={"title-and-toggle"}>
                <Typography
                  marginY={"14px"}
                  variant="body3"
                  sx={{ color: "#666666" }}
                >
                  Confirm bet before placing
                </Typography>
                {/* <Switch value={true} /> */}
                <Box sx={{ height: "24px", display: "inline-block" }}>
                  <Box
                    onClick={() => setConfirmBet()}
                    sx={{
                      background: settings.confirmBet
                        ? "#00a950 !important"
                        : "",
                      "&:after": {
                        color: settings.confirmBet ? "#fff !important" : "",
                      },
                    }}
                    className={`toggle-container ${
                      settings.confirmBet ? "on" : "off"
                    }`}
                  >
                    <Box
                      sx={{ background: "#fff !important" }}
                      className={"toggle-button"}
                    ></Box>
                  </Box>
                </Box>
              </Box>
            </MobileView>
          </Box>
        </Box>
      )}
    </>
  );
}
