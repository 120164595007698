import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import {Detector,} from "react-detect-offline"
import { RefreshSocketContext } from "../context/context";

const checkInternetSpeed = async (setSpeed) => {
    try {
        const fileSizeInBytes = 8090; // Size of the file you are going to download (in bytes)
        const downloadUrl = 'https://httpbin.org/image/png'; // URL of the small file
      
        const startTime = new Date().getTime();
        await fetch(downloadUrl, { cache: "no-cache" });
        const endTime = new Date().getTime();
      
        const duration = (endTime - startTime) / 1000; // Time in seconds
        const bitsLoaded = fileSizeInBytes * 8; // Convert bytes to bits
        const speedBps = (bitsLoaded / duration).toFixed(2);
        const speedKbps = (speedBps / 1024).toFixed(2);
      
        setSpeed(speedKbps);
        
    } catch (error) {
        setSpeed(120)
    }
  };

export default function BottomMsg(){
    
    const [isOnline,setOnline] = useState(true);

    const [speed, setSpeed] = useState(100);

    const {setRefresh} = useContext(RefreshSocketContext)

    useEffect(() => {
        // const interval = setInterval(() => {
        // checkInternetSpeed(setSpeed);
        // }, 5000); // Check every 5 seconds

        // return () => clearInterval(interval);
    }, []);

    useEffect(()=>{
        // console.log({speed})
    },[speed])

    // useEffect(()=>{
    //     if(isOnline){
    //         window.location.reload();
    //     }
    //     else{
    //         // setRefresh(false);
    //     }
    // },[isOnline])

    return(
        <>
            <Detector 
            polling={false}
            onChange={(online)=>{
                setOnline(online)
                if(online) window.location.reload();
            }}
            render={()=>("")}
            /> 
            <Box
                sx={{
                    position:"fixed",
                    padding:"10px",
                    bgcolor:"error.main",
                    width:"100%",
                    bottom:(isOnline && speed > 50)?-44:0,
                    zIndex:999999,
                    color:"#fff",
                    textAlign:"center",
                    transition:"all 0.5s ease-in-out",
                }}
            >
                <Typography variant="body1">{ isOnline ? speed > 50 ? "": "Opps!! Your internet connection is slow" : "No Internet Connection"}</Typography>
            </Box>
        </>
        
    )
}