import { Box, Dialog, } from "@mui/material";
import CustomIcon from "../customIcon";

export default function IconInfo({open,info,onClose=()=>{}}) {
    return(
        <Dialog open={open}
        onClose={onClose}
            PaperProps={{
                sx:{
                    margin:0,
                    borderRadius:"10px",
                    width:"75%",
                    maxWidth:"90%",
                }
            }}
        >
            <Box className={`modal-container notification-modal `}>
                <Box className={"modal-component-wrap"}>
                    <Box className={"modal-content"} position={"relative"}>
                        <Box sx={{position:"absolute",top:0,right:0}}>
                            <CustomIcon onClick={onClose} name={"modal-close"} fontSize={16} />
                        </Box>
                        <Box component={"p"}>{info}</Box>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}