import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
// eslint-disable-next-line
import CustomIcon from "../components/customIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LeftSideBarTabs } from "../utils/sidebar";
import { useSelector } from "react-redux";
import eventServices from "../services/eventServices";
import { findByKeyInArray, groupBy } from "../utils/functions";
import { EVENTSTATUS } from "../constants/constants";
import CompCard from "../components/sportsComponents/compCard";
import LoadingSkelaton from "../components/loadingSkelaton";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function GameList() {
  const [currentSport, setCurrentSport] = useState();
  const [liveLoading, setLiveLoading] = useState(false);
  const [upcomingLoading, setUpcomingLoading] = useState(false);
  const [liveEvents, setLiveEvents] = useState();
  const [upcomingEvents, setUpcomingEvents] = useState();
  const [leagues, setLeagues] = useState([]);
  const params = useParams();
  const { sports } = useSelector((state) => state.sports);
  const [selectedTab, setSelectedTab] = useState(0);

  const headers = [
    {
      status: EVENTSTATUS.INPLAY,
      component: (
        <Stack direction={"row"} alignItems={"center"}>
          <CustomIcon name={"live"} color={"#257b23"} fontSize={18} />
          <Box ml={1}>{"Live"}</Box>
        </Stack>
      ),
    },
    {
      status: EVENTSTATUS.UPCOMING,
      component: (
        <Stack direction={"row"} alignItems={"center"}>
          <CustomIcon name={"heart"} color={"#cc5f36"} fontSize={18} />
          <Box ml={1}>{"Upcoming"}</Box>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    console.log(params, "params", params?.sportId);
    console.log(
      LeftSideBarTabs.filter(
        (_sports) => _sports.id === Number(params?.sportId)
      )[0]
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sports && sports.length > 0) {
      let sport = findByKeyInArray(sports, "provider_game_id", params?.sportId);
      if (sport) setCurrentSport(sport);
    }
  }, [sports, params]);

  useEffect(() => {
    if (currentSport) {
      getLiveEventsBySport();
      getUpcomingEventsBySport();
      getLeaguesBySport();
    }
    // eslint-disable-next-line
  }, [currentSport]);

  const getLiveEventsBySport = async () => {
    try {
      setLiveLoading(true);
      const res = await eventServices.getLiveEventsBySport(
        currentSport?.game_id
      );
      setLiveLoading(false);
      if (res && res.length > 0) {
        setLiveEvents(res);
      } else {
        setLiveEvents([]);
      }
    } catch (error) {
      setLiveLoading(false);
      setLiveEvents([]);
    }
  };

  const getUpcomingEventsBySport = async () => {
    try {
      setUpcomingLoading(true);
      const res = await eventServices.getUpcomingEventsBySport(
        currentSport?.game_id
      );
      setUpcomingLoading(false);
      if (res && res.length > 0) {
        setUpcomingEvents(res);
      } else {
        setUpcomingEvents([]);
      }
    } catch (error) {
      setUpcomingLoading(false);
      setUpcomingEvents([]);
    }
  };

  const getLeaguesBySport = async () => {
    const res = await eventServices.getLeaguesBySport(currentSport?.game_id);
    if (res && res.length > 0) {
      setLeagues(res);
    }
  };

  return (
    <>
      <Box className={"sport-name-wrap"}>
        <CustomIcon name={currentSport?.game_name} fontSize={"22px"} />
        <Typography
          sx={{
            fontSize: "14px",
            marginLeft: "18px",
            textTransform: "capitalize",
          }}
        >
          {currentSport?.game_name}
        </Typography>
      </Box>
      <MobileView>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(e, v) => setSelectedTab(v)}
          className="nav-tabs-group-container"
        >
          <Tab
            disableRipple={true}
            label="LIVE & UPCOMING"
            className={`nav-tab ${selectedTab === 0 ? "active" : ""}`}
          />
          <Tab
            disableRipple={true}
            label="LEAGUES"
            className={`nav-tab ${selectedTab === 1 ? "active" : ""}`}
          />
        </Tabs>
      </MobileView>
      {selectedTab === 0 ? (
        <Box padding={1} className={"event-group"}>
          {liveLoading ? (
            <LoadingSkelaton />
          ) : (
            liveEvents &&
            liveEvents.length > 0 && (
              <CompCard header={headers[0].component} eventData={liveEvents} />
            )
          )}
          {upcomingLoading ? (
            <LoadingSkelaton />
          ) : (
            upcomingEvents &&
            upcomingEvents.length > 0 && (
              <CompCard
                header={headers[1].component}
                eventData={upcomingEvents}
              />
            )
          )}
          {!liveLoading &&
            !upcomingLoading &&
            (!liveEvents || liveEvents.length === 0) &&
            (!upcomingEvents || upcomingEvents.length === 0) && (
              <Box padding={2}>No Matches Available Right Now </Box>
            )}
        </Box>
      ) : (
        <Leagues leagues={leagues} />
      )}
    </>
  );
}

const Leagues = ({ leagues }) => {
  const navigate = useNavigate();
  return (
    <Box padding={1} className={"leagues-group"}>
      {leagues.map((_data) => {
        return (
          <Box
            className={"leagues-box"}
            onClick={() => navigate(`league/${_data.league_id}`)}
          >
            <Typography fontSize={"15px"}>{_data.league_name}</Typography>
            <Typography fontSize={"15px"}>{_data.event_data_count}</Typography>
          </Box>
        );
      })}
    </Box>
    // <p>AHAH</p>
  );
};
