import { Box, Stack,Grid,Item } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomIcon from "../customIcon";
import BetSlip from "../betSlip";
import socketServices from "../../services/socketServices";
import BetPanel from "./betPanel";
// import { RefreshSocketContext } from "../../context/context";

export default function MarketCard({ header, live, eventData = null }) {
  const [events, setEvents] = useState([]);
  const [openSlip, setOpenBetSlip] = useState(null);
  const [socketUserIds, setSocketUserIds] = useState([]);
  const [liveOdds, setLiveOdds] = useState({});
  const { isLogginIn } = useSelector((state) => state.user);
//   const { refresh } = useContext(RefreshSocketContext);

  useEffect(() => {
    socketServices.roomJoined(handleRoomIds);
    return () => {
      console.log("Leaving rooms", socketUserIds);
      socketUserIds.forEach((data) => {
        socketServices.leaveRoom(data);
      });
    };
  }, []);

  const handleRoomIds = (data) => {
    if (data && data.data && !data.response_error) {
      setSocketUserIds((prev) => [...prev, data.data]);
    }
  };

  useEffect(() => {
    if (live) {
      setEvents([{ name: "Cricket", id: 4 }, { name: "Football", id: 1 }]);
    } else {
      setEvents([{ name: "Cricket", id: 4 }, { name: "Football", id: 1 }, { name: "Tennis", id: 2 }, { name: "ESports", id: 12121 }]);
    }
  }, [live]);

  useEffect(() => {
    if (eventData && eventData.length > 0 && isLogginIn) {
      eventData.forEach((_sport) => {
        _sport.event_data.forEach((_event) => {
          if (_event.provider_event_id && _event.provider_market_id) {
            const roomName = `${_event.provider_event_id}/${_event.provider_market_id}`;
            const data = { roomNames: roomName, marketType: _event.market_type };
            socketServices.joinRoom(data);
            socketServices.listenEvent(roomName, handleLiveOdds);
          }
        });
      });
    }
  }, [eventData, isLogginIn]);

  const handleLiveOdds = (data) => {
    if (data && !data.response_error && data.data) {
      setLiveOdds((prev) => ({
        ...prev,
        [`${data.data.provider_event_id}/${data.data.provider_market_id}`]: data.data,
      }));
    }
  };

  return (
    <Box sx={{ background: '#fff', borderRadius: '2px', marginBottom: '8px' }}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} padding={1} className="section-title in-play">
        {header}
      </Stack>
      <Box>
        {eventData ? (
          eventData
            .filter((_sport) => _sport.event_data?.length > 0)
            .map((_sport) => (
              <Box key={_sport.game_name}>
                <Box className="events-group-title">
                  <Stack className="title" direction={"row"} spacing={1} alignItems={"center"}>
                    <CustomIcon name={_sport.game_name.toLowerCase()} color={live ? "#257b23" : "#cc5f36"} fontSize={"18px"} />
                    <span style={{ textTransform: "capitalize" }}>{_sport.game_name}</span>
                  </Stack>
                  <Box className="odds-header">
                    <Box className={"odds-col"}>1</Box>
                    <Box className={"odds-col"}>X</Box>
                    <Box className={"odds-col"}>2</Box>
                    <Box className={"odds-col onlyDesktop"}>1</Box>
                    <Box className={"odds-col onlyDesktop"}>X</Box>
                    <Box className={"odds-col onlyDesktop"}>2</Box>
                  </Box>
                </Box>
                {_sport.event_data.map((_event, i) => {
                  const { provider_event_id, provider_market_id, market_name, market_type, runners } = _event;
                  const marketKey = `${provider_event_id}/${provider_market_id}`;
                  return (
                    <BetPanel
                      key={marketKey}
                      market={liveOdds[marketKey] || { market_name, market_type, provider_market_id }}
                      event={_event}
                      runners={liveOdds[marketKey]?.runners || runners}
                      sport={_sport}
                      openSlip={openSlip}
                      setOpenBetSlip={setOpenBetSlip}
                      index={i}
                    />
                  );
                })}
              </Box>
            ))
        ) : (
          events.map((_sport) => (
            <Box key={_sport.name}>
              <Box className="events-group-title">
                <Stack className="title" direction={"row"} spacing={1} alignItems={"center"}>
                  <CustomIcon name={_sport.name.toLowerCase()} color={live ? "#257b23" : "#cc5f36"} fontSize={"18px"} />
                  <span>{_sport.name}</span>
                </Stack>
                <Box className="odds-header">
                  <Box className={"odds-col"}>1</Box>
                  <Box className={"odds-col"}>X</Box>
                  <Box className={"odds-col"}>2</Box>
                </Box>
              </Box>
              <BetPanel sport={_sport} openSlip={openSlip} setOpenBetSlip={setOpenBetSlip} index={0} />
              <BetPanel sport={_sport} openSlip={openSlip} setOpenBetSlip={setOpenBetSlip} index={1} />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}
