

export default function Tabs(theme)
{
    return {
        MuiTabs: {
            styleOverrides: {
              indicator:{
                position: 'absolute',
                height: '3px',
                bottom: '0',
                width: '100%',
                WebkitTransition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: 'var(--accent_color)'
              }
            }
          }
    }
}