import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import BetButton from "../betButton";
import { useSelector } from "react-redux";
import { LoginContext } from "../../context/context";
import { RUNNERSTATUS } from "../../constants/constants";

export default function Odds({
  onClick = () => {},
  runners,
  isInPlay,
  slipSide,
  openSlip,
  marketType,
  sport,
  market,
  event,
}) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [validData, setValidData] = useState(false);
  const { isLogginIn } = useSelector((state) => state.user);
  const { setOpenLogin } = useContext(LoginContext);

  const ref = useRef();
  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      console.log("swipe", isLeftSwipe ? "left" : "right");
    if (isLeftSwipe && !ref?.current?.classList.contains("-swiped-to-left"))
      ref?.current?.classList.add("-swiped-to-left");
    if (isRightSwipe && ref?.current?.classList.contains("-swiped-to-left"))
      ref?.current?.classList.remove("-swiped-to-left");
    // add your conditional logic here
  };

  const handleClick = (side, runner) => {
    if (isLogginIn) {
      onClick(side, runner);
    } else {
      setOpenLogin(true);
    }
  };

  useEffect(() => {
    if (runners && runners[0] && runners[0]?.back && runners[0]?.back[0]) {
      setValidData(true);
    } else {
      setValidData(false);
    }
  }, [runners]);

  return (
    <>
      {validData && (
        <Box className={"market-odds custom-width"}>
          <Box className={"odds-wrap scrollable"}>
            <Box
              ref={ref}
              className={"odds-line "}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              <Box className={"market-odds__container "}>
                {RUNNERSTATUS.SUSPENDED === market?.provider_status && (
                  <Box className={"suspended"}>
                    <Box className={"inner"}>SUSPENDED</Box>
                  </Box>
                )}
                {RUNNERSTATUS.BALLRUNNING === market?.provider_status && (
                  <Box className={"ball-running-overlay"}>
                    <Box className={"ball-running-overlay-message"}>
                      Ball Running
                    </Box>
                  </Box>
                )}
                <Box className={"bet-buttons not-selected scrollable"}>
                  <BetButton
                    line={market.no_value}
                    event={event}
                    market={market}
                    slipSide={slipSide}
                    openSlip={openSlip}
                    runner={{
                      runner_id: runners[0]?.runner_id,
                      runner_name: runners[0]?.runner_name,
                    }}
                    market_type={marketType}
                    customClass={`${
                      marketType == "match_odd" &&
                      !isInPlay &&
                      sport.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }`}
                    onClick={() => handleClick("back", runners[0])}
                    odds={runners[0]?.back[0]?.price}
                    size={runners[0]?.back[0]?.size}
                    side={"back"}
                  />
                </Box>
                <Box className={"bet-buttons not-selected scrollable"}>
                  <BetButton
                    line={market.no_value}
                    event={event}
                    market={market}
                    openSlip={openSlip}
                    slipSide={slipSide}
                    runner={{
                      runner_id: runners[2]?.runner_id,
                      runner_name: runners[2]?.runner_name,
                    }}
                    market_type={marketType}
                    customClass={`${
                      marketType == "match_odd" &&
                      !isInPlay &&
                      sport.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    } ${runners[2] ? "" : "has-no-runner"}`}
                    onClick={() => handleClick("back", runners[2])}
                    odds={
                      runners[2] && runners[2]?.back
                        ? runners[2]?.back[0]?.price
                        : "-"
                    }
                    size={
                      runners[2] && runners[2]?.back
                        ? runners[2]?.back[0]?.size
                        : 0
                    }
                    side={"back"}
                  />
                </Box>
                <Box className={"bet-buttons not-selected scrollable"}>
                  <BetButton
                    line={market.no_value}
                    event={event}
                    market={market}
                    openSlip={openSlip}
                    slipSide={slipSide}
                    runner={{
                      runner_id: runners[1]?.runner_id,
                      runner_name: runners[1]?.runner_name,
                    }}
                    market_type={marketType}
                    customClass={`${
                      marketType == "match_odd" &&
                      !isInPlay &&
                      sport.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }`}
                    onClick={() => handleClick("back", runners[1])}
                    odds={runners[1]?.back[0]?.price}
                    size={runners[1]?.back[0]?.size}
                    side={"back"}
                  />
                </Box>
                <Box className={"bet-buttons not-selected scrollable"}>
                  <BetButton
                    line={market.no_value}
                    event={event}
                    market={market}
                    openSlip={openSlip}
                    slipSide={slipSide}
                    runner={{
                      runner_id: runners[0]?.runner_id,
                      runner_name: runners[0]?.runner_name,
                    }}
                    market_type={marketType}
                    customClass={`${
                      marketType == "match_odd" &&
                      !isInPlay &&
                      sport.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }`}
                    onClick={() => handleClick("lay", runners[0])}
                    odds={runners[0]?.lay[0]?.price}
                    size={runners[0]?.lay[0]?.size}
                    side={"lay"}
                  />
                </Box>
                <Box className={"bet-buttons not-selected scrollable"}>
                  <BetButton
                    line={market.no_value}
                    event={event}
                    market={market}
                    openSlip={openSlip}
                    slipSide={slipSide}
                    runner={{
                      runner_id: runners[2]?.runner_id,
                      runner_name: runners[2]?.runner_name,
                    }}
                    market_type={marketType}
                    customClass={`${
                      marketType == "match_odd" &&
                      !isInPlay &&
                      sport.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    } ${runners[2] ? "" : "has-no-runner"}`}
                    onClick={() => handleClick("lay", runners[2])}
                    odds={
                      runners[2] && runners[2]?.lay
                        ? runners[2]?.lay[0]?.price
                        : "-"
                    }
                    size={
                      runners[2] && runners[2]?.lay
                        ? runners[2]?.lay[0]?.size
                        : 0
                    }
                    side={"lay"}
                  />
                </Box>
                <Box className={"bet-buttons not-selected scrollable"}>
                  <BetButton
                    line={market.no_value}
                    event={event}
                    market={market}
                    openSlip={openSlip}
                    slipSide={slipSide}
                    runner={{
                      runner_id: runners[1]?.runner_id,
                      runner_name: runners[1]?.runner_name,
                    }}
                    market_type={marketType}
                    customClass={`${
                      marketType == "match_odd" &&
                      !isInPlay &&
                      sport.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }`}
                    onClick={() => handleClick("lay", runners[1])}
                    odds={runners[1]?.lay[0]?.price}
                    size={runners[1]?.lay[0]?.size}
                    side={"lay"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
