import { Box, Stack } from '@mui/material';
import CustomIcon from '../components/customIcon';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import eventServices from '../services/eventServices';
import { getIP, groupBy } from '../utils/functions';
import { LoginContext, ToastContext } from '../context/context';
import LoginModal from '../components/modals/login';
import casinoServices from '../services/casinoServices';
import { API, API_LINK } from '../constants/constants';
import axios from 'axios';
import CoinMsg from '../components/modals/coinMsg';
import LowBalanceMsg from '../components/modals/lowBalance';

function getCasinoIconName(gameName) {
  if (gameName === 'All') return 'allcasino';
  return gameName.replace(/ /g, '').toLowerCase();
}

export default function LiveCasino() {
  const { casino } = useSelector((state) => state.sports);
  const [openLogin, setOpenLogin] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showBalanceMsg, setShowBalanceMsg] = useState(false);
  const [iframeLink, setIframeLink] = useState('');
  const { game_id, tabId } = useParams();
  const { setShowToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const { isLogginIn, user, balanceData } = useSelector((state) => state.user);

  const [tabs, setTabs] = useState([
    {
      game_id: 'All',
      game_name: 'All',
    },
  ]);
  const [selected, setSelected] = useState({
    game_id: 'All',
    game_name: 'All',
  });

  useEffect(() => {
    if (tabId && casino) {
      let category = casino.find(
        (category) => category.provider_game_id == tabId
      );
      if (category) setSelected(category);
    }
  }, [tabId, casino]);

  useEffect(() => {
    if (casino && casino.length > 0) {
      let temp = [
        {
          game_id: 'All',
          game_name: 'All',
        },
        ...casino,
      ];
      setTabs(temp);
    }
  }, [casino]);

  // useEffect(()=>{
  //     const token = sessionStorage.getItem('token');
  //     if(token && game_id )
  //     {
  //         setGameOpen(`${token}/${game_id}`)
  //     }
  //     else{
  //         setGameOpen(null)
  //     }
  // },[game_id])

  const handleOpenGame = (game) => {
    try {
      if (isLogginIn) {
        console.log(game);
        if (user && game) {
          getGameIframe(game);
          // if(game_id === "evol")
          // {
          //     setGameOpen(game_id);
          // }
          // else{
          //     navigate(game_id);
          // }
        }
      } else {
        setOpenLogin(true);
      }
    } catch (error) {}
  };

  // useEffect(()=>{
  //     if(gameOpen === "evol")
  //     {
  //         getEvolIframe();
  //     }
  //     else{
  //         setIframeLink(`${API_LINK}/${API.CASINO_GAME}${gameOpen}`)
  //     }
  // },[gameOpen])

  const getGameIframe = async (game) => {
    try {
      if (balanceData && Number(balanceData.available_credit)) {
        setLoading(true);
        const ip = await getIP();
        const url = window.location.origin;
        // console.log(url);
        const data = {
          gameId: game.provider_event_id,
          is_mobile: true,
          clientIp: ip,
          providerName: game.provider_name,
          provider_type: game.provider_type,
          redirectUrl: url,
        };
        const res = await casinoServices.getCasinoGame(data);
        if (game.provider_type === 'Evolution') setShowMsg(true);
        setLoading(false);
        if (res && res.url) setIframeLink(res.url);
      } else {
        setShowBalanceMsg(true);
      }
    } catch (error) {
      setLoading(false);
      setShowToast({
        error: true,
        message: error || 'Something went wrong!',
      });
    }
  };

  const openLobby = async () => {
    // try {
    //     if(Number(balanceData.available_credit))
    //     {
    //         setLoading(true);
    //         const ip = await getIP();
    //         const data = {
    //             gameId:"200828",
    //             is_mobile:true,
    //             clientIp:ip,
    //             providerName:"DC",
    //             provider_type:"Evolution",
    //             redirectUrl:API_LINK
    //         }
    //          const res = await casinoServices.getCasinoGame(data);
    //           setShowMsg(true);
    //          setLoading(false);
    //          if(res && res.url) setIframeLink(res.url);
    //     }
    //     else{
    //         setShowBalanceMsg(true)
    //     }
    // } catch (error) {
    //     setLoading(false);
    // }
  };

  // const tabs = [
  //     {
  //         index:0,
  //         title:"All",
  //         icon:"allcasino"
  //     },
  //     {
  //         index:1,
  //         title:"Roulette",
  //         icon:"roulette",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:2,
  //         title:"Baccarat",
  //         icon:"baccarat",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:3,
  //         title:"Game Shows",
  //         icon:"gameshows",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:4,
  //         title:"Dragon Tiger",
  //         icon:"dragontiger",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:5,
  //         title:"Blackjack",
  //         icon:"blackjack",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:6,
  //         title:"Poker",
  //         icon:"poker",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:7,
  //         title:"Teen Patti",
  //         icon:"teenpatti",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:8,
  //         title:"Indian Games",
  //         icon:"indiangames",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:9,
  //         title:"Slots",
  //         icon:"slots",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  //     {
  //         index:10,
  //         title:"Exchange",
  //         icon:"exchange",
  //         games:[
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //             {
  //                 title:"A",
  //                 image:"/assets/images/casino.jpg"
  //             },
  //         ]
  //     },
  // ]

  return (
    <Box className={`casino-page ${iframeLink ? 'open' : ''}`}>
      {loading && <Loader />}
      <LoginModal open={openLogin} onClose={() => setOpenLogin(false)} />
      <CoinMsg open={showMsg} onClose={() => setShowMsg(false)} />
      <LowBalanceMsg
        open={showBalanceMsg}
        onClose={() => setShowBalanceMsg(false)}
      />
      <Box className="mobile-only">
        <Header
          open={iframeLink}
          setOpen={setIframeLink}
          title={game_id === 'Aura' ? 'Indian Casino' : 'Live Casino'}
        />
      </Box>

      {iframeLink ? (
        <Box
          component={'iframe'}
          width={'100%'}
          height={'100%'}
          src={`${iframeLink}`}
          flex={1}
        />
      ) : (
        <>
          {!game_id && (
            <Box
              onClick={() => openLobby()}
              className={'casino-banner'}
              sx={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Evolution.webp)`,
              }}
            />
          )}

          <CategoryTabs
            selected={selected}
            setSelected={setSelected}
            tabs={tabs}
          />

          <CasinoLobby
            handleOpenGame={handleOpenGame}
            selected={selected}
            tabs={tabs}
            game_id={game_id}
          />
        </>
      )}
    </Box>
  );
}

const Header = ({ open, setOpen = () => {}, title = 'Live Casino' }) => {
  let navigate = useNavigate();
  const handleBackClick = () => {
    if (open) {
      setOpen(null);
    } else {
      navigate('/');
    }
  };
  return (
    <Box className="casino-header">
      <Box className="title-wrap">
        <Stack
          className="pointer"
          width={60}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CustomIcon
            onClick={handleBackClick}
            name={'back-arrow'}
            color={'#fff'}
          />
        </Stack>
        {open ? <Box>Back to Lobby</Box> : <Box>{title}</Box>}
      </Box>
    </Box>
  );
};

const CategoryTabs = ({ selected, setSelected, tabs }) => {
  const handleSelect = (item) => {
    setSelected(item);
  };

  return (
    <Box className={'casino-tabs'}>
      <Box className={'tabs-container'}>
        {tabs.map((item, i) => (
          <Box
            onClick={() => handleSelect(item)}
            className={`tab-item ${
              selected.game_id === item.game_id ? 'selected' : ''
            } pointer`}
          >
            <CustomIcon
              name={getCasinoIconName(item.game_name)}
              fontSize={30}
            />
            <Box className={'title-container'}>{item.game_name}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const CasinoLobby = ({ selected, tabs = [], handleOpenGame, game_id }) => {
  const { category } = useSelector((state) => state.sports);

  const [allGames, setAllGames] = useState([]);

  useEffect(() => {
    let casino = category.find((c) => c.game_type === 'casino');
    if (casino) getEventsByCategory(casino.menu_id);
  }, [category]);

  const getEventsByCategory = async (menu_id) => {
    try {
      const res = await eventServices.getCasinoEventsByCategory(
        menu_id,
        game_id
      );
      if (res && res.length > 0) {
        setAllGames(res);
      }
    } catch (error) {}
    // try {
    //     const res = await eventServices.getEventsByCategory(menu_id);
    //     if(res && res.length > 0)
    //     {
    //         setAllGames(groupBy(res,"game_id"));
    //     }
    // } catch (error) {

    // }
  };

  const renderGameBox = (game) => {
    return (
      <Box onClick={() => handleOpenGame(game)} className={'game-card'}>
        <Box
          className={'game-box'}
          sx={{ background: `url(${game.game_thumbnail})` }}
        />
        <Box className={'empty-box'}>
          <CustomIcon className="icon" name={'roulette'} />
        </Box>
      </Box>
    );
  };

  return (
    <Box className={'casino-lobby'}>
      {selected.game_id === 'All'
        ? tabs.map((item, i) => {
            if (i !== 0) {
              return (
                <>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    className={'category-title'}
                  >
                    <CustomIcon
                      name={getCasinoIconName(item.game_name)}
                      fontSize={14}
                    />
                    <Box className={'casino-label'}>{item.game_name}</Box>
                  </Stack>
                  <Box p={'0 8px'}>
                    {allGames
                      .find((g) => g.game_id === item.game_id)
                      ?.event_data?.map((game) => {
                        // allGames.find((g)=>g.game_id === item.game_id)?.data?.map((game)=>{
                        return renderGameBox(game);
                      })}
                  </Box>
                </>
              );
            }
            return <></>;
          })
        : allGames
            .find((g) => g.game_id === selected.game_id)
            ?.event_data?.map((game) => {
              // allGames.find((g)=>g.game_id === selected.game_id)?.data?.map((game)=>{
              return renderGameBox(game);
            })}
    </Box>
  );
};

const Loader = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--spinner-background-color)',
        zIndex: '999',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 40,
          height: 40,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <CustomIcon
          className={'spinner'}
          name={'loading'}
          fontSize={28}
          color={'white'}
        />
      </Box>
    </Box>
  );
};
