import React, { useEffect, useState } from "react";
import {
  Box,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import eventServices from "../services/eventServices";
const NestedItem = ({
  sportId,
  comp,
  compId,
  sport,
  openLeauge,
  setOpenLeague,
}) => {
  const [events, setEvents] = useState([]);
  // console.log('eventId............', events);
  // console.log('eventId............', comp);

  const { eventId } = useParams();
  // console.log('sportId', sportId);

  const handleClick = () => {
    if (comp.league_id === compId || !openLeauge) setOpenLeague(!openLeauge);
  };

  // const eventId = 0;

  const getEventsByLeagueIdFunc = async (league_id) => {
    const response = await eventServices.getEventsByLeague(league_id);
    if (response) {
      setEvents(response[0].event_list);
      // setEvents(response[0].event_list);
    }
  };

  useEffect(() => {
    if (comp.league_id.toString() === compId && openLeauge) {
      getEventsByLeagueIdFunc(compId);
    }
  }, [compId]);

  const navigate = useNavigate();

  return (
    <Box>
      <Box>
        <ListItem disablePadding onClick={handleClick}>
          <Box
            // onClick={()=>navigate(`league/${comp.league_id}`)}
            onClick={() =>
              navigate(`/sport/${sportId}/league/${comp.league_id}`)
            }
            className={`group-name ${
              comp.league_id.toString() === compId ? "active" : ""
            }`}
            sx={{
              borderLeftColor:
                comp.league_id.toString() === compId
                  ? `${sport.bgcolor} !important`
                  : "none",
              bgcolor:
                comp.league_id.toString() === compId
                  ? `${sport.bgcolor} !important`
                  : "none",
            }}
          >
            {comp.league_name}
          </Box>
        </ListItem>
        {comp.league_id.toString() === compId && openLeauge && (
          <Box component={"ul"} pl={"4px"}>
            {events &&
              events.map((event, i) => {
                return (
                  <Box
                    component={"li"}
                    onClick={() =>
                      navigate(
                        `/sport/${event.provider_game_id}/game-details/${
                          event ? event.provider_event_id : 37373737
                        }/${event?.league_id}`,
                        { state: { ...event, game_id: sportId } }
                      )
                    }
                    sx={{
                      mt: "2px",
                      position: i !== events.length - 1 ? "relative" : "",
                      ":after": {
                        content: "''",
                        display: "block",
                        position: "absolute",
                        top: "-2px",
                        left: "0",
                        background: "#E8E8E8",
                        height: "calc(100% + 4px)",
                        width: "1px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight:
                          eventId === event.game_event_id.toString()
                            ? "700 !important"
                            : 400,
                        color:
                          eventId === event.game_event_id.toString()
                            ? `${sport.bgcolor} !important`
                            : "",
                        ":hover": {
                          color: sport.bgcolor,
                        },
                      }}
                      className={"groupEventName"}
                    >
                      {event.event_name}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NestedItem;
