import { io } from "socket.io-client";
import { API_LINK, SOCKET_LINK } from "../constants/constants";
import { authHeader } from "../helpers/authHeader";
import { parse } from "cookie";
import { socketOptions } from "./../constants/constants";
import parser from "socket.io-msgpack-parser";

// let socket = io(`${API_LINK}/v1/cricket`,
// export const socket = io(`${SOCKET_LINK}/v1/market`,
// {
//     forceNew: true,
//     withCredentials: true,
//     extraHeaders: {
//         Authorization: authHeader(),
//     }
//     // extraHeaders:{
//     //     Authorization:"eyJhbGciOiJIUzUxMiJ9.ZXlKaGJHY2lPaUpJVXpNNE5DSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdVaU9pSTRNREF3T0RZMk5ERTJJaXdpWlcxaGFXd2lPaUprWlcxdk1VQjViM0J0WVdsc0xtTnZiU0lzSW1acGNuTjBYMjVoYldVaU9pSmtaVzF2TVNJc0lteGhjM1JmYm1GdFpTSTZJbkJoZEdWc0lpd2lkWE5sY2tsa0lqb2lNV0YyZHpKdWRWZDNkRkZOYlhkUGVtZENUa053SWl3aWNtOXNaU0k2SWxWelpYSWlMQ0p5YjJ4bFNXUWlPaUpHT1ZWdk5GSmlkelExYWxOSmMwWmhhM1JRTkd3aUxDSnZjR1Z5WVhSdmNsOWllU0k2SWxWaVptVm9RVlZuTlRKNVRtVkdiak5uWm0xelR5SXNJbU4xY25KbGJtTjVYMmxrSWpvaU16bGhTV3RqUlZJNE5rTm5iakJIYVRGQlRsaFhJaXdpYjNCbGNtRjBiM0pmZFhObGNsOXBaQ0k2SW5SaGJtRjVOelVpTENKamRYSnlaVzVqZVY5dVlXMWxJam9pU1U1U0lpd2lhV0YwSWpveE5qZ3dPRFUxT1RnMGZRLmVBeDJHbEwxVG5ENENHWE44U2VoR3JWN3lHMDdybHl0OU5MS2k4MVlCZmF5T3l4ZnJKMTVWQmo2azJLNlE3TjM.-ktuZ4pUx4yfY2kTjujFMQfjIRNF9zUaQdwRrnSzsmCkTEIwMr1p5hebE8Yx9y42XuCOt39ZfKlbZN_xlVVNCw",
//     // }
// });
export let socket;

const connect = (onConnect) => {
  socket = io(`${SOCKET_LINK}/v1/userSubscribe`, {
    // forceNew: true,
    // withCredentials: true,
    // extraHeaders: {
    //     Authorization: authHeader(),
    // }
    parser,
    ...socketOptions,
    query: {
      authorization: authHeader(),
    },
  });

  const COOKIE_NAME = process.env.SECRET_KEY;

  socket.io.on("open", () => {
    socket.io.engine.transport.on("pollComplete", () => {
      const request = socket.io.engine.transport.pollXhr.xhr;
      const cookieHeader = request?.getResponseHeader("set-cookie");
      if (!cookieHeader) {
        return;
      }
      cookieHeader.forEach((cookieString) => {
        if (cookieString.includes(`${COOKIE_NAME}=`)) {
          const cookie = parse(cookieString);
          socket.io.opts.extraHeaders = {
            cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`,
          };
        }
      });
    });
  });

  return new Promise((res, rej) => {
    socket?.on("connect", function (socket) {
      console.log("connected", socket);
      onConnect(true);
      res(socket);
    });
    socket?.once("connect_error", function () {
      // rej(new Error('connect_error'));
    });
    socket?.once("connect_timeout", function () {
      // rej(new Error('connect_timeout'));
    });
    socket?.on("reconnect", () => {
      console.log("reconnected");
    });
    socket?.on("disconnect", function () {
      console.log("disconnect");
      onConnect(false);
    });
  });
};

const reconnect = () => {};

const joinRoom = (data) => {
  console.log(data);
  socket?.emit("join-market-room", JSON.stringify(data));
};

const roomJoined = (setData) => {
  console.log("room-joined");
  socket?.on("room-joined", (data) => {
    setData(data);
  });
};

const leaveRoom = (data) => {
  socket?.emit("market-leave", JSON.stringify(data));
};
const disconnect = (userIds) => {
  // socket?.disconnect()
  const data = {
    leave_userIds: userIds,
  };
  socket?.emit("leave-room", data);
};
const refreshBookmakerList = (getMarkets) => {
  socket?.on("refresh-bookmaker-list", (data) => {
    getMarkets();
  });
};
const listenEvent = (eventName, setData) => {
  socket?.on(eventName, (data) => {
    // console.info('eventData......',data)
    setData(data);
  });
};

const chekUserConnected = (connectToRooms = () => {}) => {
  socket?.on("token-verified", (data) => {
    console.log("IN users-connected - sport", data);
    if (data.data.isVerified) {
      connectToRooms();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/");
    }
  });
};

const socketServices = {
  connect,
  joinRoom,
  listenEvent,
  disconnect,
  leaveRoom,
  roomJoined,
  reconnect,
  // marketerror,
  chekUserConnected,
  refreshBookmakerList,
};

export default socketServices;
