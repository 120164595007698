

export default function Input(theme)
{
    return{
        MuiOutlinedInput:{
            styleOverrides:{
                root:{
                    borderRadius:0,
                    fontSize:14,
                    "& fieldset":{
                        display:"none"
                    },

                    "& input":{
                        padding:"8px",
                        boxShadow:`inset 0 0 0 1px #cecece`,
                        // textAlign:"center",

                        "&:focus":{
                            boxShadow:`inset 0 0 0 2px #cecece`,
                        }
                    },

                    "&.small":{
                        fontSize:12,
                    }
                },
            }
        }
    }
}