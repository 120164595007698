import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLogginIn:false,
    user_id:null,
    user:null,
    balanceData:null,
    settings:{
        confirmBet:true,
        isTwoFactor:false,
        utc:{
            value:"+05:30",
            id:"1",
        }
    }
}


const userSlice = createSlice({
    name:"user",
    initialState:initialState,
    reducers:{
        login:(state,action)=>{
            state.isLogginIn = true;
            state.user = action.payload;
        },
        updateUserID:(state,action)=>{
            state.user_id = action.payload;
        },
        updateBalanceDetails:(state,action) =>{
            state.balanceData = action.payload
        },
        conditionAccepted:(state,action)=>{
            state.user.is_condition_accepted = true;
        },
        changeTimeZone:(state,action)=>{
            state.settings.utc = action.payload;
        },
        changeConfirmBet:(state,action)=>{
            state.settings.confirmBet = !state.settings.confirmBet;
        },
        changeTwoFactor:(state,action)=>{
            state.settings.isTwoFactor = !state.settings.isTwoFactor;
        },
        clearUser:()=>{
            sessionStorage.removeItem("token");
           return initialState;
        }
    }
});

export const {login,updateUserID,clearUser,updateBalanceDetails,conditionAccepted,changeTimeZone,changeConfirmBet,changeTwoFactor} = userSlice.actions;

export default userSlice.reducer;