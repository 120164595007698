import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomIcon from "./customIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function Footer() {
  const [restricted, setRestricted] = useState(false);
  const [underageGambling, setUnderageGambling] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {!restricted && (
        // <Box sx={{position:"fixed",width:"100%",left:0,bottom:0}}>
        <Box sx={{ width: "100vw" }}>
          <MobileView className="custom-class">
            <Box
              sx={{
                height: 48,
                bgcolor: "var(--primary)",
                display: "flex",
                flexDirection: "row",
              }}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <Box
                className="pointer"
                onClick={() => window.open("https://www.gamcare.org.uk/")}
              >
                <CustomIcon
                  name={"gamecare"}
                  fontSize={"28px"}
                  color={"#fff"}
                />
              </Box>
              <Box className="pointer" onClick={() => setRestricted(true)}>
                <CustomIcon
                  name={"earth-lock"}
                  fontSize={"28px"}
                  color={"#fff"}
                />
              </Box>
              <Stack
                className="pointer"
                onClick={() => setUnderageGambling(true)}
                direction={"row"}
                alignItems={"center"}
                spacing={1}
              >
                <CustomIcon name={"18plus"} fontSize={"28px"} color={"#fff"} />
                <Typography
                  width={"125px"}
                  textAlign={"left"}
                  color={"#fff"}
                  fontSize={"11px"}
                >
                  UNDERAGE GAMBLING IS AN OFFENCE
                </Typography>
              </Stack>
            </Box>
          </MobileView>
          <BrowserView className="custom-class">
            <Box
              sx={{
                textAlign: "center",
                bgcolor: "primary.main",
                color: "#fff",
                lineHeight: "20px",
                padding: "21px 20px",
                marginTop: "0",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={"20px"}
                px={"10px"}
                width={"fit-content"}
                margin={"auto"}
              >
                <Box sx={{ height: 32, width: 32 }} />
                <Stack
                  onClick={() => window.open("https://www.gamcare.org.uk/")}
                  direction={"row"}
                  spacing={"10px"}
                  alignItems={"center"}
                >
                  <Box sx={{ height: 32, width: 32 }} />
                  <Typography variant="body2">
                    Underage gambling is an offence
                  </Typography>
                </Stack>
                <Stack
                  onClick={() => setRestricted(true)}
                  direction={"row"}
                  spacing={"10px"}
                  alignItems={"center"}
                >
                  <Box sx={{ height: 32, width: 32 }} />
                  <Typography variant="body2">
                    Restricted territories
                  </Typography>
                </Stack>
                <FooterLink text={"KYC"} to="/account/kyc" />
                <FooterLink text={"Rules & Regulations"} to="/account/rules-regs"  />
                <FooterLink text={"Responsible Gambling"} to="/account/responsible-gambling"/>
                <FooterLink text={"Exclusion Policy"} to="/account/exclusion-policy"/>
                    <Typography variant="body2">@ 2016-2024</Typography>
              </Stack>
            </Box>
          </BrowserView>         
        </Box>
      )}
      {restricted && <RestrictedTerritories setRestricted={setRestricted} />}
      {underageGambling && (
        <UnderageGambling setUnderageGambling={setUnderageGambling} />
      )}
          </>  
  );
}

const FooterLinks = ({ text,onClick }) => {
  return (
    <Typography
      variant="body2"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        ":hover": {
          textDecoration: "underline",
          color: "#e6e6e6",
        },
      }}
    >
      {text}
    </Typography>
  );
};
const FooterLink = ({ text, to }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Typography
        variant="body2"
        sx={{
          cursor: "pointer",
          ":hover": {
            textDecoration: "underline",
            color: "#e6e6e6",
          },
        }}
      >
        {text}
      </Typography>
    </Link>
  );
};
const RestrictedTerritories = ({ setRestricted }) => {
  return (
    <>
      <Box
        sx={{ zIndex: 999 }}
        onClick={() => setRestricted(false)}
        className={"regulatory-links unfolded restricted-territories"}
      >
        <Stack
          className="header"
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          paddingX={1.5}
        >
          <CustomIcon name={"earth-lock"} fontSize={"28px"} />
          <Typography>RESTRICTED TERRITORIES</Typography>
        </Stack>
        <Box class="details">
          <Typography sx={{ marginBottom: "13px", fontSize: "13px" }}>
            Access to this site is restricted in certain geographical
            territories. Customers residing in the listed territories are
            prohibited from accessing the site and its services.
          </Typography>
          <Typography sx={{ marginY: "13px", fontSize: "13px" }}>
            The restricted territories are:
          </Typography>
          <Typography sx={{ marginY: "13px", fontSize: "13px" }}>
            Afghanistan, Algeria, Australia, Bosnia and Herzegovina, Bulgaria,
            Canada, China (People's Republic of), Cuba, Cyprus, Denmark,
            Ethiopia, France (and French territories), Germany, Gibraltar, Iran
            (Islamic Republic of), Iraq, Ireland, Italy, Korea (Democratic
            People's Republic of), Lao (People's Democratic Republic of), Macau,
            Malta, Myanmar, Netherlands, New Zealand, Poland, Portugal, Puerto
            Rico, Qatar, Romania, Russian Federation (Russia), Singapore,
            Slovakia, South Africa, Spain, Sudan, Syrian Arab Republic, Taiwan,
            Turkey, Uganda, Ukraine (Donetsk and Luhansk Regions), United
            Kingdom, United States (and U.S. Territories), Yemen.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const UnderageGambling = ({ setUnderageGambling }) => {
  return (
    <>
      <Box
        sx={{ zIndex: 999 }}
        onClick={() => setUnderageGambling(false)}
        className={"regulatory-links unfolded restricted-territories"}
      >
        <Stack
          className="header"
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
          paddingX={1.5}
        >
          <CustomIcon name={"18plus"} fontSize={"28px"} />
          <Typography fontSize={"14px"}>
            UNDERAGE GAMBLING IS AN OFFENCE
          </Typography>
        </Stack>
        <Stack direction={"column"} justifyContent={"center"} class="details">
          <Box>
            <Box class="title">Protections of minors</Box>
            <Typography
              sx={{ marginY: "13px", fontSize: "13px", textAlign: "center" }}
            >
              It is illegal for anybody under the age of 18 to gamble.
            </Typography>
            <Typography
              sx={{ marginY: "13px", fontSize: "13px", textAlign: "center" }}
            >
              Our site has strict policies and verification measures to prevent
              access to minors.
            </Typography>
            <Typography
              sx={{ marginY: "13px", fontSize: "13px", textAlign: "center" }}
            >
              We encourage parents consider the use of internet use protection
              tools. You may find the following links useful.
            </Typography>
          </Box>
          <Box class="links">
            <Box
              onClick={() => window.open("https://www.cyberpatrol.com/")}
              sx={{
                width: "100%",
                border: "1px solid black",
                padding: "4px",
                textAlign: "center",
                marginBottom: "12px",
              }}
            >
              CYBERPERTROL
            </Box>
            <Box
              onClick={() => window.open("https://www.cybersitter.com/")}
              sx={{
                width: "100%",
                border: "1px solid black",
                padding: "4px",
                textAlign: "center",
              }}
            >
              CYBERSITTER
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
