import { io } from "socket.io-client";
import { API_LINK, SOCKET_LINK } from "../constants/constants";
import { authHeader } from "../helpers/authHeader";
import { parse } from "cookie";
import { socketOptions } from './../constants/constants';
import parser from 'socket.io-msgpack-parser'


let socket;

const connect = (onConnect) =>{
    socket = io(`${SOCKET_LINK}/v1/fancyMarket`, {
        // forceNew: true,
        // withCredentials: true,
        // extraHeaders: {
        //     Authorization: authHeader(),
        // }
        parser,
        ...socketOptions,
        query: {
            authorization: authHeader(),
        }
    })

    const COOKIE_NAME = process.env.SECRET_KEY;

    socket.io.on("open", () => {
        socket.io.engine.transport.on("pollComplete", () => {
            const request = socket.io.engine.transport.pollXhr.xhr;
            const cookieHeader = request?.getResponseHeader("set-cookie");
            if (!cookieHeader) {
                return;
            }
            cookieHeader.forEach(cookieString => {
                if (cookieString.includes(`${COOKIE_NAME}=`)) {
                    const cookie = parse(cookieString);
                    socket.io.opts.extraHeaders = {
                        cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`
                    }
                }
            });
        });
    });

    return new Promise((res,rej)=>{
        socket?.on('connect', function(socket) {
            console.log("connected",socket);
            onConnect(true)
            res(socket);
        });
        socket?.once('connect_error', function() {
            // rej(new Error('connect_error'));
        });
        socket?.once('connect_timeout', function() {
            // rej(new Error('connect_timeout'));
        });
        socket?.on("reconnect",()=>{
            console.log("reconnected")
        })
        socket?.on('disconnect',function(){
            console.log("disconnect");
            onConnect(false)
        })
    })
}


const joinRoom = (data) =>{
    socket?.emit("join-fancy-market-room",JSON.stringify(data));
}

const roomJoined = (setData) =>{
    socket?.on("room-joined",(data)=>{
        setData(data);
    })
}

const leaveRoom = (data) =>{
    socket?.emit("fancy-market-leave",JSON.stringify(data))
}
const disconnect = (userIds) =>{
    // socket?.disconnect()
    const data = {
        leave_userIds:userIds
    }
    socket?.emit("leave-room",data)
}

const listenEvent = (eventName,setData)=>{
    socket?.on(eventName,(data)=>{
        setData(data);
    })
}


const chekUserConnected = (connectToRooms=()=>{}) =>{
    socket?.on('token-verified',(data)=>{
        console.log("IN users-connected - sport",data)
        if(data.data.isVerified){
            connectToRooms();
        }
        else{
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace("/");
        }
    })
}


const fancySocketServices = {
    connect,
    joinRoom,
    listenEvent,
    disconnect,
    leaveRoom,
    roomJoined,
    // marketerror,
    chekUserConnected,
}

export default fancySocketServices;