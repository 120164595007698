import React, { useEffect, useState } from 'react';
import HomeBanner from '../components/homeBanner';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MarketCard from '../components/sportsComponents/marketCard';
import { Box, Skeleton,useMediaQuery, Stack, Typography } from '@mui/material';
import eventServices from '../services/eventServices';
import { convertKeyInArrayOfObjects, findByKeyInArray, groupBy } from '../utils/functions';
import { useSelector } from 'react-redux';
import CustomIcon from '../components/customIcon';
import { useNavigate } from 'react-router-dom';
import betServices from '../services/betServices';
import { BETTYPES } from '../constants/constants';


export default function Home(){

    const [liveEvents,setLiveEvents] = useState();
    const [upcomingEvents,setUpcomingEvents] = useState();
    const [matchBets,setMatchBets] = useState([]);
    const [unmatchBets,setUnMatchBets] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filter,setFilter] = useState(["4","2","1"]);
    const [interval,setIntervalId] = useState(false);
    const {isLogginIn} = useSelector(state => state.user);
    const navigate = useNavigate();
    const customOrder = ["4", "1", "2"];

    useEffect(()=>{
      // getLiveEvents();
      if(isLogginIn) 
      {
        getLiveEvents();
      }
      else {
        getLivePopularEvents();
      }
      getUpcomingPopularEvents();
    },[isLogginIn])

    useEffect(()=>{
      // getUpcomingEvents();
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
      // if(isLogginIn) 
      // {
      //   setTimeout(()=>{
      //     getLiveEvents();
      //     setIntervalId(!interval)
      //   },1000)
      // }
    },[interval,isLogginIn])

    useEffect(()=>{
      if(isLogginIn)
      {
        getOpenBets(BETTYPES.MATCHED);
        getOpenBets(BETTYPES.UNMATCHED);
      }
      else{
        setMatchBets([])
        setUnMatchBets([])
    }
  },[isLogginIn]);

    const getLiveEvents = async() =>{
      // setLoading(true);
      const res = await eventServices.getLiveEvents();
      // setLoading(false);
      if(res && res.length>0)
      {
        const inPlayFilteredData = res.filter(item => item.event_data.length > 0)?.sort((a,b)=>customOrder.indexOf(a.provider_game_id) - customOrder.indexOf(b.provider_game_id)); 
        setLiveEvents(inPlayFilteredData);
        // // setLiveEvents(convertKeyInArrayOfObjects(groupBy(res.data,"game_name"),"data","event_data"));
        // setLiveEvents(filteredEvents);
      }
    }

    const getUpcomingEvents = async() =>{
      const res = await eventServices.getUpcomingEvents();
      setLoading(prevState =>({...prevState,upcoming:false}));
      if(res && !res.response_error && res.data && res.data.length>0)
      {
        let filteredEvents = res.data.filter(event => filter.includes(event.provider_game_id))
        setUpcomingEvents(filteredEvents);
      }
    }

    const getLivePopularEvents = async() =>{
      setLoading(true);
      const res = await eventServices.getLivePopularEvents();
      setLoading(false);
      if(res && res.length>0)
      {
        // let filteredEvents = res.data.filter(game => game.event_data.length>0)
        const inPlayFilteredData = res.filter(item => item.event_data.length > 0)?.sort((a,b)=>customOrder.indexOf(a.provider_game_id) - customOrder.indexOf(b.provider_game_id)); 

        setLiveEvents(inPlayFilteredData);

        // const upcomingFilteredData = res.data.map(item => ({
        //   ...item,
        //   event_data: item.event_data.filter(event => event.provider_status === "UPCOMING")
        // })).filter(item => item.event_data.length > 0);     
           
        // setUpcomingEvents(upcomingFilteredData);
      }
    }
    const getUpcomingPopularEvents = async() =>{
      setLoading(true);
      const res = await eventServices.getUpcomingPopularEvents();
      setLoading(false);
      if(res && res.length>0)
      {
        const upcomingFilteredData = res.filter(item => item.event_data.length > 0)?.sort((a,b)=>customOrder.indexOf(a.provider_game_id) - customOrder.indexOf(b.provider_game_id));     
           
        setUpcomingEvents(upcomingFilteredData);
      }
    }

    

    const getOpenBets = async(bet_type)=>{
      try {
        
        const res = await betServices.getUserOpenBets(bet_type);
        // console.log(res)
        if(res && res.bets && res.bets.length>0)
        {
  
              if(bet_type === BETTYPES.MATCHED) setMatchBets([...res.bets]);
              if(bet_type === BETTYPES.UNMATCHED) setUnMatchBets([...res.bets]);
        }
        else
        {
            if(bet_type === BETTYPES.MATCHED) setMatchBets([]);
            if(bet_type === BETTYPES.UNMATCHED) setUnMatchBets([]);
        }
      } catch (error) {
        
      }
  }


    

    return(
        <>
        <MobileView>
        <HomeBanner />
        </MobileView>
          <Box padding={1}>
            {
              loading ? 
              <LoadingState />
              :
              <>
                {
                 liveEvents && liveEvents.length>0 &&
                <MarketCard live={true} eventData={liveEvents}
                  header={
                  <>
                    <Stack direction={"row"} alignItems={"center"}>
                        <CustomIcon name={"live"} color={"#257b23"}/>
                        <Typography variant="h5" ml={1}>{"Live"}</Typography>
                    </Stack>
                    <Stack onClick={()=>navigate("/my-bets")} class="open-bets-link" style={{color:"black"}} alignItems={"center"}>
                        <Typography variant="body3">Open Bets</Typography>
                        <Box class="count"> {matchBets.length+unmatchBets.length} </Box>
                    </Stack>
                  </>
                  }
                
                />
                }
                <LiveCasinoSlider />
                {
                  upcomingEvents && upcomingEvents.length>0 &&
                  <MarketCard live={false} eventData={upcomingEvents}
                    header={
                    <>
                      <Stack direction={"row"} alignItems={"center"}>
                          <CustomIcon name={"heart"} color={"#cc5f36"}/>
                          <Typography variant="h5" ml={1}>{"Most Popular"}</Typography>
                      </Stack>
                    </>}
                  />
                }
              </>
            }
          </Box> 
        </>
    )
}

const LoadingState = ()=>{
  return(
    <Box>
      <Box className={"skelaton-market"}>
        <Stack direction={"row"} alignItems={"center"} paddingLeft={2}>
          <Skeleton variant='circular' width={20} />
          <Box className={"skelaton-title-box"}>
                <Skeleton variant="rectangular" className="skelaton-title"></Skeleton>
          </Box>
        </Stack>
      </Box>
      <Box className={"skelaton-market"}>
        <Stack direction={"row"} alignItems={"center"} paddingLeft={2}>
          <Skeleton variant='circular' width={15} height={15} />
          <Box className={"skelaton-title-box"}>
                <Skeleton variant="rectangular" className="skelaton-title"></Skeleton>
          </Box>
        </Stack>
      </Box>

      <Box className={"skelaton-market"}>
          <Stack spacing={1} justifyContent={"center"} paddingLeft={2} flex={1}>
            <Skeleton variant='rounded' height={6} width={"75%"} ></Skeleton>
            <Skeleton variant='rounded' height={6} width={"50%"} ></Skeleton>
          </Stack>
          <Skeleton variant="rectangular" className="skelaton-empty-buttons"></Skeleton>
      </Box>
      <Box className={"skelaton-market"}>
          <Stack spacing={1} justifyContent={"center"} paddingLeft={2} flex={1}>
            <Skeleton variant='rounded' height={6} width={"75%"} ></Skeleton>
            <Skeleton variant='rounded' height={6} width={"50%"} ></Skeleton>
          </Stack>
          <Skeleton variant="rectangular" className="skelaton-empty-buttons"></Skeleton>
      </Box>
      <Box className={"skelaton-market"}>
          <Stack spacing={1} justifyContent={"center"} paddingLeft={2} flex={1}>
            <Skeleton variant='rounded' height={6} width={"75%"} ></Skeleton>
            <Skeleton variant='rounded' height={6} width={"50%"} ></Skeleton>
          </Stack>
          <Skeleton variant="rectangular" className="skelaton-empty-buttons"></Skeleton>
      </Box>

      <Box className={"skelaton-market"}>
        <Stack direction={"row"} alignItems={"center"} paddingLeft={2}>
          <Skeleton variant='circular' width={15} height={15} />
          <Box className={"skelaton-title-box"}>
                <Skeleton variant="rectangular" className="skelaton-title"></Skeleton>
          </Box>
        </Stack>
      </Box>

      <Box className={"skelaton-market"}>
          <Stack spacing={1} justifyContent={"center"} paddingLeft={2} flex={1}>
            <Skeleton variant='rounded' height={6} width={"75%"} ></Skeleton>
            <Skeleton variant='rounded' height={6} width={"50%"} ></Skeleton>
          </Stack>
          <Skeleton variant="rectangular" className="skelaton-empty-buttons"></Skeleton>
      </Box>
    </Box>
  )
}

const LiveCasinoSlider = () =>{
  const navigate = useNavigate();
  const CATEGORIES = [
    {name:"Evolution",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_12.webp"},
    {name:"Roulette",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_2.webp"},
    {name:"Baccarat",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_3.webp"},
    {name:"Game Shows",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_4.webp"},
    {name:"Dragon Tiger",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_5.webp"},
    {name:"Blackjack",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_6.webp"},
    {name:"Poker",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_7.webp"},
    {name:"Teen Patti",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_8.webp"},
    {name:"Indian Games",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_9.webp"},
    {name:"Slots",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_10.webp"},
    {name:"Exchange",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_11.webp"},
    {name:"Instant Games",img_link:process.env.PUBLIC_URL +"/assets/images/home/tab_icon_13.webp"},
    {name:"Casino Lobby",img_link:process.env.PUBLIC_URL +"/assets/images/home/lobby.png"},
  ];
  const {casino} = useSelector(state=>state.sports);
  const isLargeScreen = useMediaQuery('(min-width:767px)');

 console.log("isLargeScreen",isLargeScreen)
  if (isLargeScreen) {
    return null;
  }
  return(
    <Box className={"casino-slider"}>
        <Box className={"casino-container"}>   
          <Box className={"banners"}>  
          <Box onClick={()=>navigate(`/live-casino`)} className={"banner pointer"}>
              <Box className={"content-box "}>
                <Box component={"img"} src={process.env.PUBLIC_URL +"/assets/images/home/tab_icon_12.webp"} />
                <Box className={"banner-title"}>Evolution</Box>
              </Box>
            </Box>
          {
            casino.map((category,i)=>(
            <Box onClick={()=>navigate(`/live-casino/tab/${category.provider_game_id}`)} className={"banner pointer"}>
              <Box className={"content-box"}>
                <Box component={"img"} src={CATEGORIES.find((g)=>g.name.toLowerCase() === category.game_name.toLowerCase())?.img_link} />
                <Box className={"banner-title"}>{category.game_name}</Box>
              </Box>
            </Box>
            ))
          }
          </Box>
        </Box>
    </Box>
  )
}

