import { Box, Typography } from '@mui/material';
import betServices from '../../services/betServices';
import { BETTYPES } from '../../constants/constants';
import { useEffect, useState } from 'react';
import { findByKeyInArray, groupBy } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomIcon from '../../components/customIcon';

export default function MyMarkets() {
  const [matchBets, setMatchBets] = useState([]);
  const [unmatchBets, setUnMatchBets] = useState([]);
  const { isLogginIn } = useSelector((state) => state.user);
  const { sports } = useSelector((state) => state.sports);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let allBets = [...matchBets, ...unmatchBets];
    let byEvents = groupBy(allBets, 'event_name', [
      'provider_event_id',
      'sport_id',
      'event_id',
    ]);
    // console.log(byEvents,"sdhasjkdh")
    setEvents(byEvents);
  }, [matchBets, unmatchBets]);

  useEffect(() => {
    if (isLogginIn) {
      getOpenBets(BETTYPES.MATCHED);
      getOpenBets(BETTYPES.UNMATCHED);
    } else {
      setMatchBets([]);
      setUnMatchBets([]);
    }
  }, [isLogginIn]);

  const handleOpen = (event) => {
    const sport = findByKeyInArray(sports, 'game_id', event?.sport_id);
    navigate(
      `/sport/${sport?.provider_game_id}/game-details/${event?.provider_event_id}/${event?.league_id}`,
      {
        state: {
          game_event_id: event.event_id,
          provider_event_id: event.provider_event_id,
        },
      }
    );
  };

  const getOpenBets = async (bet_type) => {
    try {
      const res = await betServices.getUserOpenBets(bet_type);
      // console.log(res)
      if (res && res.bets && res.bets.length > 0) {
        // let byEvents = groupBy(res.bets,"event_name",["provider_event_id","sport_id","event_id"]);
        if (bet_type === BETTYPES.MATCHED) setMatchBets([...res.bets]);
        if (bet_type === BETTYPES.UNMATCHED) setUnMatchBets([...res.bets]);
      } else {
        if (bet_type === BETTYPES.MATCHED) setMatchBets([]);
        if (bet_type === BETTYPES.UNMATCHED) setUnMatchBets([]);
      }
    } catch (error) {}
  };

  return (
    <>
      <Box padding={1}>
        <Box className={'page-title'}>My Markets</Box>
        <Box className={`app-list`}>
          {events.length > 0 ? (
            events.map((event) => {
              return (
                <Box
                  className={`app-list-item my-market-list-item`}
                  onClick={() => handleOpen(event)}
                >
                  <CustomIcon
                    name={
                      findByKeyInArray(sports, 'game_id', event?.sport_id)
                        ?.game_name
                    }
                    fontSize={18}
                  />
                  <Box sx={{ ml: 1 }}>{event?.event_name}</Box>
                </Box>
              );
            })
          ) : (
            <Typography lineHeight={0} fontSize={'14px'}>
              You have no open bets.
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
