

export default function Accordion(theme)
{
    return {
        MuiAccordionSummary: {
            styleOverrides: {
              root: {
                  minHeight:"30px !important",
                  maxHeight:"30px !important",
              },
            }
        },
        MuiAccordionDetails:{
            styleOverrides:{
                root:{
                    padding:"8px"
                }
            }
        }
    }
}