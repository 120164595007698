import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import RightSidePane from "../../../components/rightSidePane";

export default function ReportLayout({ children, title }) {
  const { isLogginIn } = useSelector((state) => state.user);

  return (
    <Box
      sx={{
        display: "table",
        margin: "auto",
        overflow: "hidden",
        zIndex: "1",
        position: "relative",
        minHeight: "420px",
        height: "100%",
        width: "100%",
        padding: "0",
        maxWidth: "1254px",
      }}
    >
      <Box
        sx={{
          display: "table-cell",
          verticalAlign: "top",
          padding: "0 20px 20px",
        }}
      >
        {title && (
          <Typography
            // variant="h1"
            sx={{
              color: "#0f2327",
              marginBottom: "10px",
              padding: "10px 0 10px 0",
              borderBottom: "1px solid rgba(15, 35, 39, 0.4)",
              fontSize: "1.5rem",
              lineHeight: "36px",
            }}
          >
            {title}
          </Typography>
        )}

        {children}
      </Box>

      {/* {isLogginIn && <RightSidePane />} */}
    </Box>
  );
}
