import { Box, Button, Stack, Typography } from "@mui/material";
import CustomIcon from "../../components/customIcon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveOneClickStake,
  changeOneClickStake,
  changeOneClickStatus,
  changeStake,
} from "../../redux/reducers/stakes";
import authServices from "../../services/authServices";
import {
  blockInvalidCharWithDecmial,
  floatWith2DecimalCheck,
} from "../../utils/functions";
import Modal2FactActivated from "../../components/modals/twoFactActiveModal";
import { changeTwoFactor } from "../../redux/reducers/user";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function Settings() {
  const styleObj = {
    h1: {
      fontSize: "16px",
    },
    p: {
      fontSize: "14px",
      lineHeight: 1.15,
    },
    ul: {
      display: "block",
      listStyleType: "disc",
      marginBlockEnd: "1em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      paddingInlineStart: "40px",
      paddingLeft: "15px",
    },
  };
  const [oneClick, setOneClick] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const [editStakes, setEditStakes] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { isTwoFactor } = useSelector((state) => state.user.settings);
  const { values, one_click_stake, is_one_click_bet_enable } = useSelector(
    (state) => state.stake
  );

  useEffect(() => {
    updateUserSettings();
  }, [values, one_click_stake]);

  const updateUserSettings = async () => {
    try {
      const data = {
        is_one_click_bet_enable: false,
        stake: isMobile ? user.stake : values,
        mobile_stake: isMobile ? values : user.mobile_stake,
        one_click_stake,
      };
      const res = await authServices.updateUserSettings(user.user_id, data);
    } catch (error) {}
  };

  return (
    <Box padding={1} sx={styleObj}>
      <Box className={"title"}>
        <Box className={"page-title"}>Settings</Box>
      </Box>
      <Box className={"title"}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography fontSize={"18px"}>One Click Settings</Typography>
          <Box className="pointer" onClick={() => setOneClick(!oneClick)}>
            {" "}
            <CustomIcon name={"alert"} />{" "}
          </Box>
        </Stack>
      </Box>
      {oneClick && (
        <Box className={"info-content"}>
          <p>Using 1 Click Bet is easy.</p>
          <ul>
            <li>Enable 1 Click Betting.</li>
            <li>Edit your 1 Click stake values below.</li>
            <li>Choose your active stake.</li>
            <li>
              When 1 Click Betting is activated, choose different stakes by
              clicking on the red bar.
            </li>
            <li>
              When done with 1 Click Betting, turn off by tapping the toggle to
              off.
            </li>
          </ul>
        </Box>
      )}
      <Box className={"title-and-toggle"}>
        <Typography marginY={"14px"} variant="body3">
          Two-factor Authentication
        </Typography>
        {/* <Switch value={true} /> */}
        <Box sx={{ height: "24px", display: "inline-block" }}>
          <Box
            onClick={() => setTwoFactor(isTwoFactor ? isTwoFactor : !twoFactor)}
            className={`toggle-container ${
              twoFactor || isTwoFactor ? "on" : "off"
            }`}
          >
            <Box className={"toggle-button"}></Box>
          </Box>
        </Box>
      </Box>
      {(twoFactor || isTwoFactor) && (
        <TwoFactorAuth isTwoFactor={isTwoFactor} />
      )}
      <Box className={"title-and-toggle"}>
        <Typography marginY={"14px"} variant="body3">
          Tap to turn on one click betting
        </Typography>
        {/* <Switch value={true} /> */}
        <Box sx={{ height: "24px", display: "inline-block" }}>
          <Box
            onClick={() => dispatch(changeOneClickStatus())}
            className={`toggle-container ${
              is_one_click_bet_enable ? "on" : "off"
            }`}
          >
            <Box className={"toggle-button"}></Box>
          </Box>
        </Box>
      </Box>
      {is_one_click_bet_enable && <OneClickBetting />}
      <Stack
        onClick={() => setEditStakes(!editStakes)}
        direction={"row"}
        alignItems={"center"}
        alignContent={"center"}
        className={`bar ${!editStakes ? "margin-bottom" : ""}`}
      >
        <Box className={"bar-title"}>Stake settings</Box>
        <CustomIcon name={"downarrow-filled"} fontSize={"14px"} />
      </Stack>
      {editStakes && (
        <Box
          className={editStakes ? "margin-bottom" : ""}
          sx={{ marginTop: "8px" }}
        >
          <Box className="title" sx={{ fontWeight: 700 }}>
            Edit Stakes
          </Box>
          <EditStakes />
          {/* <Box className="title" sx={{fontWeight:700}}>Edit Texas Holdem Stakes</Box>
                        <EditStakesTexasHoldem/>                 */}
        </Box>
      )}
    </Box>
  );
}

const TwoFactorAuth = ({ isTwoFactor }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(false);
  const [showSuccess, setSuccess] = useState();

  const dispatch = useDispatch();
  const generateToken = async () => {
    try {
      const res = await authServices.generateTokenForTwoFactor();
      if (res && res.otpAuthUrl) {
        window.open(res.otpAuthUrl, "_self");
      }
      // console.log(res,"fjhdj")
    } catch (error) {}
  };

  const activateTwoAuth = async () => {
    try {
      const data = {
        token: verificationCode.split(" ").join(""),
      };
      await authServices.activateTwoAuth(data);
      dispatch(changeTwoFactor());
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  const deactivateTwoAuth = async () => {
    try {
      const data = {
        token: verificationCode.split(" ").join(""),
      };
      await authServices.deactivateTwoAuth(data);
      dispatch(changeTwoFactor());
      // setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  const handleCodeChanges = (event) => {
    let value = event.target.value.split(" ").join("");
    const newValue = floatWith2DecimalCheck(value);
    if (newValue !== null) {
      if (newValue.toString().length < 7) {
        if (newValue.toString().length >= 4) {
          const formated = newValue.toString().split("");
          formated.splice(3, 0, " ");
          setVerificationCode(formated.join(""));
        } else setVerificationCode(newValue);
      }
    }
  };

  return (
    <Box className={"two-factor-auth"}>
      <Modal2FactActivated
        open={showSuccess}
        onCancel={() => setSuccess(false)}
      />
      <div class="two-factor-authentication-content">
        {isTwoFactor ? (
          <>
            <CustomIcon name="tick" color={"#149909"} fontSize={"14px"} />
            <h4> Two-factor authentication is enabled </h4>
            <p>
              To deactivate, enter your Google Authenticator code and click
              "Deactivate two-factor authentication"
            </p>
            <Box>
              <input
                value={verificationCode}
                onChange={handleCodeChanges}
                style={{ outline: "none" }}
                placeholder="Verification code"
                apptrackclick=""
                onKeyDown={blockInvalidCharWithDecmial}
                class="verification-code-input"
              />
            </Box>
            <button
              style={{ border: "0px", fontSize: "16px" }}
              class="button -danger two-factor-authentication-btn"
              onClick={deactivateTwoAuth}
              disabled={verificationCode.length === 0}
            >
              Deactivate Two-factor authentication
            </button>
          </>
        ) : (
          <>
            <ul class="steps-list">
              <li>
                <b> Step 1: </b> Download Google Authenticator (Available in
                AppStore and GooglePlay).
              </li>
              <li class="services-links">
                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                  <img
                    alt="App Store"
                    class="app-store"
                    src="https://d2eb1cys5jgnub.cloudfront.net/m/tf/download-on-the-app-store.svg"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;gl=US">
                  <img
                    alt="Google Play"
                    class="app-store"
                    src="https://d2eb1cys5jgnub.cloudfront.net/m/tf/get-it-on-google-play.svg"
                  />
                </a>
              </li>
              <li>
                <b> Step 2: </b> Setup account within Google Authenticator.
              </li>
              <li>
                <b> Step 3: </b> To get a token in Google Authenticator and
                enter the verification code down below.
                <a onClick={generateToken} href="#">
                  Press here
                </a>
              </li>
            </ul>
            <Box>
              <input
                value={verificationCode}
                onChange={handleCodeChanges}
                style={{ outline: "none" }}
                placeholder="Verification code"
                apptrackclick=""
                onKeyDown={blockInvalidCharWithDecmial}
                class="verification-code-input"
              />
            </Box>
            <button
              style={{ border: "0px", fontSize: "16px" }}
              class="button -primary two-factor-authentication-btn"
              onClick={activateTwoAuth}
              disabled={verificationCode.length === 0}
            >
              Activate Two-factor authentication
            </button>
          </>
        )}
        {error && (
          <div class="invalid-code">Invalid code. Please try again</div>
        )}
      </div>
    </Box>
  );
};

const OneClickBetting = () => {
  const { one_click_stake, active_one_click_stake } = useSelector(
    (state) => state.stake
  );
  const [stakes, setStakes] = useState([...one_click_stake]);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setStakes([...one_click_stake]);
  }, [one_click_stake]);

  const handleStakeChange = (value, i) => {
    let temp = stakes;
    temp[i] = Number(value);
    setStakes([...temp]);
  };

  const handleSave = () => {
    dispatch(changeOneClickStake(stakes));
    dispatch(changeActiveOneClickStake(stakes[0]));
    setEdit(false);
  };

  const handleClick = (stake) => {
    dispatch(changeActiveOneClickStake(stake));
  };
  return (
    <Box sx={{ display: "block", marginBottom: "8px" }}>
      {edit ? (
        <Box className="stakes-group-edit">
          {stakes.map((e, index) => {
            return (
              <div class={`stake-input`}>
                <Box
                  value={e}
                  type="number"
                  onChange={(e) => handleStakeChange(e.target.value, index)}
                  component={"input"}
                  className="stake"
                />
              </div>
            );
          })}
        </Box>
      ) : (
        <div class="stakes-group">
          {one_click_stake.map((e, index) => {
            return (
              <div
                class={`readonly stake ${
                  e === active_one_click_stake ? "active" : ""
                } `}
                onClick={() => handleClick(e)}
              >
                <div
                  class="wrapper"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "29vw",
                  }}
                >
                  <p style={{ color: "#008000" }}>{e}</p>
                  {e === active_one_click_stake && (
                    <span style={{ color: "#008000", fontSize: "12px" }}>
                      Active Stake
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Stack direction={"row"} justifyContent={"flex-end"}>
        {edit ? (
          <Button
            onClick={handleSave}
            disableRipple={true}
            sx={{
              width: "50%",
              background: "var(--btn_color) !important",
              color: "#fff",
            }}
          >
            Save
          </Button>
        ) : (
          <Button
            onClick={() => setEdit(!edit)}
            disableRipple={true}
            sx={{
              width: "50%",
              background: "var(--btn_color) !important",
              color: "#fff",
            }}
          >
            Edit
          </Button>
        )}
      </Stack>
    </Box>
  );
};

const EditStakes = () => {
  const { values } = useSelector((state) => state.stake);
  const [stakes, setStakes] = useState([...values]);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setStakes([...values]);
  }, [values]);

  const handleStakeChange = (value, i) => {
    let temp = stakes;
    temp[i] = Number(value);
    setStakes([...temp]);
  };

  const handleSave = () => {
    dispatch(changeStake(stakes));
    setEdit(false);
  };

  return (
    <Box >
      {edit ? (
        <Box className="stakes-group-edit">
          {stakes.map((e, index) => {
            return (
              <div class={`stake-input`}>
                <Box
                  value={e}
                  type="number"
                  onChange={(e) => handleStakeChange(e.target.value, index)}
                  component={"input"}
                  className="stake"
                />
              </div>
            );
          })}
        </Box>
      ) : (
        <div class="stakes-group">
          {values.map((e, index) => {
            return (
              <div class={`readonly stake  `}>
                <div
                  class="wrapper"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "15vw",
                  }}
                >
                  <p style={{ color: "#008000" }}>{e}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Stack direction={"row"} justifyContent={"flex-end"}>
        {edit ? (
          <Button
            onClick={handleSave}
            disableRipple={true}
            sx={{
              width: "50%",
              background: "var(--btn_color) !important",
              color: "#fff",
            }}
          >
            Save
          </Button>
        ) : (
          <Button
            onClick={() => setEdit(!edit)}
            disableRipple={true}
            sx={{
              width: "50%",
              background: "var(--btn_color) !important",
              color: "#fff",
            }}
          >
            Edit
          </Button>
        )}
      </Stack>
    </Box>
  );
};
const EditStakesTexasHoldem = () => {
  return (
    <Box sx={{ display: "block", marginBottom: "8px" }}>
      <div class="stakes-group">
        {[...Array(3)].map((e, index) => {
          return (
            <div class={`readonly stake  `}>
              <div
                class="wrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "29vw",
                }}
              >
                <p style={{ color: "#008000" }}>1000</p>
              </div>
            </div>
          );
        })}
      </div>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button
          disableRipple={true}
          sx={{
            width: "50%",
            background: "var(--btn_color) !important",
            color: "#fff",
          }}
        >
          Edit
        </Button>
      </Stack>
    </Box>
  );
};
