import { Box, Button, Dialog, Stack } from '@mui/material';
import { useState } from 'react';

export default function MultipleAccount({ open, onCancel = () => {} }) {
  const [accepted, setAccepted] = useState(false);
  const styleObj = {
    fontSize: '14px',
    h2: {
      fontSize: '21px',
      lineHeight: '1.15',
    },
    '& p': {
      fontSize: '14px',
      lineHeight: 1.15,
      margin: '14px 0px',
    },
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: '5px',
          maxWidth: 'none',
          width: '90%',
        },
      }}
    >
      <Box className={`modal-container change-password`}>
        <Box className={'modal-component-wrap'} sx={styleObj}>
          <div class="">
            <h2>Multiple Account &amp; Bet Limit Rule Acceptance</h2>
            <div _ngcontent-xia-c54="" class="rules-text">
              <p _ngcontent-xia-c54="">
                Members are not permitted to hold multiple accounts. This
                includes holding an account with any associated site operating
                on the same platform as this site.
              </p>
              <p _ngcontent-xia-c54="">
                Where maximum bet or maximum market limits are imposed, the Site
                reserves to the right to void bets from accounts found to be
                exceeding these limits by using multiple accounts across this
                and any other associated sites.
              </p>
              <p _ngcontent-xia-c54="">
                Please confirm your acceptance and understanding of this rule.
              </p>
            </div>
          </div>
          <Stack direction={'row'} spacing={2} justifyContent={'center'}>
            <input
              type="checkbox"
              onChange={(e) => setAccepted(e.target.checked)}
            />
            <Button disabled={!accepted} className="button -primary -large">
              Accept and Continue
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
}
