import { API } from "../constants/constants";
import { handleResponse } from "../utils/functions";
import { server } from "../utils/server";


const getTransferStatement = () =>{
    return server.get(`${API.ACCOUNT.TRANSFER}`)
    .then((res)=>res.data.data)
    .catch((err)=>{
        console.log(err);
    })
}

const getBettingPNL = () =>{
    return server.get(`${API.ACCOUNT.BETTINGPNL}`)
    .then((res)=>res.data.data)
    .catch((err)=>{
        console.log(err);
    })
}
const getBettingPNLByMarket = (marketId) =>{
    return server.get(`${API.ACCOUNT.BETTINGPNLBYMARKET}${marketId}`)
    .then((res)=>res.data.data)
    .catch((err)=>{
        console.log(err);
    })
}

const getBettingPNLByCasino = (marketId) =>{
    return server.get(`${API.ACCOUNT.BETTINGPNLBYCASINO}${marketId}`)
    .then((res)=>res.data.data)
    .catch((err)=>{
        console.log(err);
    })
}

const getNotification = (userId) =>{
    return server.get(`${API.NOTIFICATION}?admin_id=${userId}`)
    .then((res)=>res.data.data)
    .catch((err)=>{
        console.log(err);
    })
}

const accountServices = {
    getTransferStatement,
    getBettingPNL,
    getBettingPNLByMarket,
    getBettingPNLByCasino,
    getNotification
}

export default accountServices;