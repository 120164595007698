

export default function RadioButton(theme)
{
    return {
        MuiFormControl: {
            styleOverrides: {
              root: {
                  width:"100%"
              },
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
              root: {
                  justifyContent:"space-between",
                  marginLeft:"0px",
                  fontSize:"12px"
              },
              label: {
                    fontSize: '12px',
                }
            }
        },
    }
}