// context/bet-slip-context.js
import React, { createContext, useState } from 'react';

export const BetSlipContext = createContext();

export const BetSlipProvider = ({ children }) => {
  const [currentTab, setCurrentTab] = useState('BETSLIP');

  const changeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <BetSlipContext.Provider value={{ currentTab, changeTab }}>
      {children}
    </BetSlipContext.Provider>
  );
};
export default BetSlipProvider;