import { Box, Button, Stack, TextField } from "@mui/material";
import CustomIcon from "../../components/customIcon";
import { useContext, useEffect, useState } from "react";
import authServices from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../redux/reducers/user";
import { ToastContext } from "../../context/context";
import userSocket from "../../services/userSocket";
import { clearStake } from "../../redux/reducers/stakes";
import { userDisconnected } from "../../redux/reducers/socket";


export default function ChangePassword(){

    const [oldPassword,setOldPassword] = useState();
    const [newPassword,setNewPassword] = useState();
    const [repeatPassword,setRepeatPassword] = useState();
    const { user } = useSelector(state=>state.user);
    const {setShowToast} = useContext(ToastContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error,setError] = useState({
        min:false,
        upperLower:false,
        number:false,
        repeat:false,
    });

    useEffect(()=>{     
        validate();
    },[newPassword,repeatPassword])

    const validate = () =>{
        if(newPassword)
        {
            if(newPassword.length>=8 && newPassword.length<=255) setError(prev=>({...prev,min:true}))
            else setError(prev=>({...prev,min:false}))
            if(newPassword.match(/[a-z]/) && newPassword.match(/[A-Z]/)) setError(prev=>({...prev,upperLower:true}))
            else setError(prev=>({...prev,upperLower:false}))
            if(newPassword.match(/[0-9]/)) setError(prev=>({...prev,number:true}))
            else setError(prev=>({...prev,number:false}))
            if(repeatPassword && newPassword === repeatPassword) setError(prev=>({...prev,repeat:true}))
            else setError(prev=>({...prev,repeat:false}))
        }
    }


    const updatePassword = async(e) =>{
        e.preventDefault();
        
        const data = {
            "old_password":oldPassword,
            "new_password":newPassword,
            
        }

        const res = await authServices.updatePassword(user.user_id,data);
        if(res && res.status_code === 200)
        {
            dispatch(clearUser());
            dispatch(clearStake());
            dispatch(userDisconnected());
            userSocket.disconnect();
            navigate("",{replace:true})
            window.location.reload();
        }
        else{
            if(res.status_code === 400) setShowToast({error:true,
                message:"Old password is incorrect!!"})
        }
    }

   
       
        const handleKeyPress = (e) => {
            
            if (e.key === 'Enter') {
               
                updatePassword(e);
               
            }
          };  
      
   


    return(
        <>
            <Box padding={1}>
                <Box className={"page-title"}>Change Password</Box>
                <Box className={"form-group"} >
                    <TextField value={oldPassword} onChange={(e)=>setOldPassword(e.target.value)} type="password"  sx={{margin:"8px 0px"}} InputProps={{className:"small"}} placeholder="Old Password"></TextField>
                    <TextField value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} type="password"  sx={{margin:"8px 0px"}} InputProps={{className:"small"}} placeholder="New Password"></TextField>
                     <TextField value={repeatPassword} onChange={(e)=>setRepeatPassword(e.target.value)} onKeyPress={(!error.min || !error.number || !error.upperLower || !error.repeat || !oldPassword)?  null:handleKeyPress } type="password"  sx={{margin:"8px 0px"}} InputProps={{className:"small"}}   placeholder="Repeat New Password"></TextField>
                <Box className={"password-change-requirements"}>
                    <Box className={"sub-title"} sx={{fontWeight:600}}>Password Should:</Box>
                        <Stack direction={"row"} spacing={1} className={"requirement"}>
                            <CustomIcon name="x" color={error.min?"#149909":"#f53d3d"} fontSize={"14px"}/>
                            <Box className={`message ${error.min ?"":"-invalid"}`}> Be from 8 up to 255 characters. </Box>
                        </Stack>
                        <Stack direction={"row"} spacing={1} className={"requirement"}>
                            <CustomIcon name="x" color={error.upperLower?"#149909":"#f53d3d"} fontSize={"14px"}/>
                            <Box className={`message ${error.upperLower ?"":"-invalid"}`}>  Have at least one upper case and lower case letter. </Box>
                        </Stack>
                        <Stack direction={"row"} spacing={1} className={"requirement"}>
                            <CustomIcon name="x" color={error.number?"#149909":"#f53d3d"} fontSize={"14px"}/>
                            <Box className={`message ${error.number ?"":"-invalid"}`}>  Have at least one number.  </Box>
                        </Stack>
                        <Stack direction={"row"} spacing={1} className={"requirement"}>
                            <CustomIcon name="x" color={error.repeat?"#149909":"#f53d3d"} fontSize={"14px"}/>
                            <Box className={`message ${error.repeat ?"":"-invalid"}`}>  Match repeated password.  </Box>
                        </Stack>
                    </Box>
                    <Button className="button -primary" sx={{width:"50%",fontWeight:"400",mt:"10px",alignSelf:"flex-end"}} onClick={updatePassword}
                        disabled={(!error.min || !error.number || !error.upperLower || !error.repeat || !oldPassword)}>SAVE</Button>
                </Box>
            </Box>
        </>
    )
}