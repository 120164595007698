import { Box, Button, Dialog, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { clearUser } from "../../redux/reducers/user";


export default function Modal18({open,onCancel=()=>{},onAccept=()=>{}}){
    const dispatch = useDispatch();

    const onReject = () =>{
        onCancel();
        dispatch(clearUser())
    }

    return(
    <Dialog open={open}
            PaperProps={{
                sx:{
                    margin:0,
                    borderRadius:"10px",
                    maxWidth:"none",
                }
            }}
        >
            <Box className={`modal-container rules-modal`}>
               <Box className={"modal-component-wrap"} >
                    <Box
                        sx={{
                            padding: 'var(--mPad)'
                        }}
                    >
                        <Box component={"p"} sx={{fontSize:14,textAlign:"center",my:"14px"}}>Are you over 18?</Box>
                        <Stack direction={"row"} pt={"var(--mPad)"}>
                            <Button onClick={onAccept} sx={{fontWeight:400,minWidth:"auto"}} className="button -primary">Yes</Button>
                            <Button onClick={onReject} sx={{fontWeight:400,minWidth:"auto"}} className="button -reject">No</Button>
                        </Stack>
                    </Box>
               </Box>
            </Box>
        </Dialog>
    )
}