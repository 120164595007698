// routesConfig.js
import MainLayout from "./layouts/mainLayout";
import MainLayoutDesktop from "./layouts/mainLayoutDesktop";
import Home from "./pages/home";
import HomeDesktop from "./pages/home-desktop/home";
import GameList from "./pages/gameList";
import MyMarkets from "./pages/profilePages/myMarkets";
import Racing from "./pages/racing";
import GameDetail from "./pages/gameDetails";
import MyBets from "./pages/profilePages/myBets";
import BettingPnL from "./pages/profilePages/bettingPnL";
import TransferStatement from "./pages/profilePages/transferStatement";
import TimeSettings from "./pages/profilePages/timeSettings";
import ChangePassword from "./pages/profilePages/changePassword";
import RulesAndRegs from "./pages/miscPages/rulesAndRegs";
import Kyc from "./pages/miscPages/kyc";
import ResponsibleGambling from "./pages/miscPages/responsibleGambling";
import ExclusionPolicy from "./pages/miscPages/exclusionPoliciy";
import Settings from "./pages/profilePages/settings";
import LeagueEvents from "./pages/leagueEvents";
import LiveCasino from "./pages/liveCasino";
import SportBook from "./pages/profilePages/sportbook";
import { Navigate } from "react-router-dom";
import MenuLayout from "./layouts/menuLayout";
import BettingProfitLoss from "./pages/reports/bettingProfitLoss";

// Mobile Routes
export const mobileRoutes = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      { path: "", element: <Home /> },
      { path: "sport/:sportId", element: <GameList /> },
      { path: "sport/:sportId/league/:leagueId", element: <LeagueEvents /> },
      {
        path: "sport/:sportId/game-details/:eventId/:leagueId",
        element: <GameDetail />,
      },
      { path: "racing/:sportId", element: <Racing /> },
      { path: "settings", element: <Settings /> },
      { path: "my-bets", element: <MyBets /> },
      { path: "betting-pnl", element: <BettingPnL /> },
      { path: "betting-pnl/:marketId", element: <BettingPnL /> },
      {
        path: "my-markets",
        element: <MyMarkets />,
      },
      { path: "transfer", element: <TransferStatement /> },
      { path: "time-settings", element: <TimeSettings /> },
      { path: "change-password", element: <ChangePassword /> },
      { path: "rules-regs", element: <RulesAndRegs /> },
      { path: "kyc", element: <Kyc /> },
      { path: "responsible-gambling", element: <ResponsibleGambling /> },
      { path: "exclusion-policy", element: <ExclusionPolicy /> },
    ],
  },
  { path: "live-casino", element: <LiveCasino /> },
  { path: "live-casino/tab/:tabId", element: <LiveCasino /> },
  { path: "live-casino/tab/:tabId/:game_id", element: <LiveCasino /> },
  { path: "live-casino/:game_id", element: <LiveCasino /> },
  { path: "sportbook", element: <SportBook /> },
  { path: "*", element: <Navigate to="" /> },
];

// Desktop Routes
export const desktopRoutes = [
  {
    path: "",
    element: <MainLayoutDesktop />,
    children: [
      { path: "", element: <HomeDesktop /> },
      {
        path: "sport",
        element: <MenuLayout />,
        children: [
          { path: "", element: <Home /> },
          { path: ":sportId", element: <GameList /> },
          { path: ":sportId/league/:leagueId", element: <LeagueEvents /> },
          {
            path: ":sportId/game-details/:eventId/:leagueId",
            element: <GameDetail />,
          },
          {
            path: "my-markets",
            element: <MyMarkets />,
          },
          { path: "racing/:sportId", element: <Racing /> },
          {
            path: "racing/:sportId/:eventId/:marketId",
            element: <Racing />,
          },
        ],
      },
      {
        path: "account",
        element: <MenuLayout />,
        children: [
          { path: "settings", element: <Settings /> },
          { path: "my-bets", element: <MyBets /> },
          { path: "betting-pnl", element: <BettingProfitLoss /> },
          { path: "betting-pnl/:marketId", element: <BettingProfitLoss /> },
          { path: "transfer", element: <TransferStatement /> },
          { path: "time-settings", element: <TimeSettings /> },
          { path: "change-password", element: <ChangePassword /> },
          { path: "rules-regs", element: <RulesAndRegs /> },
          { path: "kyc", element: <Kyc /> },
          { path: "responsible-gambling", element: <ResponsibleGambling /> },
          { path: "exclusion-policy", element: <ExclusionPolicy /> },
        ],
      },

      { path: "live-casino", element: <LiveCasino /> },
      { path: "live-casino/tab/:tabId", element: <LiveCasino /> },
      { path: "live-casino/tab/:tabId/:game_id", element: <LiveCasino /> },
      { path: "live-casino/:game_id", element: <LiveCasino /> },
      { path: "sportbook", element: <SportBook /> },
    ],
  },
  { path: "*", element: <Navigate to="" /> },
];
