import { Box, Typography } from "@mui/material";
import CustomIcon from "../components/customIcon";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { findByKeyInArray, groupBy } from "../utils/functions";
import eventServices from "../services/eventServices";
import moment from "moment";
import BetPanel from "../components/sportsComponents/betPanel";



export default function LeagueEvents()
{
    const [currentSport,setCurrentSport] = useState();
    const [league,setLeague] = useState();
    const [events,setEvents] = useState([]);

    const [openSlip,setOpenBetSlip] = useState();
    const [liveOdds,setLiveOdds] = useState({});

    const params = useParams();
    const {sports} = useSelector(state => state.sports);
    const navigate = useNavigate();

    useEffect(()=>{
        if(sports && sports.length>0){
            let sport = findByKeyInArray(sports,"provider_game_id",params?.sportId);
            if(sport) setCurrentSport(sport);
        }
    },[sports,params])

    useEffect(()=>{
        getEventsByLeague();
     // eslint-disable-next-line
    },[params])

    const getEventsByLeague = async() =>{
        const res = await eventServices.getEventsByLeague(params.leagueId);
        if(res && res.length >0)
        {
            setLeague(res[0]);
            let temp = res[0].event_list;
            if(temp && temp.length > 0)
            {
                temp = temp.sort((ea,eb)=>parseInt(ea.event_time)-parseInt(eb.event_time)).map((event)=>({...event,eventDate:moment.unix(event.event_time).format("Do MMM")}))
            }
            setEvents(groupBy(temp,"eventDate"))
        }
    }

    return(
        <>
            <Box className={"sport-name-wrap"}>
                <Box className={"crumb-icon right-radius"} onClick={()=>navigate(`/sport/${params?.sportId}`)}>
                    <CustomIcon name={currentSport?.game_name} fontSize={"22px"}/>
                </Box>
                <Typography sx={{fontSize:"14px",marginLeft:"18px",textTransform:"capitalize",pl:"24px"}}>{league?.league_name}</Typography>
            </Box>
            <Box padding={isBrowser?0:1}>
            {
                events?.map((group)=>{
                   return(
                    <Box bgcolor={"white"} className={"event-group"}>
                         <Box className="events-group-title">
                            <Box className="group-title" >
                                <span style={{textTransform:"capitalize"}}>Fixtures {group.eventDate}</span>
                            </Box>
                            <Box className="odds-header">
                                <Box className={"odds-col"}>1</Box>
                                <Box className={"odds-col"}>X</Box>
                                <Box className={"odds-col"}>2</Box>
                            </Box>
                        </Box>
                        {
                            group.data.map((event,i)=>{
                                return(
                                    <BetPanel
                                        event={event}
                                        index={i}
                                        openSlip={openSlip}
                                        setOpenBetSlip={setOpenBetSlip}
                                        sport={currentSport}
                                        runners={event.runners}
                                        market={event}
                                    />
                                )
                            })
                        }
                    </Box>
                   )
                })
            }
          </Box>  
        </>
    )
}