import { Box } from "@mui/material";
import React from "react";

export default function CustomIcon({
  name,
  fontSize,
  minWidth,
  textAlign,
  color,
  onClick = () => {},
  className = "",
}) {
  const icons = [
    {
      title: "uparrow-filled",
      code: '"\\f0de"',
    },
    {
      title: "uniF013",
      code: '"\\f013"',
    },
    {
      title: "downarrow-filled",
      code: '"\\f0dd"',
    },
    {
      title: "x",
      code: '"\\e93e"',
    },
    {
      title: "alert",
      code: '"\\e935"',
    },
    {
      title: "tick",
      code: '"\\f00c"',
    },
    {
      title: "18plus",
      code: '"\\e942"',
    },
    {
      title: "earth-lock",
      code: '"\\e92f"',
    },
    {
      title: "live",
      code: '"\\a00c"',
    },
    {
      title: "heart",
      code: '"\\f004"',
    },
    {
      title: "video-tv",
      code: '"\\e905"',
    },
    {
      title: "inplay",
      code: '"\\a006"',
    },
    {
      title: "inplay-o",
      code: '"\\a008"',
    },
    {
      title: "match-stats",
      code: '"\\a010"',
    },
    {
      title: "clock",
      code: '"\\a014"',
    },
    {
      title: "ladder",
      code: '"\\a015"',
    },
    {
      title: "32223",
      code: '"\\e900"',
    },
    {
      title: "Inplay",
      code: '"\\e901"',
    },
    {
      title: "video-tv",
      code: '"\\e911"',
    },
    {
      title: "info-sign",
      code: '"\\e913"',
    },
    {
      title: "popular",
      code: '"\\e915"',
    },
    {
      title: "my_market",
      code: '"\\e96c"',
    },
    {
      title: "chevron-down",
      code: '"\\e917"',
    },
    {
      title: "chevron-up",
      code: '"\\e918"',
    },
    {
      title: "stopwatch",
      code: '"\\e952"',
    },
    {
      title: "bet-builder",
      code: '"\\a002"',
    },
    {
      title: "heart",
      code: '"\\f004"',
    },
    {
      title: "user",
      code: '"\\f007"',
    },
    {
      title: "remove",
      code: '"\\f00d"',
    },
    {
      title: "time",
      code: '"\\f017"',
    },
    {
      title: "signout",
      code: '"\\f08b"',
    },
    {
      title: "repeat",
      code: '"\\f01e"',
    },
    {
      title: "password",
      code: '"\\f023"',
    },
    {
      title: "open_bets",
      code: '"\\f03a"',
    },
    {
      title: "pnl",
      code: '"\\f0d6"',
    },
    {
      title: "pnl2",
      code: '"\\f0d7"',
    },
    {
      title: "setting",
      code: '"\\f140"',
    },
    {
      title: "plus-square-o",
      code: '"\\f196"',
    },
    {
      title: "balance",
      code: '"\\f19c"',
    },
    {
      title: "settings2",
      code: '"\\f141"',
    },
    {
      title: "gamecare",
      code: '"\\e934"',
    },
    {
      title: "openbets",
      code: '"\\e92c"',
    },
    {
      title: "transferstatement",
      code: '"\\f02e"',
    },
    {
      title: "lock",
      code: '"\\e95f"',
    },
    {
      title: "rules",
      code: '"\\e90d"',
    },
    {
      title: "signout",
      code: '"\\f08c"',
    },
    {
      title: "search",
      code: '"\\f002"',
    },
    {
      title: "menuicon",
      code: '"\\e91e"',
    },
    {
      title: "0percent",
      code: '"\\e941"',
    },
    {
      title: "stopwatch-race",
      code: '"\\e949"',
    },
    {
      title: "right-arrow",
      code: '"\\f054"',
    },
    {
      title: "back-arrow",
      code: '"\\e92e"',
    },
    {
      title: "modal-close",
      code: '"\\e937"',
    },
    {
      title: "loading",
      code: '"\\e939"',
    },

    //sports
    {
      title: "tabletennis",
      code: '"\\e90f"',
    },
    {
      title: "esports",
      code: '"\\e910"',
    },
    {
      title: "greyhound racing",
      code: '"\\a001"',
    },
    {
      title: "horse racing",
      code: '"\\a007"',
    },
    {
      title: "cricket",
      code: '"\\a009"',
    },
    {
      title: "football",
      code: '"\\a00a"',
    },
    {
      title: "tennis",
      code: '"\\a00b"',
    },
    {
      title: "election",
      code: '"\\f140"',
    },
    //left-menu icon
    {
      title: "home",
      code: '"\\f015"',
    },
    {
      title: "mymarkets",
      code: '"\\e93c"',
    },
    {
      title: "livecasino",
      code: '"\\e966"',
    },
    {
      title: "indiancasino",
      code: '"\\e957"',
    },
    {
      title: "slots",
      code: '"\\e956"',
    },
    {
      title: "genie",
      code: '"\\e96f"',
    },

    //casino icons
    {
      title: "allcasino",
      code: '"\\e970"',
    },
    {
      title: "roulette",
      code: '"\\e978"',
    },
    {
      title: "baccarat",
      code: '"\\e971"',
    },
    {
      title: "gameshows",
      code: '"\\e976"',
    },
    {
      title: "dragontiger",
      code: '"\\e973"',
    },
    {
      title: "blackjack",
      code: '"\\e972"',
    },
    {
      title: "poker",
      code: '"\\e977"',
    },
    {
      title: "teenpatti",
      code: '"\\e97a"',
    },
    {
      title: "indiangames",
      code: '"\\e95a"',
    },
    {
      title: "slots",
      code: '"\\e954"',
    },
    {
      title: "sportbook",
      code: '"\\e901"',
    },
    {
      title: "instantgames",
      code: '"\\e974"',
    },
    {
      title: "exchange",
      code: '"\\e974"',
    },
    {
      title: "responsiblegambling",
      code: '"\\e942"',
    },
    {
      title: "kyc",
      code: '"\\e900"',
    },
    {
      title: "casinolobby",
      code: '"\\e97b"',
    },
    // header
    {
      title: "account",
      code: '"\\e901"',
    },
    {
      title: "sm-down-arrow",
      code: '"\\f107"',
    },
    {
      title: "sm-up-arrow",
      code: '"\\f106"',
    },
  ];
  return (
    <Box
      onClick={onClick}
      className={className}
      component={"icon"}
      sx={{
        fontSize: fontSize ? fontSize : "22px",
        textAlign: textAlign ? textAlign : "center",
        minWidth: minWidth ? minWidth : "20px",
        ":before": {
          content: icons?.filter((icon) => icon?.title === name)[0]?.code,
          fontSize: fontSize ? fontSize : "20px",
          fontFamily: "icomoon",
          color: color ? color : "",
        },
      }}
    ></Box>
  );
}
