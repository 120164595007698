import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import InvoiceSection from "./invoiceSection";
import { useSelector } from "react-redux";
import { isNegative } from "../../../utils/functions";
import accountServices from "../../../services/accountServices";

const ProfitLossReportByMarket = ({ setMarket, marketId, isCasino }) => {
  console.log("isCasino", isCasino);
  const userData = useSelector((state) => state.user?.user);
  const [reportData, setReportData] = useState({});
  const [gameName, setGameName] = useState("");
  const [tableHead, setTableHead] = useState([
    "Placed",
    "Game Type",
    "Selection",
    "Bet ID",
    "Type",
    "Odds",
    "Stake",
    "Profit/Loss",
    "Status",
  ]);

  const getProfitLossByMarketFunc = async () => {
    try {
      let response;
      if (isCasino) {
        response = await accountServices.getBettingPNLByCasino(marketId);
      } else {
        response = await accountServices.getBettingPNLByMarket(marketId);
      }
      setReportData(response);
      setGameName(response.bets[0].game_name);
    } catch (error) {
      console.error("Error Fetching Profit Loss Report By Market", error);
    }
  };

  useEffect(() => {
    getProfitLossByMarketFunc();
  }, [marketId]);

  useEffect(() => {
    if (!isCasino) {
      const itemsToRemove = ["Game Type"];
      const tableHeadForOtherSports = tableHead.filter(
        (item) => !itemsToRemove.includes(item)
      );
      setTableHead(tableHeadForOtherSports);
    } else {
      const itemsToRemove = ["Type", "Odds", "Selection"];
      const tableHeadForCasino = tableHead.filter(
        (item) => !itemsToRemove.includes(item)
      );

      if (!tableHeadForCasino.includes("Game Type")) {
        tableHeadForCasino.splice(1, 0, "Game Type");
      }

      setTableHead(tableHeadForCasino);
    }
  }, [gameName]);

  return (
    <>
      {reportData && reportData.market ? (
        <>
          <Stack sx={{ width: "100%" }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography
                onClick={() => setMarket("")}
                fontWeight={600}
                sx={{ cursor: "pointer" }}
              >
                {reportData.market.game_name}
              </Typography>
              <Typography>{reportData.market.event_name}</Typography>
            </Breadcrumbs>
          </Stack>

          <TableContainer sx={{ mt: 4 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#DDDDDD !important" }}>
                  {tableHead.map((th) => {
                    return <TableCell sx={{ p: "8px" }}>{th}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.bets.map((bet) => (
                  <TableRow sx={{ bgcolor: "#F0F0F0" }}>
                    <TableCell sx={{ p: "8px" }}>
                      {moment.unix(bet.matched_at).format("DD/MM/YYYY")} |{" "}
                      {moment.unix(bet.matched_at).format("HH:mm:ss")}
                    </TableCell>
                    {isCasino && (
                      <TableCell sx={{ p: "8px" }}>{bet.event_name}</TableCell>
                    )}
                    {!isCasino && (
                      <TableCell sx={{ p: "8px" }}>{bet.runner_name}</TableCell>
                    )}
                    <TableCell sx={{ p: "8px" }}>{bet.bet_id_ui}</TableCell>
                    {!isCasino && (
                      <>
                        <TableCell sx={{ p: "8px" }}>{bet.type}</TableCell>
                        <TableCell sx={{ p: "8px" }}>{bet.odds}</TableCell>
                      </>
                    )}
                    <TableCell sx={{ p: "8px" }}>{bet.bet_amount}</TableCell>
                    <TableCell
                      sx={{ p: "8px" }}
                      className={
                        isNegative(
                          bet.outcome === "won" ? bet.profit : -bet.liability
                        )
                          ? "-negative"
                          : "-positive"
                      }
                    >
                      {bet.outcome === "won" ? bet.profit : -bet.liability}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        p: "8px",
                        color: bet.outcome === "loss" ? "red" : "green",
                      }}
                    >
                      {bet.outcome.toUpperCase()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isCasino ? (
            <Box sx={{ borderBottom: "1px solid", float: "right", mt: 4 }}>
              Net session Total:{" "}
              {reportData.total.totalProfitLossAfterCommission}
            </Box>
          ) : (
            <InvoiceSection data={reportData.total} />
          )}
        </>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </>
  );
};

export default ProfitLossReportByMarket;
