import { Outlet } from "react-router-dom";
import Header from "../components/header";
// import HeaderDesktop from "../components/header-desktop";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Footer from "../components/footer";
import LoginModal from "../components/modals/login";
import { BetContext, LoginContext, ToastContext } from "../context/context";
// import SideMenu from "../components/sideMenu";
import ChangePassword from "../components/modals/changePassword";
import Modal18 from "../components/modals/modal18";
import KYCModal from "../components/modals/kycModal";
import MultipleAccount from "../components/modals/multipleAccount";
import { useSelector } from "react-redux";
import Modal2Fact from "../components/modals/twoFactModal";

export default function MainLayout() {
  const [openLeftNav, setOpenLeftNav] = useState(false);
  const [openRightNav, setOpenRightNav] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [showModal18, setShowModal18] = useState(false);
  const [showModal2Fact, setShowModal2Fact] = useState(false);
  const [showKYC, setShowKYC] = useState(false);
  const [updateBetslip, setUpdateBetslip] = useState(false);
  const { user, isLogginIn, user_id } = useSelector((state) => state.user);

  useEffect(() => {
    if (isLogginIn) {
      if (!user.is_password_changed) setShowChangePass(true);
      else if (!user.is_condition_accepted) setShowModal18(true);
      else setShowModal18(false);
    }
  }, [user, user?.is_condition_accepted]);

  useEffect(() => {
    if (user_id && !isLogginIn) setShowModal2Fact(true);
  }, [user_id, isLogginIn]);

  return (
    <>
      <Box sx={{}}>
        <LoginContext.Provider value={{ openLogin, setOpenLogin }}>
          <BetContext.Provider value={{ updateBetslip, setUpdateBetslip }}>
            <Header
              openLeftNav={openLeftNav}
              setOpenLeftNav={setOpenLeftNav}
              openRightNav={openRightNav}
              setOpenRightNav={setOpenRightNav}
            />
            {/* <HeaderDesktop /> */}
            <LoginModal open={openLogin} onClose={() => setOpenLogin(false)} />
            <Modal2Fact
              open={showModal2Fact}
              onCancel={() => setShowModal2Fact(false)}
              user_id={user_id}
            />
            <ChangePassword
              open={showChangePass}
              onCancel={() => setShowChangePass(false)}
            />
            <Modal18
              open={showModal18}
              onCancel={() => setShowModal18(false)}
              onAccept={() => setShowKYC(true)}
            />
            <KYCModal open={showKYC} onCancel={() => setShowKYC(false)} />
            <MultipleAccount open={false} />
            {/* <SideMenu /> */}
            <Outlet />
          </BetContext.Provider>
          {/* {!isLogginIn && <Footer />} */}
        </LoginContext.Provider>
      </Box>
      <Footer />
    </>
  );
}
