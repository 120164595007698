import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addBet, clearBets, updateOdds } from "../redux/reducers/bets";
import { useContext, useEffect, useState } from "react";
import { numberWithComma } from "../utils/functions";
import { BetContext } from "../context/context";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { LoginContext } from "../context/context";
import { BetSlipContext } from "../context/bet-slip-context";
// isFancy={market.market_type === "fancy"}
// wholeOdds={market.market_type === "match_odd"}
// slipSide={slipSide} onCancel={()=>setOpenBetSlip(null)}
// line={market.market_type === "fancy" ? 1 : null}
// event={event}
// market={market}
// runner={runner}
// calculateExposure={calculateExposure}

// export default function BetButton(props,{odds,size,onClick=()=>{},side,customClass = "",isFancy=false,market_type,wholeOdds,slipSide,line,event,market,runner,calculateExposure}){
export default function BetButton(props) {
  const [sizeChanged, setSizeChanged] = useState(false);
  const [oddsChanged, setOddschanged] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [prevOdds, setPrevOdds] = useState(0);
  const { setUpdateBetslip } = useContext(BetContext);
  const { changeTab } = useContext(BetSlipContext);
  const { bets } = useSelector((state) => state.bets);
  // const { isLogginIn, userData,settings } = useSelector((state) => state.userDesktop.desktop);
  const { isLogginIn } = useSelector((state) => state.user);
  const { values, is_one_click_bet_enable, active_one_click_stake } =
    useSelector((state) => state.stake);
  const { one_click_stake } = useSelector((state) => state.stake);
  // const {active_one_click_stake} = settings;
  const { setOpenLogin } = useContext(LoginContext);

  const dispatch = useDispatch();
  const generateBetSlip = (e) => {
    // console.log("proops", props);
    if (!disabled && props.customClass.trim() !== "disabled") {
      e.preventDefault();
      e.stopPropagation();
      if (
        isLogginIn &&
        props.event &&
        props.market &&
        props.runner &&
        props.odds
      ) {
        changeTab("BETSLIP");
        const bet = bets?.find(
          (bet) =>
            bet.betId ===
            `${props?.event?.game_event_id}-${props?.market?.market_id}-${props?.runner?.runner_name}-${props?.side}`
        );
        if (!bet && !disabled) {
          props.onClick();
          setUpdateBetslip((prev) => !prev);
          const data = {
            props,
          };
          console.log("BETSLIP", JSON.stringify(data));
          dispatch(clearBets());
          dispatch(addBet(data));
        } else {
          console.log(props.odds);
          console.log(bet.odds);
          if (props.odds !== bet.odds) {
            dispatch(updateOdds({ betId: bet.betId, odds: props.odds }));
          } else {
            console.log("already added");
          }
        }
      } else if (!isLogginIn) {
        setOpenLogin(true);
      }
    }
  };

  useEffect(() => {
    setSizeChanged(true);
    setTimeout(() => {
      setSizeChanged(false);
    }, 300);
  }, [props.size]);

  useEffect(() => {
    setOddschanged(true);
    setTimeout(() => {
      setOddschanged(false);
      setPrevOdds(props.odds);
    }, 300);
    if (!props.odds) setDisabled(true);
    else {
      if (props.market_type === "match_odd" && props.odds >= 11)
        setDisabled(true);
      else setDisabled(false);
    }
  }, [props.odds, props.market_type]);

  const handleClick = () => {
    // console.log('BETSLIP...................', JSON.stringify(props));
    if (!disabled) {
      props.onClick();
      setUpdateBetslip((prev) => !prev);
    }
  };

  return (
    // <Button variant="text">
    <Box
      className={`${props.customClass} bet-button ${disabled && "disabled"} ${
        props.side
      } ${sizeChanged && "size-highlighted"} ${
        oddsChanged
          ? prevOdds > props.odds
            ? "price-down-highlighted"
            : "price-up-highlighted"
          : ""
      }`}
      onClick={isMobile ? handleClick : generateBetSlip}
    >
      <Box>
        <Box class="price">{props.odds ? props.odds : ""}</Box>
        {props.size > 0 && (
          <Box class="market-volume">
            {props.isFancy
              ? props.size
              : Number(props.size) < 5000
              ? numberWithComma(Number(props.size.toFixed(2)) * 100)
              : "500,000+"}
          </Box>
        )}
      </Box>
      {/* <MobileView>
        <Box onClick={handleClick}>
          <Box class="price">{props.odds ? props.odds : ''}</Box>
          {props.size > 0 && (
            <Box class="market-volume">
              {props.isFancy
                ? props.size
                : Number(props.size) < 5000
                ? numberWithComma(Number(props.size.toFixed(2)) * 100)
                : '500,000+'}
            </Box>
          )}
        </Box>
      </MobileView>
      <BrowserView>
        <Box onClick={generateBetSlip}>
          <Box class="price">{props.odds ? props.odds : ''}</Box>
          {props.size > 0 && (
            <Box class="market-volume">
              {props.isFancy
                ? props.size
                : Number(props.size) < 5000
                ? numberWithComma(Number(props.size.toFixed(2)) * 100)
                : '500,000+'}
            </Box>
          )}
        </Box>
      </BrowserView> */}
    </Box>
    // </Button>
  );
}
