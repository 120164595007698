

export default function Switch(theme)
{
    return {
        MuiSwitch: {
            styleOverrides: {
              root: {
                  height:"24px",
                  width:"54px",
                  padding:"0px",
                  borderRadius:"20px"
              },
            }
        },
    }
}