import { Provider } from 'react-redux';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Router from './router';
import ThemeProvider from './theme';
import 'swiper/css';
import 'swiper/css/bundle';
import store from './redux/store';
import { server } from './utils/server';
import { authHeader } from './helpers/authHeader';
import { useEffect, useState } from 'react';
import socketServices from './services/socketServices';
import Initialization from './components/initialize';
import ToastMsg from './components/modals/toast';
import { RefreshSocketContext, ToastContext } from './context/context';
import BetSlipProvider from './context/bet-slip-context';
import BottomMsg from './components/bottomMsg';

const interCeptor = () => {
  server.interceptors.request.use(
    (config) => {
      const token = authHeader();
      config.headers.Authorization = token;
      config.headers.wati = true;
      return config;
    },
    null,
    { synchronous: true }
  );
};
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.info = () => {};
}

function App() {
  const [showToast, setShowToast] = useState();
  const [refresh, setRefresh] = useState(true);
  interCeptor();

  useEffect(() => {
    // socketServices.connect();
    socketServices.reconnect();
  }, []);

  return (
    <Provider store={store}>
      <Initialization />
      <ThemeProvider>
        <RefreshSocketContext.Provider value={{ refresh, setRefresh }}>
          <BetSlipProvider>
            {/* <BusinessMenuProvider> */}
            <ToastContext.Provider value={{ showToast, setShowToast }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ToastMsg showToast={showToast} setShowToast={setShowToast} />
                <Router />
                <BottomMsg />
              </LocalizationProvider>
            </ToastContext.Provider>
          </BetSlipProvider>
          {/* </BusinessMenuProvider> */}
        </RefreshSocketContext.Provider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
