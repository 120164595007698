



import { Box, Button, Dialog } from "@mui/material";
import CustomIcon from "../customIcon";


export default function Modal2FactActivated({open,onCancel=()=>{}}){

    const onReject = () =>{
        onCancel();
    }

    return(
    <Dialog open={open}
            PaperProps={{
                sx:{
                    margin:0,
                    borderRadius:"10px",
                    maxWidth:"none",
                    width:"80%"
                }
            }}
        >
            <Box className={`modal-container`}>
                <Box onClick={onReject}
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                    }}
                >
                    <CustomIcon name={"modal-close"} fontSize={14} />
                </Box>
               <Box className={"modal-component-wrap"} sx={{textAlign:"center",padding:"32px !important"}}  >
                    <p> Two-factor authentication </p>
                    <p>  is activated  </p>

                    <Button onClick={onReject} sx={{fontWeight:400,minWidth:"100%",mt:4}} className="button -primary">OK</Button>
               </Box>
            </Box>
        </Dialog>
    )
}