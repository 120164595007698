import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomIcon from "../../components/customIcon";
import { useContext, useEffect, useState } from "react";
import betServices from "../../services/betServices";
import { BETTYPES } from "../../constants/constants";
import { findByKeyInArray, groupBy } from "../../utils/functions";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContext } from "../../context/context";
import { useSelector } from "react-redux";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function MyBets({ betCount = () => {} }) {
  const [matchBets, setMatchBets] = useState([]);
  const [unmatchBets, setUnMatchBets] = useState([]);
  const { eventId } = useParams();
  const { showToast, setShowToast } = useContext(ToastContext);
  const { isLogginIn } = useSelector((state) => state.user);
  const { sports } = useSelector((state) => state.sports);
  const navigate = useNavigate();

  useEffect(() => {
    let temp = 0;
    if (matchBets.length > 0) {
      matchBets.map((event) => {
        event.data.map((market) => {
          temp += market.data.length;
        });
      });
    }
    if (unmatchBets.length > 0) {
      unmatchBets.map((event) => {
        event.data.map((market) => {
          temp += market.data.length;
        });
      });
    }
    betCount(temp);
  }, [matchBets, unmatchBets]);

  useEffect(() => {
    if (isLogginIn) {
      getOpenBets(BETTYPES.MATCHED);
      getOpenBets(BETTYPES.UNMATCHED);
    } else {
      setMatchBets([]);
      setUnMatchBets([]);
    }
  }, [isLogginIn]);

  useEffect(() => {
    if (showToast && showToast.bet) {
      getOpenBets(BETTYPES.MATCHED);
      getOpenBets(BETTYPES.UNMATCHED);
    }
  }, [showToast]);

  const getOpenBets = async (bet_type) => {
    try {
      const res = await betServices.getUserOpenBets(bet_type);
      // console.log(res)
      if (res && res.bets && res.bets.length > 0) {
        let filteredBets;
        let sortedBets = res.bets.sort(
          (a, b) => Number(b.created_at) - Number(a.created_at)
        );
        if (eventId) {
          filteredBets = sortedBets.filter(
            (bet) => bet.provider_event_id === eventId
          );
        } else {
          filteredBets = sortedBets;
        }
        let byEvents = groupBy(filteredBets, "event_name", [
          "provider_event_id",
          "sport_id",
          "event_id",
          "league_id",
        ]);

        byEvents.forEach((event, i) => {
          byEvents[i].data = groupBy(event.data, "market_name");
        });
        if (bet_type === BETTYPES.MATCHED) setMatchBets([...byEvents]);
        if (bet_type === BETTYPES.UNMATCHED) setUnMatchBets([...byEvents]);
      } else {
        if (bet_type === BETTYPES.MATCHED) setMatchBets([]);
        if (bet_type === BETTYPES.UNMATCHED) setUnMatchBets([]);
      }
    } catch (error) {}
  };

  const cancelUnmatchBets = async (bet_id) => {
    try {
      const res = await betServices.cancelUnmatchBets(bet_id);
      if (res) {
        setShowToast({
          error: res.status_code === 200 ? false : true,
          message: res.status_message || "Something went wrong, Try again!",
        });
        getOpenBets(BETTYPES.UNMATCHED);
        if (res.status_code === 400) {
          getOpenBets(BETTYPES.MATCHED);
        }
      }
    } catch (error) {
      setShowToast({
        error: true,
        message:
          error.status_message || "Please try to refresh the page, Try again!",
      });
      getOpenBets(BETTYPES.MATCHED);
      getOpenBets(BETTYPES.UNMATCHED);
    }
  };

  const handleOpenEvent = (event) => {
    const sport = findByKeyInArray(sports, "game_id", event?.sport_id);
    console.log("sportid event......................", event);
    console.log("sportid event sport......................", sport);
    navigate(
      `/sport/${sport?.provider_game_id}/game-details/${event?.provider_event_id}/${event?.league_id}`,
      {
        state: {
          ...event,
          game_id: sport.sport_id,
        },
      }
    );
  };
  // { state: { ...event, game_id: sportId } }
  return (
    <Box className={"app-content"}>
      {/* <Box className={'page-title'}>Open Bets</Box> */}
      <Box className={isMobile ? "my-bets" : "my-bets-scroll"}>
        <Box className={"my-bets-wrapper"}>
          <Accordion
            defaultExpanded
            sx={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#c8592c" }}
            >
              <Stack direction={"row"} spacing={1}>
                <MobileView>
                  <CustomIcon name={"time"} fontSize={"14px"} color={"#fff"} />
                </MobileView>
                <Typography sx={{ color: "#fff" }}>Unmatched Bets</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {unmatchBets.length > 0 ? (
                unmatchBets.map((event) => {
                  return (
                    <>
                      {!eventId && (
                        <Box
                          onClick={() => handleOpenEvent(event)}
                          className={"name-box"}
                        >
                          <MobileView>
                            <CustomIcon
                              name={
                                findByKeyInArray(
                                  sports,
                                  "game_id",
                                  event?.sport_id
                                )?.game_name
                              }
                            />
                          </MobileView>

                          <Box component={"span"} className={"bet-event-title"}>
                            {event.event_name}
                          </Box>
                        </Box>
                      )}
                      {event.data.map((market) => {
                        return (
                          <>
                            <Box className={"bet-market-title"}>
                              {market.market_name}
                            </Box>
                            {market.data.map((bet) => {
                              return (
                                <Box className={`bet-item ${bet.type}`}>
                                  <Box className={"left-top"}>
                                    <Box className={`price ${bet.type}`}>
                                      {bet.odds}
                                    </Box>
                                    <Box className={"title-info"}>
                                      <Box
                                        component={"span"}
                                        textTransform={"capitalize"}
                                        className={"market-name"}
                                      >
                                        {bet.runner_name}:{" "}
                                        {bet.line
                                          ? bet.type === "back"
                                            ? "Yes"
                                            : "No"
                                          : bet.type}
                                      </Box>
                                      {bet.line && (
                                        <Box
                                          component={"span"}
                                          className={"market-name"}
                                        >
                                          {bet.line}
                                        </Box>
                                      )}
                                      <Box
                                        component={"span"}
                                        className={"placed"}
                                      >
                                        {moment
                                          .unix(bet.created_at)
                                          .format("DD/M/YYYY HH:mm:ss")}
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Box
                                    onClick={() =>
                                      cancelUnmatchBets(bet.bet_id)
                                    }
                                    className={"right-top cancel"}
                                  >
                                    <Box component={"span"}>Cancel</Box>
                                  </Box>

                                  <Box className={"bottom"}>
                                    <Box className={"liability-box"}>
                                      <Box
                                        component={"span"}
                                        className={"liability-title"}
                                      >
                                        Liability:{" "}
                                      </Box>
                                      <Box
                                        component={"span"}
                                        className={"liability"}
                                      >
                                        {bet.liability}
                                      </Box>
                                    </Box>
                                    <Box className={"potential-win-box"}>
                                      <Box
                                        component={"span"}
                                        className={"potential-win-title"}
                                      >
                                        Potential win:{" "}
                                      </Box>
                                      <Box
                                        component={"span"}
                                        className={"potential-win"}
                                      >
                                        {bet.profit}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <Box
                  sx={{
                    padding: "var(--sPad)",
                    background: "var(--white)",
                    margin: "0",
                  }}
                >
                  <Typography fontSize={"14px"}>
                    You have no unmatched bets
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className={"my-bets-wrapper"}>
          <Accordion
            defaultExpanded
            sx={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#7b9b1e !important" }}
            >
              <Stack direction={"row"} spacing={1}>
                <MobileView>
                  <CustomIcon name={"tick"} fontSize={"14px"} color={"#fff"} />
                </MobileView>
                <Typography sx={{ color: "#fff" }}>Matched Bets</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {matchBets.length > 0 ? (
                matchBets.map((event) => {
                  return (
                    <>
                      {!eventId && (
                        <Box
                          onClick={() => handleOpenEvent(event)}
                          className={"name-box"}
                        >
                          <CustomIcon
                            name={
                              findByKeyInArray(
                                sports,
                                "game_id",
                                event?.sport_id
                              )?.game_name
                            }
                          />
                          <Box component={"span"} className={"bet-event-title"}>
                            {event.event_name}
                          </Box>
                        </Box>
                      )}
                      {event.data.map((market) => {
                        // return (
                        //   <>

                        //   </>
                        // );
                        return (
                          <>
                            <MobileView>
                              <Box className={"bet-market-title"}>
                                {market.market_name}
                              </Box>
                              {market.data.map((bet) => {
                                return (
                                  <Box className={`bet-item ${bet.type}`}>
                                    <Box className={"left-top"}>
                                      <Box className={`price ${bet.type}`}>
                                        {bet.odds}
                                      </Box>
                                      <Box className={"title-info"}>
                                        <Box
                                          component={"span"}
                                          textTransform={"capitalize"}
                                          className={"market-name"}
                                        >
                                          {bet.runner_name}:{" "}
                                          {bet.line
                                            ? bet.type === "back"
                                              ? "Yes"
                                              : "No"
                                            : bet.type}
                                        </Box>
                                        {bet.line && (
                                          <Box
                                            component={"span"}
                                            className={"market-name"}
                                          >
                                            ({bet.line})
                                          </Box>
                                        )}
                                        <Box
                                          component={"span"}
                                          className={"placed"}
                                        >
                                          {moment
                                            .unix(bet.created_at)
                                            .format("DD/M/YYYY HH:mm:ss")}
                                        </Box>
                                      </Box>
                                    </Box>

                                    <Box className={"bottom"}>
                                      <Box className={"liability-box"}>
                                        <Box
                                          component={"span"}
                                          className={"liability-title"}
                                        >
                                          Liability:{" "}
                                        </Box>
                                        <Box
                                          component={"span"}
                                          className={"liability"}
                                        >
                                          {bet.liability}
                                        </Box>
                                      </Box>
                                      <Box className={"potential-win-box"}>
                                        <Box
                                          component={"span"}
                                          className={"potential-win-title"}
                                        >
                                          Potential win:{" "}
                                        </Box>
                                        <Box
                                          component={"span"}
                                          className={"potential-win"}
                                        >
                                          {bet.profit}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                );
                              })}
                            </MobileView>

                            <BrowserView>
                              <Box className={"desktop-openbets"}>
                                <TableContainer component={Paper}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          className={"bet-market-title"}
                                        >
                                          <Box>{market.market_name}</Box>
                                        </TableCell>
                                        <TableCell
                                          className={"bet-market-title"}
                                        >
                                          Odds
                                        </TableCell>
                                        {/*                                     
                                    <TableCell>Line</TableCell>
                                    <TableCell>Placed</TableCell>
                                    <TableCell>Cancel</TableCell> */}
                                        <TableCell
                                          className={"bet-market-title"}
                                        >
                                          Liability
                                        </TableCell>
                                        <TableCell
                                          className={"bet-market-title"}
                                        >
                                          Potential Win
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {market.data.map((bet) => (
                                        <TableRow
                                          key={bet.bet_id}
                                          className={`bet-item ${bet.type}`}
                                          style={{
                                            backgroundColor:
                                              bet.type === "back"
                                                ? "skyblue"
                                                : "pink",
                                          }}
                                        >
                                          <TableCell className={"market-name"}>
                                            {/* {bet.runner_name}:{" "}
                                        {bet.line ? (bet.type === "back" ? "Yes" : "No") : bet.type} */}
                                            <Box className={"title-info"}>
                                              {bet.runner_name}
                                              {/* <Box
                                         component={"span"}
                                         textTransform={"capitalize"}
                                        //  className={"market-name"}
                                       >
                                         
                                         {bet.line
                                           ? bet.type === "back"
                                             ? "Yes"
                                             : "No"
                                           : bet.type}
                                       </Box>
                                       {bet.line && (
                                         <Box
                                           component={"span"}
                                           className={"market-name"}
                                         >
                                           ({bet.line})
                                         </Box>
                                      )} */}
                                              {/* <Box
                                                component={"span"}
                                                className={"placed"}
                                              >
                                                {moment
                                                  .unix(bet.created_at)
                                                  .format("DD/M/YYYY HH:mm:ss")}
                                              </Box> */}
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            className={`price ${bet.type}`}
                                          >
                                            {bet.odds}
                                          </TableCell>

                                          {/* <TableCell className={"market-name"}>
                                        {bet.line && <span>({bet.line})</span>}
                                      </TableCell>
                                      <TableCell className={"placed"}>
                                        {moment.unix(bet.created_at).format("DD/MM/YYYY HH:mm:ss")}
                                      </TableCell> */}
                                          {/* <TableCell className={"right-top cancel"} onClick={() => cancelUnmatchBets(bet.bet_id)}>
                                        <span>Cancel</span>
                                      </TableCell> */}
                                          <TableCell className={"liability"}>
                                            {bet.liability}
                                          </TableCell>
                                          <TableCell
                                            className={"potential-win"}
                                          >
                                            {bet.profit}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </BrowserView>
                          </>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <Box
                  sx={{
                    padding: "var(--sPad)",
                    background: "var(--white)",
                    margin: "0",
                  }}
                >
                  <Typography fontSize={"14px"}>
                    You have no matched bets
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className={"my-bets-wrapper"}>
          <Accordion
            defaultExpanded
            sx={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#888 !important" }}
            >
              <Stack direction={"row"} spacing={1}>
                <MobileView>
                  <CustomIcon name={"alert"} fontSize={"14px"} color={"#fff"} />
                </MobileView>
                <Typography sx={{ color: "#fff" }}>Void Bets</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Box
                sx={{
                  padding: "var(--sPad)",
                  background: "var(--white)",
                  margin: "0",
                }}
              >
                <Typography fontSize={"14px"}>You have no void bets</Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
}
