import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const InvoiceSection = ({ data }) => {
  return (
    <>
      {data && (
        <Box
          sx={{
            background: "#fff",
            mt: 4,
            width: "fit-content",
            px: 8,
            py: 4,
            display: "grid",
            gridGap: 16,
            float: "right",
          }}
        >
          <Stack gap={4} direction={"row"}>
            <Typography flex={1}>Back subtotal: </Typography>
            <Typography sx={{ textAlign: "right" }}>
              {(
                data.details.find((detail) => detail.type === "back")
                  ?.totalProfitLoss || 0
              )?.toFixed(2)}
            </Typography>
          </Stack>
          <Stack gap={4} direction={"row"}>
            <Typography flex={1}>Lay subtotal: </Typography>
            <Typography sx={{ textAlign: "right" }}>
              {(
                data.details.find((detail) => detail.type === "lay")
                  ?.totalProfitLoss || 0
              )?.toFixed(2)}
            </Typography>
          </Stack>
          <Stack gap={4} direction={"row"}>
            <Typography flex={1}>Market subtotal: </Typography>
            <Typography sx={{ textAlign: "right" }}>
              {data.totalAfterProfitLossBeforeCommission?.toFixed(2)}
            </Typography>
          </Stack>
          <Stack gap={4} direction={"row"}>
            <Typography flex={1}>Commission: </Typography>
            <Typography sx={{ textAlign: "right" }}>
              {data.totalCommission?.toFixed(2)}
            </Typography>
          </Stack>
          <Stack gap={4} direction={"row"}>
            <Typography flex={1}>Net Market Total: </Typography>
            <Typography
              sx={{ textAlign: "right", borderTop: 1, borderBottom: 1, py: 1 }}
            >
              {data.totalProfitLossAfterCommission?.toFixed(2)}
            </Typography>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default InvoiceSection;
