import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import CustomIcon from "./customIcon";

export default function BetTimer({ betDelay }) {
  const [timer, setTimer] = useState();

  useEffect(() => {
    if (betDelay) {
      setTimer(betDelay);
    }
  }, [betDelay]);

  useEffect(() => {
    if (timer) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <Box className={`modal-container placing-bet-loader-modal`}>
      <Box className={"modal-component-wrap"}>
        <Box className={"modal-content"}>
          <Box component={"p"} my={"14px"}>
            Your bet is being processed. <br /> Please wait...
          </Box>
          {parseInt(betDelay) > 0 && (
            <Box className={"countdown"}>
              <CustomIcon
                className={"spinner"}
                name={"loading"}
                fontSize={60}
              />
              {timer}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
