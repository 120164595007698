// Redux File
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  desktop: {
    exposure: [],
  },
};

const exposureSlice = createSlice({
  name: 'exposures',
  initialState,
  reducers: {
    addExposure: (state, action) => {
      return {
        ...state,
        desktop: {
          exposure: [...state.desktop.exposure, action.payload],
        },
      };
    },
    removeAnExposure: (state, action) => {
      return {
        ...state,
        desktop: {
          exposure: state?.desktop?.exposure?.filter(
            (exp) => exp.exposureId !== action.payload
          ),
        },
      };
    },
    updateExposure: (state, action) => {
      const { exposureId, runnerId, profit } = action.payload;

      const data = {
        ...state,
        desktop: {
          exposure: state.desktop.exposure.map((exposure) =>
            exposure.exposureId === exposureId
              ? {
                  ...exposure,
                  runners: exposure?.runners?.map((runner) =>
                    runner?.runnerId === runnerId
                      ? { ...runner, profit: profit }
                      : runner
                  ),
                }
              : exposure
          ),
        },
      };

      console.log({ data });
      return data;
    },
    removeAllExposures: () => initialState,
  },
});

export const {
  addExposure,
  updateExposure,
  removeAnExposure,
  removeAllExposures,
} = exposureSlice.actions;

export default exposureSlice.reducer;
