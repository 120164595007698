
// production API
export const API_LINK = process.env.REACT_APP_API_LINK;
export const SOCKET_LINK = process.env.REACT_APP_SOCKET_LINK;

// Devlopment API
// export const API_LINK = "http://192.168.0.104:8001";
// export const API_LINK = "http://192.168.0.140:8001";
// export const API_LINK = "https://spacex365.net";
// export const API_LINK = "https://lotusbook365.net";
// export const API_LINK = "https://spacex365.net";
// export const API_LINK = "http://35.172.143.228:8000";

// export const SOCKET_LINK = "https://bet-fair-sep.spacex365.net";
// export const SOCKET_LINK = "http://192.168.0.140:8001";

export const socketOptions = { reconnection: true, reconnectionDelay: 500, reconnectionDelayMax: 500, reconnectionAttempts: Infinity, forceNew: true, transports: ['websocket'], timeout: 10000 }

export const API = {
    LOGIN : "auth/user-login",
    CHANGE_PASSWORD : "user/update-password/",
    CHANGE_DEFAULT_PASSWORD : "user/update-default-password/",
    CHANGE_USER_STAKE:"user/update-user-stake/",
    ACCEPT_CONDITION : "user/update-accept-condition/",
    MENU:"game/business-menu",
    CATEGORY_LIST:"menu",
    SPORT_LIST:"game/get-game-list",
    SPORT_LIST_BY_CATEGORY:"game/get-game-by-menu/",
    LIVE_EVENTS:"event/live-event-list",
    UPCOMING_EVENTS:"event/upcoming-event-market-list",
    LIVE_POPULAR_EVENTS:"event/most-popular-live-event-market-list",
    UPCOMING_POPULAR_EVENTS:"event/most-popular-upcoming-event-market-list",
    SEARCH_EVENT:"event/event_search_list/",
    EVENT_BY_SPORT:"event/event_list/",
    EVENT_BY_CATEGORY:"event/by-menu/",
    CASINO_EVENT_BY_CATEGORY:"event/casino-event/",
    RACE_BY_SPORT:"event/hours-or-greyhounds-event-list/",
    MARKET_BY_RACE:"event/hours-or-greyhounds-event-and-market-list",
    LIVE_EVENTS_BY_SPORT:"event/live_event_list/",
    UPCOMING_EVENTS_BY_SPORT:"event/upcoming_event_list/",
    EVENT_BY_LEAGUE:"event/league-event-list/",
    MARKET_BY_EVENT:"event/market_list/",
    SUSPENDED_MARKET_BY_EVENT:"event/my_market_list/",
    LEAGUE_BY_SPORT:"league/",
    GET_MIN_MAX:"event/getMatchOddOrBookMarkerMinMax",
    GET_FANCY_MIN_MAX:"event/getFancyMinMax",
    CREATE_BET:"bet",
    EXPOSURE_BY_MARKET:"bet/user-exposure-by-market?market_id=",
    LADDER_BY_EVENT:"bet/ladder-exposure?event_id=",
    USER_EXPORSURE:"bet/user-exposure",
    OPEN_BETS:"bet/open-bet?bet_type=",
    CANCEL_BETS:"bet/bet-cancel?bet_id=",
    STREAM:"/api/stream/vStream/",
    SCORE:"/api/stream/vScore/",
    BALANCE:"user/get-user-balance",
    TWO_FACTOR_GENERATE:"auth/generate-token",
    TWO_FACTOR_ACTIVATE:"auth/verify-otp",
    TWO_FACTOR_DEACTIVATE:"auth/two-factor-auth-off",
    TWO_FACTOR_LOGIN:"auth/verify-two-factor-auth",
    ACCOUNT:{
        TRANSFER:"transfer/user-transfer-statement",
        BETTINGPNL:"profit-loss-report",
        BETTINGPNLBYMARKET:"profit-loss-report/by-market/",
        BETTINGPNLBYCASINO:"profit-loss-report/evolution-bets/"
    },

    CASINO_GAME:"casino/login",
    EVOLUTION_GAME:"evolution/login",
    SPORTBOOK:"premium-sport/sportLogin",
    EVENT_SPORTBOOK:"premium-sport/premiumEventLogin",
    NOTIFICATION:"admin-notification",
    VOIDREPORT:"bet/void-report",
    SPORTBOOKSLOTGETOR: "get-slotegrator-url",
}

export const EVENTSTATUS = {
    INPLAY : "IN_PLAY",
    UPCOMING : "UPCOMING",
    SUSPENDED : "SUSPENDED",
}

export const MARKETSTATUS = {
    SUSPENDED : "SUSPENDED"
}

export const RUNNERSTATUS = {
    SUSPENDED : "SUSPENDED",
    BALLRUNNING : "BALL_RUNNING"
}

export const BETTYPES = {
    UNMATCHED:"un-matched",
    MATCHED:"matched",
}