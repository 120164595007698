import Accordion from "./Accordion";
import Button from "./Button";
import CssBaseline from "./CssBaseline";
import Drawer from "./Drawer";
import Input from "./Input";
import RadioButton from "./RadioButton";
import Switch from "./Switch";
import Tabs from "./Tabs";



export default function ComponentsOverrides(theme)
{
    return Object.assign(
        CssBaseline(theme),
        Button(theme),
        Input(theme),
        Tabs(theme),
        Accordion(theme),
        RadioButton(theme),
        Switch(theme),
        Drawer(theme),
    )
}