import { Box, Typography } from "@mui/material";
import accountServices from "../../services/accountServices";
import { useEffect, useState } from "react";
import { addNewField, groupBy } from "../../utils/functions";
import moment from "moment";

export default function TransferStatement(){
    const [transfers,setTransfers] = useState([]);
    useEffect(()=>{
        getTransferStatement();
    },[])

    const getTransferStatement = async() =>{
        const res = await accountServices.getTransferStatement();
        if(res && res.length > 0) {
            const data = res.map((r)=>r.items);
            const afterDate = addNewField(data,"date",(obj)=>moment.unix(obj.created_at).format("Do MMMM YYYY"));
            setTransfers(groupBy(afterDate,"date"))
        }
    }

    return(
        <>
            <Box padding={1} >
                <Box className={"page-title"}>Transfer Statement</Box>
                <Box className={"_transfer-page-columns"}>
                    {
                        transfers.length > 0 ?
                            <Box className={"report"}>
                                <Box className={"report-header"}>
                                    <Box className={"date"}>Date</Box>
                                    <Box className={"description"}>Description</Box>
                                    <Box className={"amount"}>Amount</Box>
                                </Box>
                                {
                                    transfers.map((group)=>{
                                        return(
                                            <Box className={"group"}>
                                                <Box className={"report-header"}>{group.date}</Box>
                                                <Box className={"content"}>
                                                    {
                                                        group.data.map((item)=>{
                                                            return(
                                                                <Box className={"report-details"}>
                                                                    <Box className={"date"}>{moment.unix(item.created_at).format("HH:mm")}</Box>
                                                                    <Box className={"description"}>{item.description}</Box>
                                                                    <Box className={`amount ${item.amount >=0 ?"positive":"negative"}`}>{item.amount}</Box>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>  
                        :
                        <>
                            
                            <Typography lineHeight={0} fontSize={"14px"}>You have no transfers in the last 14 days.</Typography>
                        </>
                    }
                </Box>
            </Box>
        </>
    )
}