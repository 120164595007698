import { API } from "../constants/constants";
import { handleResponse } from "../utils/functions";
import { server } from "../utils/server";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const getMenu = () => {
  let is_sequence = "is_desktop_sequence=1";
  if (isMobile) {
    is_sequence = "is_mobile_sequence=1";
  }
  return (
    server
      .get(API.MENU + `?${is_sequence}&origin=https://roll888.in`)
      // return server.get(API.MENU+`?${is_sequence}&origin=https://roll888.in`)
      .then((res) => {
        return handleResponse(res.data, false);
      })
      .catch((err) => {
        console.log(err);
      })
  );
};

const getAllCategories = () => {
  return server
    .get(API.CATEGORY_LIST)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getAllSports = () => {
  return server
    .get(API.SPORT_LIST)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getAllSportsByCategory = (menu_id) => {
  return server
    .get(`${API.SPORT_LIST_BY_CATEGORY}${menu_id}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getLiveEvents = () => {
  return server
    .get(API.LIVE_EVENTS)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getUpcomingEvents = () => {
  return server
    .get(API.UPCOMING_EVENTS)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getLivePopularEvents = () => {
  return server
    .get(API.LIVE_POPULAR_EVENTS)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getUpcomingPopularEvents = () => {
  return server
    .get(API.UPCOMING_POPULAR_EVENTS)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getEventsBySport = (sportId) => {
  return server
    .get(
      `${API.EVENT_BY_SPORT}${
        sportId ? sportId : "d2ef753d-13c7-43f0-a78d-1b17abf205c8"
      }`
    )
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getEventsByCategory = (menu_id) => {
  return server
    .get(`${API.EVENT_BY_CATEGORY}${menu_id}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getCasinoEventsByCategory = (menu_id, provider_id) => {
  return server
    .get(
      `${API.CASINO_EVENT_BY_CATEGORY}?menu_id=${menu_id}&is_mobile=true${
        provider_id ? `&provider_id=${provider_id}` : ""
      }`
    )
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getRacesBySport = (sportId) => {
  return server
    .get(`${API.RACE_BY_SPORT}${sportId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getRacesRunnersBySport = (eventId, marketId) => {
  return server
    .get(`${API.MARKET_BY_RACE}?event_id=${eventId}&market_id=${marketId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getLiveEventsBySport = (sportId) => {
  return server
    .get(`${API.LIVE_EVENTS_BY_SPORT}${sportId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getUpcomingEventsBySport = (sportId) => {
  return server
    .get(`${API.UPCOMING_EVENTS_BY_SPORT}${sportId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getMarketsByEventId = (eventId) => {
  return server
    .get(`${API.MARKET_BY_EVENT}${eventId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getSuspendedMarketsByEventId = (eventId) => {
  return server
    .get(`${API.SUSPENDED_MARKET_BY_EVENT}${eventId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getExposureByMarket = (marketId) => {
  return server
    .get(`${API.EXPOSURE_BY_MARKET}${marketId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};
const getFancyLadderByMarket = (eventId) => {
  return server
    .get(`${API.LADDER_BY_EVENT}${eventId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getLeaguesBySport = (sportId) => {
  return server
    .get(`${API.LEAGUE_BY_SPORT}${sportId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getEventsByLeague = (leagueId) => {
  return server
    .get(`${API.EVENT_BY_LEAGUE}${leagueId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const searchEventByName = (query) => {
  return server
    .get(`${API.SEARCH_EVENT}${query}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getMinMaxMarket = (marketId, runnerId) => {
  return server
    .get(`${API.GET_MIN_MAX}?marketId=${marketId}&runnerId=${runnerId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};
const getFancyMinMaxMarket = (marketId) => {
  return server
    .get(`${API.GET_FANCY_MIN_MAX}?marketId=${marketId}`)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getSportbook = (data) => {
  return server
    .post(`${API.SPORTBOOK}`, data)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};

const getSportbookByEvent = (data) => {
  return server
    .post(`${API.EVENT_SPORTBOOK}`, data)
    .then((res) => handleResponse(res.data))
    .catch((err) => {
      console.log(err);
    });
};
//  const getRacesBySport = async (game_id) => {
//     try {
//       const response = await instance.get(`/api/event/hours-or-greyhounds-event-list/${game_id}`);
//       return handleResponse(response.data);
//     } catch (error) {
//       console.error(`Error Fetching Event List By Game ID: `, error);
//     }
//   }

// export const searchEventByNames = async(query) => {
//     try {
//       const response = await server.get(`${API.SEARCH_EVENT}${query}`)
//       return handleResponse(response.data)
//     } catch (error) {
//       console.error(`Error Fetching Event Data: `, error);
//     }
//   }

const eventServices = {
  getMenu,
  getAllCategories,
  getEventsBySport,
  getRacesBySport,
  getEventsByLeague,
  getLeaguesBySport,
  getLiveEvents,
  getUpcomingEvents,
  getLivePopularEvents,
  getUpcomingPopularEvents,
  getMarketsByEventId,
  getSuspendedMarketsByEventId,
  getAllSports,
  getExposureByMarket,
  getFancyLadderByMarket,
  getMinMaxMarket,
  getFancyMinMaxMarket,
  searchEventByName,
  getLiveEventsBySport,
  getUpcomingEventsBySport,
  getAllSportsByCategory,
  getEventsByCategory,
  getRacesRunnersBySport,
  getSportbook,
  getSportbookByEvent,
  getCasinoEventsByCategory,
};

export default eventServices;
