import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import EventName from './eventName';
import Odds from './odds';
import { useEffect, useState } from 'react';
import BetSlip from '../betSlip';
import socketServices from '../../services/socketServices';
import { groupBy } from '../../utils/functions';
import BetPanel from './betPanel';
import { useSelector } from 'react-redux';

export default function CompCard({ header, eventData = null, event, runners }) {
  const [events, setEvents] = useState();
  const [openSlip, setOpenBetSlip] = useState();

  const [liveOdds, setLiveOdds] = useState({});
  const [socketUserIds, setSocketUserIds] = useState([]);
  const { isLogginIn } = useSelector((state) => state.user);
  // console.log(eventData);

  useEffect(() => {
    socketServices.roomJoined(handleRoomIds);
    return () => {
      // console.log("leaving",socketUserIds);
      socketUserIds.forEach((data) => {
        socketServices.leaveRoom(data);
      });
      // socketServices.disconnect(socketUserIds)
    };
  }, []);

  const handleRoomIds = (data) => {
    // console.log("room Joined",data);
    if (data && data.data && !data.response_error) {
      // console.log("room Joined",socketUserIds);
      let temp = socketUserIds;
      temp.push(data.data);
      setSocketUserIds(temp);
    }
  };

  useEffect(() => {
    if (eventData && eventData.length > 0) {
      // console.log(groupBy(eventData,"league_name"));
      setEvents(groupBy(eventData, 'league_name'));
    }
  }, [eventData]);

  useEffect(() => {
    if (events && events.length > 0 && isLogginIn) {
      events.forEach((_sport) => {
        _sport.data.forEach((_event) => {
          if (_event.provider_event_id && _event.provider_market_id) {
            let roomName = `${_event.provider_event_id}/${_event.provider_market_id}`;
            let data = {
              roomNames: roomName,
              marketType: _event.market_type,
            };
            socketServices.joinRoom(data);
            socketServices.listenEvent(roomName, handleLiveOdds);
          }
        });
      });
    }
    // eslint-disable-next-line
  }, [events, isLogginIn]);

  const handleLiveOdds = (data) => {
    if (data && !data.response_error && data.data) {
      const temp = liveOdds;
      temp[`${data.data.provider_event_id}/${data.data.provider_market_id}`] =
        data.data;
      setLiveOdds({ ...temp });
    }
  };

  return (
    <Box sx={{ background: '#fff', borderRadius: '2px', marginBottom: '8px' }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={1}
        className="section-title in-play"
      >
        {header}
      </Stack>
      <Box>
        {events &&
          events?.map((_sport) => {
            return (
              <>
                <Box className="events-group-title">
                  <Box className="group-title">
                    <span style={{ textTransform: 'capitalize' }}>
                      {_sport.data[0].game_name} |{' '}
                    </span>
                    <span style={{ textTransform: 'capitalize' }}>
                      {_sport.league_name}
                    </span>
                  </Box>
                  {_sport.data[0].runners.length > 3 ? (
                    <></>
                  ) : (
                    <Box className="odds-header">
                      <Box className={'odds-col'}>1</Box>
                      <Box className={'odds-col'}>X</Box>
                      <Box className={'odds-col'}>2</Box>

                      <Box className={'odds-col onlyDesktop'}>1</Box>
                      <Box className={'odds-col onlyDesktop'}>X</Box>
                      <Box className={'odds-col onlyDesktop'}>2</Box>
                    </Box>
                  )}
                </Box>
                {_sport.data.map((_event, i) => {
                  return (
                    <BetPanel
                      market={
                        liveOdds[
                          `${_event.provider_event_id}/${_event.provider_market_id}/${_event.market_id}`
                        ]
                          ? liveOdds[
                              `${_event.provider_event_id}/${_event.provider_market_id}/${_event.market_id}`
                            ]
                          : _event
                      }
                      event={_event}
                      runners={
                        liveOdds[
                          `${_event.provider_event_id}/${_event.provider_market_id}`
                        ]
                          ? liveOdds[
                              `${_event.provider_event_id}/${_event.provider_market_id}`
                            ].runners
                          : _event.runners
                      }
                      sport={{
                        game_name: _sport.data[0].game_name,
                        game_id: _sport.data[0].game_id,
                        provider_game_id: _sport.data[0].provider_game_id,
                      }}
                      openSlip={openSlip}
                      setOpenBetSlip={setOpenBetSlip}
                      index={i}
                    />
                  );
                })}
              </>
            );
          })}
      </Box>
    </Box>
  );
}

// const BetPanel = ({sport,openSlip,setOpenBetSlip,index,event,runners}) =>{
//     const [slipSide,setSlipSide] = useState(0);

//     const handleClick = (side) =>{
//         setOpenBetSlip(sport.game_name+"-"+index);
//         setSlipSide(side);
//     }
//     return(
//         <Box className={"app-list"}>
//             <Box className={"market-list-item"}>
//                 <Box className={"market"} >
//                     <EventName event={event} sportId={sport.game_name}/>
//                     <Odds runners={runners} onClick={(side)=>handleClick(side)} />
//                 </Box>
//                 {
//                     openSlip === `${sport.game_name}-${index}` &&
//                     <BetSlip slipSide={slipSide} onCancel={()=>setOpenBetSlip(null)} />
//                 }
//             </Box>
//         </Box>
//     )
// }
