import PropTypes from "prop-types";
import { createTheme,ThemeProvider as MUIThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { useMemo } from "react";
import ComponentsOverrides from "./overrirdes";
import palette from "./palette";
import typography from "./typography" 

ThemeProvider.propTypes = {
    children: PropTypes.node,
};

export default function ThemeProvider({children})
{
    const themeOptions = useMemo(
        ()=>({
            palette,
            typography,
        }),
        []
    );

    const theme = createTheme(themeOptions)
    theme.components = ComponentsOverrides(theme);
    
    return(
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    )

}   