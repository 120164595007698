import { Box, Skeleton, Grid, Stack, Tab, Tabs } from "@mui/material";
import CustomIcon from "../components/customIcon";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import MyBets from "./profilePages/myBets";
import BetSlip from "./../components/betSlip";
import { useLocation, useParams } from "react-router-dom";
import eventServices from "../services/eventServices";
import socketServices, { socket } from "../services/socketServices";
import {
  API,
  API_LINK,
  EVENTSTATUS,
  RUNNERSTATUS,
} from "../constants/constants";
import { useSelector } from "react-redux";
import BetButton from "../components/betButton";
import {
  calcProfit,
  formatUnixTimestamp,
  getIP,
  showNumberWithDecimal,
  timeDifferenceFromNow,
} from "../utils/functions";
import { LoginContext, ToastContext } from "../context/context";
import LadderModal from "../components/modals/ladder";
import IconInfo from "../components/modals/iconInfo";
import eventSocket from "../services/eventSocket";
import fancySocketServices from "../services/fancySocketServices";

const EventContext = createContext({});

export default function GameDetail() {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState();
  const [interval, setIntervalId] = useState(false);
  const [selectedTab, setSelectedTab] = useState("MARKET");
  const [suspended, setSuspended] = useState(false);
  const [isInplay, setIsInplay] = useState(false);
  const [matchOdds, setMatchOdds] = useState({});
  const [matchMarkets, setMatchMarkets] = useState([]);
  const [bookmakerOdds, setBookmakerOdds] = useState({});
  const [bookmakerMarkets, setBookmakerMarkets] = useState([]);
  const [fancyMarkets, setFancyMarkets] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [socketUserIds, setSocketUserIds] = useState([]);
  const [fancyRoomIds, setfancyRoomIds] = useState([]);
  const [showIconInfo, setShowIconInfo] = useState();
  const [openSlip, setOpenBetSlip] = useState();
  const { state } = useLocation();
  const { isLogginIn, user } = useSelector((state) => state.user);
  const { odds_socket, event_socket } = useSelector((state) => state.sockets);
  const MatchOddCustomSort = [
    "Extra Time",
    "Match Odds",
    "Over/Under 0.5 Goals",
    "Over/Under 1.5 Goals",
    "Over/Under 2.5 Goals",
    "Over/Under 3.5 Goals",
  ];

  const [openBets, setOpenBets] = useState(0);

  const handleReconnectionRef = useRef();
  const handleEventReconnectionRef = useRef();
  const params = useParams();
  // console.log(state);
  // console.log('sportid', params);
  // console.log('sportid .....', useLocation());
  useEffect(() => {
    socketServices.roomJoined(handleRoomIds);
    fancySocketServices.roomJoined(handleFancyRoomIds);
    return () => {
      console.log("leaving", socketUserIds);
      socketUserIds.forEach((data) => {
        socketServices.leaveRoom(data);
      });
      fancyRoomIds.forEach((data) => {
        fancyRoomIds.leaveRoom(data);
      });
      // socketServices.disconnect(socketUserIds)
    };
  }, []);

  useEffect(() => {
    if (state) {
      setIsInplay(state.provider_status === EVENTSTATUS.INPLAY);
      getMarkets();
    }
    // if(state && state.provider_event_id) {
    //     if(isLogginIn) {
    //         // handleEventConnection(true);
    //         eventSocket.chekUserConnected(handleEventReconnectionRef.current)
    //     }
    // }
    // return ()=>{
    //     if(state)
    //     {
    //         const data = {
    //             eventId:state.provider_event_id
    //         }
    //         eventSocket.leaveEvent(data)
    //     }
    // }
    // eslint-disable-next-line
  }, [state, isLogginIn]);

  useEffect(() => {
    eventSocket.chekUserConnected(handleEventReconnectionRef.current);
    if (state && event_socket?.isConnected) {
      handleEventReconnectionRef.current();
    }
    return () => {
      if (state) {
        const data = {
          eventId: state.provider_event_id,
        };
        eventSocket.leaveEvent(data);
      }
    };
  }, [state, event_socket?.isConnected]);

  useEffect(() => {
    if (
      state &&
      state.provider_event_id &&
      isLogginIn &&
      odds_socket?.isConnected
    ) {
      getFancyMarkets();
    }
  }, [state, isLogginIn, odds_socket?.isConnected]);

  useEffect(() => {
    if (event && event.provider_status === EVENTSTATUS.INPLAY) {
      setIsInplay(true);
      setSuspended(false);
    }
    if (event && event.provider_status === EVENTSTATUS.SUSPENDED)
      setSuspended(true);
  }, [event, event?.provider_status]);

  handleReconnectionRef.current = function () {
    console.log(
      "connected in game detail page",
      isLogginIn,
      event,
      socketUserIds.length
    );
    if (isLogginIn && event && socketUserIds.length === 0) {
      console.log("joining rooms");
      connectToMatchOddSocket();
      connectToBookmakerSocket();
      getFancyMarkets();
    }
  };

  handleEventReconnectionRef.current = function () {
    if (isLogginIn && state.provider_event_id) {
      // console.log('sportid', state.provider_event_id);
      const data = {
        eventId: state.provider_event_id,
      };
      eventSocket.joinEvent(data);
    }
  };

  useEffect(() => {
    if (socket && event) {
      socket.on("connect", () => handleReconnectionRef.current());

      socket.on("disconnect", function () {
        console.log("kfnjdklfjklsd");
        setSocketUserIds([]);
      });
    }
  }, [event]);

  // useEffect(() => {
  //   getMarkets();
  //   // setTimeout(()=>{
  //   //     setIntervalId(!interval)
  //   // },1000)
  // }, [interval]);

  const getMarkets = async () => {
    try {
      const res = await eventServices.getMarketsByEventId(state.game_event_id);
      // setTimeout(()=>{
      //     setIntervalId(!interval)
      // },1000)
      if (res) {
        let MarketData = res;
        let sortedMatchOdds = MarketData.match_odd.sort(
          (a, b) =>
            MatchOddCustomSort.indexOf(a.market_name) -
            MatchOddCustomSort.indexOf(b.market_name)
        );
        setEvent(MarketData);
        setMatchMarkets(sortedMatchOdds);
        setBookmakerMarkets(MarketData.bookmaker);
        // setFancyMarkets(MarketData.fancy);
        // setFancy(MarketData.)
      } else {
        const res2 = await eventServices.getSuspendedMarketsByEventId(
          state.game_event_id
        );
        if (res2) {
          let MarketData = res2;
          let sortedMatchOdds = MarketData.match_odd.sort(
            (a, b) =>
              MatchOddCustomSort.indexOf(a.market_name) -
              MatchOddCustomSort.indexOf(b.market_name)
          );
          setEvent(MarketData);
          setMatchMarkets(sortedMatchOdds);
          setBookmakerMarkets(MarketData.bookmaker);
          // setFancyMarkets(MarketData.fancy);
          // setFancy(MarketData.)
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getFancyMarkets = () => {
    console.log("getting Fancy");
    const data = {
      roomNames: `${state.provider_event_id}`,
      marketType: "fancy",
    };
    fancySocketServices.joinRoom(data);
    fancySocketServices.listenEvent(
      `${state.provider_event_id}`,
      handleFancySocketData
    );
  };

  useEffect(() => {
    if (socket) {
      console.log(socket.id);
    }
  }, [socket]);

  // useEffect(()=>{
  //     if(socket )
  // },[socket])

  useEffect(() => {
    connectToMatchOddSocket();
    // eslint-disable-next-line
  }, [matchMarkets, isLogginIn, socket?.connected]);

  useEffect(() => {
    connectToBookmakerSocket();
    // eslint-disable-next-line
  }, [bookmakerMarkets, isLogginIn]);

  // useEffect(()=>{
  //     if(isLogginIn && isInplay)
  //     {
  //         if(event?.is_live_score && event?.is_score_available) setSelectedTab("INFO")
  //         else if(event?.is_live_stream && event?.is_stream_available) setSelectedTab("VIDEO")
  //         else setSelectedTab("MARKET")
  //     }
  // },[isLogginIn,event?.is_live_score,event?.is_score_available, isInplay,event?.is_live_stream, event?.is_stream_available])

  const handleEventConnection = () => {
    setTimeout(() => {
      const data = {
        eventId: state.provider_event_id,
      };
      eventSocket.joinEvent(data);
    }, 1000);
    eventSocket.getEventDetails(handleEventSocketData);
  };

  const connectToMatchOddSocket = () => {
    if (
      matchMarkets &&
      matchMarkets.length > 0 &&
      isLogginIn &&
      socket?.connected
    ) {
      matchMarkets.forEach((market) => {
        const data = {
          roomNames: `${event.provider_event_id}/${market.provider_market_id}`,
          marketType: "match_odd",
        };
        socketServices.joinRoom(data);
        socketServices.listenEvent(
          `${event.provider_event_id}/${market.provider_market_id}`,
          handleMatchOddsSocketData
        );
      });
    }
  };

  const connectToBookmakerSocket = () => {
    if (bookmakerMarkets && bookmakerMarkets.length > 0 && isLogginIn) {
      socketServices.refreshBookmakerList(getMarkets);
      bookmakerMarkets.forEach((market) => {
        const data = {
          roomNames: `${event.provider_event_id}/${market.provider_market_id}`,
          marketType: "bookmaker",
        };
        socketServices.joinRoom(data);
        socketServices.listenEvent(
          `${event.provider_event_id}/${market.provider_market_id}`,
          handleBookmakerSocketData
        );
      });
    }
  };

  const handleRoomIds = (data) => {
    // console.log("room Joined",data);
    if (data && data.data && !data.response_error) {
      // console.log("room Joined",socketUserIds);
      let temp = socketUserIds;
      temp.push(data.data);
      setSocketUserIds(temp);
    }
  };

  const handleFancyRoomIds = (data) => {
    // console.log("room Joined",data);
    if (data && data.data && !data.response_error) {
      // console.log("room Joined",socketUserIds);
      let temp = socketUserIds;
      temp.push(data.data);
      setfancyRoomIds(temp);
    }
  };

  const handleMatchOddsSocketData = (data) => {
    // console.info("Match odds.......")
    // console.info(data);
    if (data && !data.response_error && data.data) {
      const temp = matchOdds;
      temp[data.data.provider_market_id] = data.data;
      // console.info("Match odds.......")
      // console.info(data.data);
      setMatchOdds({ ...temp });
    }
  };

  const handleBookmakerSocketData = (data) => {
    if (data && !data.response_error && data.data) {
      const temp = bookmakerOdds;
      temp[data.data.provider_market_id] = data.data;
      // console.info("Bookmaker.........")
      //     console.info(data.data);
      setBookmakerOdds({ ...temp });
    }
  };

  const handleFancySocketData = (data) => {
    if (
      data &&
      !data.response_error &&
      data.data &&
      data.data.fancy?.length > 0
    ) {
      setFancy([...data.data.fancy]);
    }
  };

  const handleEventSocketData = (data) => {
    // console.log(data,"event data");
    if (data) {
      setEvent(data);
    }
  };

  return (
    <EventContext.Provider value={{ event }}>
      <>
        <IconInfo
          open={showIconInfo}
          info={showIconInfo}
          onClose={() => setShowIconInfo()}
        />
        <Box className={"other-event-details"}>
          <Box className={"main-detail-header"}>
            {loading ? (
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Skeleton
                  sx={{ bgcolor: "grey.600" }}
                  variant="circular"
                  width={20}
                />
                <Box className={"skelaton-title-box"}>
                  <Skeleton
                    sx={{ bgcolor: "grey.600" }}
                    animation={"pulse"}
                    variant="rectangular"
                    className="skelaton-title"
                  ></Skeleton>
                </Box>
              </Stack>
            ) : (
              <Stack direction={"row"} spacing={1}>
                {!isInplay && !suspended && (
                  <CustomIcon name={"time"} fontSize={"14px"} />
                )}
                <Box className={"event-title-box"}>
                  {isInplay ? (
                    <Box className={"label"}>In Play</Box>
                  ) : suspended ? (
                    <Box className={"label"}>Suspened</Box>
                  ) : (
                    <Box lineHeight={1} fontSize={"10px"}>
                      {formatUnixTimestamp(event?.event_time)} (Opens in{" "}
                      {timeDifferenceFromNow(event?.event_time)})
                    </Box>
                  )}
                  <Box className={"event-title"}>{event?.event_name}</Box>
                  <Box lineHeight={0.5} className={"competition"}>
                    {event?.league_name}
                  </Box>
                </Box>
              </Stack>
            )}
          </Box>
        </Box>
        {isMobile ? (
          <Tabs
            variant="fullWidth"
            value={selectedTab}
            onChange={(e, v) => setSelectedTab(v)}
            className="nav-tabs-group-container sticky-top"
          >
            <Tab
              disableRipple={true}
              label="MARKET"
              value={"MARKET"}
              className={`nav-tab ${
                selectedTab === "MARKET" ? "active" : ""
              } mobile-only`}
            />
            <Tab
              sx={{
                display:
                  event?.is_live_score &&
                  event?.is_score_available &&
                  isInplay &&
                  isLogginIn
                    ? "inline-flex"
                    : "none",
              }}
              disableRipple={true}
              label="MATCH INFO"
              value={"INFO"}
              className={`nav-tab ${
                selectedTab === "INFO" ? "active" : ""
              } mobile-only`}
            />
            <Tab
              sx={{
                display:
                  event?.is_live_stream &&
                  event?.is_stream_available &&
                  isInplay &&
                  isLogginIn
                    ? "inline-flex"
                    : "none",
              }}
              disableRipple={true}
              label="LIVE VIDEO"
              value={"VIDEO"}
              className={`nav-tab ${
                selectedTab === "VIDEO" ? "active" : ""
              } mobile-only`}
            />
            <Tab
              disableRipple={true}
              value={"BETS"}
              label={`OPEN BETS (${openBets})`}
              className={`nav-tab ${
                selectedTab === "BETS" ? "active" : ""
              } mobile-only`}
            />
          </Tabs>
        ) : (
          <></>
        )}
        <Box display={selectedTab === "BETS" ? "block" : "none"}>
          <MyBets betCount={setOpenBets} />
        </Box>
        {/* {
                    selectedTab === 3 ? 
                    :
                    <></>
                } */}
        {selectedTab === "VIDEO" ? <LiveVideo event={event} /> : <></>}
        {selectedTab === "INFO" ? <MatchInfo event={event} /> : <></>}
        {selectedTab === "MARKET" &&
        isBrowser &&
        event?.is_live_score &&
        event?.is_score_available &&
        isInplay &&
        isLogginIn ? (
          <Box sx={{ marginBottom: "20px", bgcolor: "white" }}>
            <Grid
              container
              alignItems="center"
              justify="flex-center"
              spacing={1}
            >
              {
                <Grid item xs={12} sm={6} md={6}>
                  <MatchInfo event={event} />
                </Grid>
              }

              <Grid item xs={12} sm={6} md={6}>
                <LiveVideo event={event} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        {loading ? (
          <>
            <Box>
              <Box className={"skelaton-title-box"}>
                <Skeleton
                  variant="rectangular"
                  className="skelaton-title"
                ></Skeleton>
              </Box>
              {[...Array(5)].map((r) => {
                return (
                  <Box className={"skelaton-market"}>
                    <Box className={"skelaton-title-box"}>
                      <Skeleton
                        variant="rectangular"
                        className="skelaton-title"
                      ></Skeleton>
                    </Box>
                    <Skeleton
                      variant="rectangular"
                      className="skelaton-empty-buttons"
                    ></Skeleton>
                  </Box>
                );
              })}
              <Box className={"skelaton-title-box"}>
                <Skeleton
                  variant="rectangular"
                  className="skelaton-title"
                ></Skeleton>
              </Box>
              {[...Array(5)].map((r) => {
                return (
                  <Box className={"skelaton-market"}>
                    <Box className={"skelaton-title-box"}>
                      <Skeleton
                        variant="rectangular"
                        className="skelaton-title"
                      ></Skeleton>
                    </Box>
                    <Skeleton
                      variant="rectangular"
                      className="skelaton-empty-buttons"
                    ></Skeleton>
                  </Box>
                );
              })}
            </Box>
          </>
        ) : (
          <Box className={"app-content"}>
            {matchMarkets.map((market) => {
              return matchOdds[market.provider_market_id] && isLogginIn ? (
                <Markets
                  isLogginIn={isLogginIn}
                  suspended={suspended}
                  openSlip={openSlip}
                  setOpenBetSlip={setOpenBetSlip}
                  setShowIconInfo={setShowIconInfo}
                  isInplay={isInplay}
                  market={{
                    ...matchOdds[market.provider_market_id],
                    market_id: market.market_id,
                    delay_timer: market.delay_timer,
                  }}
                />
              ) : (
                <Markets
                  isLogginIn={isLogginIn}
                  suspended={suspended}
                  openSlip={openSlip}
                  setOpenBetSlip={setOpenBetSlip}
                  isInplay={isInplay}
                  market={market}
                />
              );
            })}
            {bookmakerMarkets
              .filter((rowI) => {
                if (rowI.is_active) {
                  return true;
                }
                return false;
              })
              .map((market) => {
                return bookmakerOdds[market.provider_market_id] &&
                  isLogginIn ? (
                  <Markets
                    isLogginIn={isLogginIn}
                    suspended={suspended}
                    openSlip={openSlip}
                    setOpenBetSlip={setOpenBetSlip}
                    setShowIconInfo={setShowIconInfo}
                    isBookmaker={true}
                    isInplay={isInplay}
                    market={{
                      ...bookmakerOdds[market.provider_market_id],
                      market_id: market.market_id,
                      delay_timer: market.delay_timer,
                    }}
                  />
                ) : (
                  <Markets
                    isLogginIn={isLogginIn}
                    suspended={suspended}
                    openSlip={openSlip}
                    setOpenBetSlip={setOpenBetSlip}
                    isInplay={isInplay}
                    market={market}
                  />
                );
              })}
            <ExtraMarketTabs
              openSlip={openSlip}
              setOpenBetSlip={setOpenBetSlip}
              setShowIconInfo={setShowIconInfo}
              fancy={fancy}
              fancyMarkets={fancyMarkets}
            />
          </Box>
        )}
      </>
    </EventContext.Provider>
  );
}

const Markets = ({
  openSlip,
  setOpenBetSlip = () => {},
  market,
  isInplay,
  isBookmaker,
  setShowIconInfo,
  suspended,
  isLogginIn,
}) => {
  const [prevExpo, setPrevExpo] = useState();
  const [exposure, setExposure] = useState();
  const { showToast } = useContext(ToastContext);
  useEffect(() => {
    getExposureByMarket();
    // eslint-disable-next-line
  }, [isLogginIn]);

  useEffect(() => {
    if (showToast && showToast.bet) getExposureByMarket();
    // eslint-disable-next-line
  }, [showToast]);

  useEffect(() => {
    if (!(openSlip?.market_id === market.market_id)) setExposure(null);
  }, [market.market_id, openSlip]);

  const calculateExposure = (stake, odds, runner_id, market_id, side) => {
    let profit = 0;
    let temp = {};
    if (market.market_id === market_id) {
      market.runners.forEach((runner) => {
        if (runner.runner_id.toString() === runner_id) {
          if (side === "back") {
            profit =
              calcProfit(odds, stake, market.market_type === "match_odd") || 0;
          } else if (side === "lay") {
            profit =
              -calcProfit(odds, stake, market.market_type === "match_odd") || 0;
          }
        } else {
          if (side === "back") {
            profit = -stake || 0;
          } else if (side === "lay") {
            profit = stake || 0;
          }
        }
        if (prevExpo && prevExpo[runner.runner_id]) {
          profit = profit + prevExpo[runner.runner_id];
        }
        temp[runner.runner_id] = profit;
      });

      setExposure(temp);
    } else {
      setExposure(null);
    }
  };

  const getExposureByMarket = async () => {
    if (market.provider_market_id && isLogginIn) {
      const res = await eventServices.getExposureByMarket(
        market.provider_market_id
      );
      // console.log(res);
      if (res && res.length > 0) {
        const marketExpo = res[0];
        let temp = {};
        marketExpo.runners.forEach((r) => {
          temp[r.runner_id] = r.profit;
        });
        // console.log(temp);
        setPrevExpo(temp);
      } else {
        setPrevExpo(null);
      }
    }
  };

  return (
    <Box className={"market-list-item "}>
      <Box className={"heading "}>
        <Box className={"title"}>
          <Box className={"header"}>
            {isBookmaker
              ? `Match odds (${market.market_name})`
              : market.market_name}
          </Box>
          <Stack
            direction={"row"}
            spacing={0.5}
            className={"app-market-indicators"}
          >
            {isInplay && (
              <CustomIcon
                onClick={() => setShowIconInfo("Going in-play")}
                name={"Inplay"}
              />
            )}
            {isBookmaker && (
              <>
                <CustomIcon
                  onClick={() => setShowIconInfo("Faster bet acceptance")}
                  name={"stopwatch-race"}
                />
                <CustomIcon
                  onClick={() => setShowIconInfo("No commission")}
                  name={"0percent"}
                />
              </>
            )}
          </Stack>
        </Box>
        <Box className={"right-title-box "}>
          <Box className={"markets__action"}>BACK</Box>
          <Box className={"markets__action"}>LAY</Box>
        </Box>
      </Box>
      <Box className={"market-wrap"}>
        <Box className={"list-item"}>
          {market?.runners?.map((runner) => {
            return (
              <BetPanel
                suspended={suspended}
                isInplay={isInplay}
                runner={runner}
                openSlip={openSlip}
                setOpenBetSlip={setOpenBetSlip}
                market={market}
                calculateExposure={calculateExposure}
                exposure={exposure}
                prevExpo={prevExpo}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const BetPanel = ({
  openSlip,
  setOpenBetSlip,
  runner,
  market,
  calculateExposure,
  exposure,
  prevExpo,
  isInplay,
  suspended,
  setExposure = () => {},
}) => {
  const [slipSide, setSlipSide] = useState("back");
  const { state } = useLocation();
  const { isLogginIn } = useSelector((state) => state.user);
  const { setOpenLogin } = useContext(LoginContext);
  const { event } = useContext(EventContext);

  const handleClick = (side, index) => {
    if (isLogginIn) {
      if (
        !(
          market.market_type === "match_odd" &&
          !isInplay &&
          event.provider_game_id !== "4"
        )
      ) {
        setOpenBetSlip({
          runner_id: runner.runner_id,
          index,
          side,
          market_id: market.market_id,
        });
        console.log({
          runner_id: runner.runner_id,
          index,
          side,
          market_id: market.market_id,
        });
        setSlipSide(side);
      }
    } else {
      setOpenLogin(true);
    }
  };

  useEffect(() => {
    // console.log(
    //   openSlip?.runner_id,
    //   runner?.runner_id,
    //   runner?.provider_status
    // );
    if (
      openSlip?.market_id === market?.market_id &&
      openSlip?.runner_id === runner?.runner_id &&
      (runner?.provider_status === RUNNERSTATUS.BALLRUNNING ||
        runner?.provider_status === RUNNERSTATUS.SUSPENDED ||
        market?.provider_status === RUNNERSTATUS.SUSPENDED ||
        market?.provider_status === RUNNERSTATUS.BALLRUNNING)
    ) {
      setOpenBetSlip(null);
      setExposure(null);
    }
  }, [runner?.provider_status, openSlip, market?.provider_status]);

  return (
    <Box className={"bet-panel"}>
      <Box className={"market"}>
        <Box className={"market-title-with-pnl"}>
          <Box className={"runner-details "}>
            <Box className={"runner-name"}>{runner.runner_name}</Box>
            <Box className={"pnl"}>
              {prevExpo && (
                <Box
                  component={"span"}
                  className={`${
                    prevExpo[runner.runner_id] > 0 ? "positive" : ""
                  } ${prevExpo[runner.runner_id] < 0 ? "negative" : ""}`}
                >
                  {`${
                    prevExpo[runner.runner_id] != null
                      ? Math.trunc(prevExpo[runner.runner_id]?.toFixed(2))
                      : ""
                  }`}
                </Box>
              )}
              {exposure && exposure[runner.runner_id] && (
                <Box
                  component={"span"}
                  className={`${
                    exposure[runner.runner_id] > 0 ? "positive" : ""
                  } ${exposure[runner.runner_id] < 0 ? "negative" : ""}`}
                >
                  &nbsp;
                  {`» ${Math.trunc(exposure[runner.runner_id]?.toFixed(2))}`}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className={"market-odds"}>
          <Box className={"odds-wrap"}>
            <Box className={"odds-line"}>
              <Box className={"market-odds__container "}>
                {RUNNERSTATUS.SUSPENDED === runner.provider_status ||
                suspended ||
                market.provider_status === RUNNERSTATUS.SUSPENDED ? (
                  <Box className={"suspended"}>
                    <Box className={"inner"}>SUSPENDED</Box>
                  </Box>
                ) : (
                  (RUNNERSTATUS.BALLRUNNING === runner.provider_status ||
                    market.provider_status === RUNNERSTATUS.BALLRUNNING) && (
                    <Box className={"ball-running-overlay"}>
                      <Box className={"ball-running-overlay-message"}>
                        Ball Running
                      </Box>
                    </Box>
                  )
                )}
                <Box
                  className={`bet-buttons more-odds ${
                    openSlip?.runner_id === runner.runner_id &&
                    openSlip?.side === "back" &&
                    openSlip?.index == 2
                      ? "selected"
                      : "not-selected"
                  }`}
                >
                  <BetButton
                    isFancy={market.market_type === "fancy"}
                    wholeOdds={market.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    line={market.market_type === "fancy" ? 1 : null}
                    event={event}
                    openSlip={openSlip}
                    market={market}
                    runner={runner}
                    calculateExposure={calculateExposure}
                    market_type={market.market_type}
                    customClass={
                      market.market_type === "match_odd" &&
                      !isInplay &&
                      event.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }
                    onClick={() => handleClick("back", 2)}
                    odds={runner.back && runner.back[2]?.price}
                    size={runner.back && runner.back[2]?.size}
                    side={"back"}
                  />
                </Box>
                <Box
                  className={`bet-buttons more-odds ${
                    openSlip?.runner_id === runner.runner_id &&
                    openSlip?.side === "back" &&
                    openSlip?.index == 1
                      ? "selected"
                      : "not-selected"
                  }`}
                >
                  <BetButton
                    isFancy={market.market_type === "fancy"}
                    wholeOdds={market.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    line={market.market_type === "fancy" ? 1 : null}
                    event={event}
                    openSlip={openSlip}
                    market={market}
                    runner={runner}
                    calculateExposure={calculateExposure}
                    market_type={market.market_type}
                    customClass={
                      market.market_type === "match_odd" &&
                      !isInplay &&
                      event.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }
                    onClick={() => handleClick("back", 1)}
                    odds={runner.back && runner.back[1]?.price}
                    size={runner.back && runner.back[1]?.size}
                    side={"back"}
                  />
                </Box>
                <Box
                  className={`bet-buttons ${
                    openSlip?.runner_id === runner.runner_id &&
                    openSlip?.side === "back" &&
                    openSlip?.index == 0
                      ? "selected"
                      : "not-selected"
                  } scrollable`}
                >
                  <BetButton
                    isFancy={market.market_type === "fancy"}
                    wholeOdds={market.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    line={market.market_type === "fancy" ? 1 : null}
                    event={event}
                    openSlip={openSlip}
                    market={market}
                    runner={runner}
                    calculateExposure={calculateExposure}
                    market_type={market.market_type}
                    customClass={
                      market.market_type === "match_odd" &&
                      !isInplay &&
                      event.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }
                    onClick={() => handleClick("back", 0)}
                    odds={runner.back && runner.back[0]?.price}
                    size={runner.back && runner.back[0]?.size}
                    side={"back"}
                  />
                </Box>
                <Box
                  className={`bet-buttons ${
                    openSlip?.runner_id === runner.runner_id &&
                    openSlip?.side === "lay" &&
                    openSlip?.index == 0
                      ? "selected"
                      : "not-selected"
                  } scrollable`}
                >
                  <BetButton
                    isFancy={market.market_type === "fancy"}
                    wholeOdds={market.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    line={market.market_type === "fancy" ? 1 : null}
                    event={event}
                    openSlip={openSlip}
                    market={market}
                    runner={runner}
                    calculateExposure={calculateExposure}
                    market_type={market.market_type}
                    customClass={
                      market.market_type === "match_odd" &&
                      !isInplay &&
                      event.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }
                    onClick={() => handleClick("lay", 0)}
                    odds={runner.lay && runner.lay[0]?.price}
                    size={runner.lay && runner.lay[0]?.size}
                    side={"lay"}
                  />
                </Box>
                <Box
                  className={`bet-buttons more-odds ${
                    openSlip?.runner_id === runner.runner_id &&
                    openSlip?.side === "lay" &&
                    openSlip?.index == 1
                      ? "selected"
                      : "not-selected"
                  }`}
                >
                  <BetButton
                    isFancy={market.market_type === "fancy"}
                    wholeOdds={market.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    line={market.market_type === "fancy" ? 1 : null}
                    event={event}
                    openSlip={openSlip}
                    market={market}
                    runner={runner}
                    calculateExposure={calculateExposure}
                    market_type={market.market_type}
                    customClass={
                      market.market_type === "match_odd" &&
                      !isInplay &&
                      event.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }
                    onClick={() => handleClick("lay", 1)}
                    odds={runner.lay && runner.lay[1]?.price}
                    size={runner.lay && runner.lay[1]?.size}
                    side={"lay"}
                  />
                </Box>
                <Box
                  className={`bet-buttons more-odds ${
                    openSlip?.runner_id === runner.runner_id &&
                    openSlip?.side === "lay" &&
                    openSlip?.index == 2
                      ? "selected"
                      : "not-selected"
                  }`}
                >
                  <BetButton
                    isFancy={market.market_type === "fancy"}
                    wholeOdds={market.market_type === "match_odd"}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    line={market.market_type === "fancy" ? 1 : null}
                    event={event}
                    openSlip={openSlip}
                    market={market}
                    runner={runner}
                    calculateExposure={calculateExposure}
                    market_type={market.market_type}
                    customClass={
                      market.market_type === "match_odd" &&
                      !isInplay &&
                      event.provider_game_id !== "4"
                        ? "disabled"
                        : "odds-2"
                    }
                    onClick={() => handleClick("lay", 2)}
                    odds={runner.lay && runner.lay[2]?.price}
                    size={runner.lay && runner.lay[2]?.size}
                    side={"lay"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {openSlip?.market_id === market?.market_id &&
        openSlip?.runner_id === runner.runner_id &&
        isMobile && (
          <BetSlip
            isFancy={market.market_type === "fancy"}
            wholeOdds={market.market_type === "match_odd"}
            slipSide={slipSide}
            onCancel={() => setOpenBetSlip(null)}
            line={market.market_type === "fancy" ? 1 : null}
            event={event}
            market={market}
            runner={runner}
            calculateExposure={calculateExposure}
            odds={runner[openSlip?.side][openSlip?.index].price}
            oddsChange={market.market_type === "match_odd" ? true : false}
          />
        )}
    </Box>
  );
};

const LiveVideo = ({ event }) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#888",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <iframe src={`https://vid.dreamcasino.live/GetAPI.html?MatchID=${state?.provider_event_id}`} name="iframe_a" title="Iframe Example"></iframe> */}
      <iframe
        style={{
          height: (window.innerWidth * 9) / 16,
          maxWidth: 390,
          maxHeight: (390 * 9) / 16,
        }}
        src={`${API_LINK}${API.STREAM}${event?.provider_event_id}`}
        name="iframe_a"
        title="Iframe Example"
      ></iframe>
    </Box>
  );
};

const MatchInfo = ({ event }) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#888",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <iframe src={`https://vid.dreamcasino.live/GetAPI.html?MatchID=${state?.provider_event_id}`} name="iframe_a" title="Iframe Example"></iframe> */}
      <iframe
        style={{
          height: isMobile ? (window.innerWidth * 251) / 288 : "auto",
          maxHeight: isMobile ? (390 * 251) / 288 : "auto",
          maxWidth: isMobile ? 390 : "100%",
        }}
        // src={`https://server.spacex365.io/api/stream/vScore/32744845`}
        src={`${API_LINK}${API.SCORE}${event?.provider_event_id}`}
        name="iframe_a"
        title="Iframe Example"
      ></iframe>
    </Box>
  );
};

const ExtraMarketTabs = ({
  openSlip,
  setOpenBetSlip = () => {},
  fancy = [],
  fancyMarkets = [],
  setShowIconInfo,
}) => {
  const { event } = useContext(EventContext);
  const { isLogginIn } = useSelector((state) => state.user);
  const [fancyExpo, setFancyExpo] = useState([]);
  const [isFancyAvailable, setFancyAvailable] = useState(
    fancy && fancy.length > 0
  );
  const [selectedTab, setSelectedTab] = useState(
    isFancyAvailable ? "FANCY" : "SPORTBOOK"
  );
  const [iframeLink, setIframeLink] = useState("");
  const { showToast } = useContext(ToastContext);
  const iframeref = useRef();

  useEffect(() => {
    if (fancy && fancy.length > 0) {
      setFancyAvailable(true);
      setSelectedTab("FANCY");
    } else {
      setFancyAvailable(false);
    }
  }, [fancy]);

  useEffect(() => {
    getExposureByMarket();
    // eslint-disable-next-line
  }, [isFancyAvailable]);

  useEffect(() => {
    if (showToast && showToast.bet) getExposureByMarket();
    // eslint-disable-next-line
  }, [showToast]);

  const getExposureByMarket = async () => {
    if (isLogginIn && isFancyAvailable) {
      const res = await eventServices.getFancyLadderByMarket(
        event?.game_event_id
      );
      if (res && res.length > 0) {
        console.log(res, "fancy expo");
        setFancyExpo(res);
        // setPrevExpo(res[0]);
      }
    }
  };

  useEffect(() => {
    // if(selectedTab === "SPORTBOOK" && isLogginIn) sportBookByEvent();
  }, [selectedTab, event]);

  const sportBookByEvent = async () => {
    try {
      const ip = await getIP();
      const data = {
        clientIp: ip,
        eventId: event.provider_event_id,
        sportId: event.provider_game_id,
      };
      const res = await eventServices.getSportbookByEvent(data);
      setIframeLink(res.url);
    } catch (error) {}
  };

  // function resizeIframe() {
  //     console.log(iframeref.current.contentWindow.document.body.scrollHeight,"djhfkjdsfhgjdfg")
  //     // iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
  // }

  return (
    <Box>
      {isFancyAvailable && (
        <Box className={"stroke-group"}>
          <Box className={"nav-tabs-group-container"}>
            <Box
              className={`nav-tab ${selectedTab === "FANCY" ? "active" : ""}`}
            >
              Fancy
            </Box>
            {/* {
                            isLogginIn &&
                            <Box className={`nav-tab ${selectedTab === "SPORTBOOK" ?"active":""}`}>
                                Premium Sportbook
                            </Box>
                        } */}
          </Box>
        </Box>
      )}
      <Box className={"market-list-item "}>
        <Box className={"heading "}>
          <Box className={"title"}>
            <Box className={"header"}></Box>
            <Stack
              direction={"row"}
              spacing={0.5}
              className={"app-market-indicators"}
            ></Stack>
          </Box>
          <Box className={"right-title-box "}>
            <Box className={"markets__action"}></Box>
            <Box className={"markets__action"}></Box>
          </Box>
        </Box>
        {isFancyAvailable &&
          selectedTab === "FANCY" &&
          fancy
            .filter(
              (market) =>
                (market.category === "OVERS" ||
                  market.category === "BATSMAN" ||
                  market.market_type === "fancy" ||
                  market.category === "SINGLE_OVER") &&
                market.yes_value !== 1
            )
            .map((market) => {
              return (
                <Box className={"market-wrap"}>
                  <Box className={"list-item"}>
                    <FancyBetPanel
                      prevExpo={fancyExpo.find(
                        (exp) =>
                          exp.provider_market_id === market.provider_market_id
                      )}
                      fancyMarket={fancyMarkets.find(
                        (f) =>
                          f.provider_market_id === market.provider_market_id
                      )}
                      market={market}
                      openSlip={openSlip}
                      setOpenBetSlip={setOpenBetSlip}
                    />
                  </Box>
                </Box>
              );
            })}
      </Box>
      {/* {
                isFancyAvailable && selectedTab === "FANCY" && fancy.filter((market)=>((market.category !== "OVERS") && (market.category !== "BATSMAN") && (market.category !== "SINGLE_OVER") || market.yes_value === 1)).map((market)=>{
                    return(
                        <FancyMarkets isLogginIn={isLogginIn} openSlip={openSlip} setOpenBetSlip={setOpenBetSlip} setShowIconInfo={setShowIconInfo} market={{...market,market_id:fancyMarkets.find((f)=>f.provider_market_id === market.provider_market_id)?.market_id}} />
                    )
                })
            } */}
      {iframeLink && selectedTab === "SPORTBOOK" && (
        <Box
          component={"iframe"}
          // ref={iframeref}
          width={"100%"}
          height={"460px"}
          src={`${iframeLink}`}
          // onLoad={resizeIframe}
        />
      )}
    </Box>
  );
};

const FancyBetPanel = ({
  openSlip,
  setOpenBetSlip,
  market,
  fancyMarket,
  prevExpo,
}) => {
  const [slipSide, setSlipSide] = useState(0);
  const { event } = useContext(EventContext);
  const [exposure, setExposure] = useState();
  const { isLogginIn } = useSelector((state) => state.user);
  const { setOpenLogin } = useContext(LoginContext);
  const [openLadder, setOpenLadder] = useState(false);

  useEffect(() => {
    if (!(openSlip === market.provider_market_id)) setExposure(null);
  }, [openSlip]);

  useEffect(() => {
    console.log(
      openSlip === market?.provider_market_id &&
        (market?.provider_status === "BALL_RUNNING" ||
          market?.provider_status === "SUSPENDED")
    );
    if (
      openSlip === market?.provider_market_id &&
      (market?.provider_status === "BALL_RUNNING" ||
        market?.provider_status === "SUSPENDED")
    ) {
      setOpenBetSlip(null);
      setExposure(null);
    }
  }, [market?.provider_status, openSlip]);

  const calculateExposure = (stake, odds, runner_id, market_id, side) => {
    let profit = 0;
    console.log(stake, odds, runner_id, market_id, "lkfjdslkf");
    let temp = {};
    if (fancyMarket?.market_id === market_id) {
      if (side === "back") {
        profit = -stake || 0;
      } else if (side === "lay") {
        profit = -calcProfit(odds, stake, false) || 0;
      }
      temp[fancyMarket?.market_id] = profit;
      console.log(temp, "fanct expo");
      setExposure(temp);
    } else {
      setExposure(null);
    }
  };

  const handleClick = (side) => {
    if (isLogginIn) {
      console.log(market.provider_market_id);
      setOpenBetSlip(market.provider_market_id);
      setSlipSide(side);
    } else {
      setOpenLogin(true);
    }
  };

  const handleLadderClick = (e) => {
    console.log("dkfjdklg");
    if (prevExpo) {
      setOpenLadder(true);
    }
  };

  return (
    <Box className={"bet-panel"}>
      <LadderModal
        open={openLadder}
        data={prevExpo}
        onClose={() => setOpenLadder(false)}
      />
      <Box className={"market"}>
        <Box className={"market-title-with-pnl"}>
          <Box className={"runner-details runner-name"} sx={{ pr: "20px" }}>
            {market.market_name}
            {prevExpo && (
              <Box
                component={"span"}
                className={`${prevExpo?.exposure > 0 ? "positive" : ""} ${
                  prevExpo?.exposure < 0 ? "negative" : ""
                }`}
              >
                {`${Math.trunc(prevExpo?.exposure)}`}
              </Box>
            )}
            {exposure && (
              <Box
                component={"span"}
                className={`${
                  exposure[fancyMarket?.market_id] > 0 ? "positive" : ""
                } ${exposure[fancyMarket?.market_id] < 0 ? "negative" : ""}`}
              >
                &nbsp;{`» ${Math.trunc(exposure[fancyMarket?.market_id] || 0)}`}
              </Box>
            )}
            <Box
              className="pointer"
              onClick={handleLadderClick}
              sx={{ position: "absolute", right: "var(--sPad)" }}
            >
              <CustomIcon
                name={"ladder"}
                fontSize={12}
                color={
                  !prevExpo
                    ? "var(--ladder-icon-disabled-color)"
                    : "var(--ladder-icon-color)"
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={"market-odds"}>
          <Box className={"odds-wrap"}>
            <Box className={"odds-line"}>
              <Box className={"market-odds__container "}>
                {RUNNERSTATUS.SUSPENDED === market.provider_status && (
                  <Box className={"suspended"}>
                    <Box className={"inner"}>SUSPENDED</Box>
                  </Box>
                )}
                {RUNNERSTATUS.BALLRUNNING === market.provider_status && (
                  <Box className={"ball-running-overlay"}>
                    <Box className={"ball-running-overlay-message"}>
                      Ball Running
                    </Box>
                  </Box>
                )}
                <Box className={`bet-buttons more-odds`}>
                  <BetButton
                    line={
                      slipSide === "back" ? market.yes_value : market.no_value
                    }
                    isFancy={true}
                    customClass={"odds-2"}
                    wholeOdds={false}
                    oddsChange={false}
                    slipSide={slipSide}
                    onCancel={() => setOpenBetSlip(null)}
                    market={{
                      ...market,
                      market_id: fancyMarket?.market_id,
                      delay_timer: fancyMarket?.delay_timer,
                    }}
                    event={event}
                    calculateExposure={calculateExposure}
                    runner={{
                      runner_id: market.provider_market_id,
                      runner_name: (slipSide === "back"
                        ? market.yes_value
                        : market.no_value
                      ).toString(),
                    }}
                    odds={0}
                    size={0}
                    side={"lay"}
                  />
                </Box>
                <Box className={`bet-buttons more-odds`}>
                  <BetButton
                    line={
                      slipSide === "back" ? market.yes_value : market.no_value
                    }
                    isFancy={true}
                    customClass={"odds-2"}
                    wholeOdds={false}
                    oddsChange={false}
                    slipSide={slipSide}
                    openSlip={openSlip}
                    onCancel={() => setOpenBetSlip(null)}
                    market={{
                      ...market,
                      market_id: fancyMarket?.market_id,
                      delay_timer: fancyMarket?.delay_timer,
                    }}
                    event={event}
                    calculateExposure={calculateExposure}
                    runner={{
                      runner_id: market.provider_market_id,
                      runner_name: (slipSide === "back"
                        ? market.yes_value
                        : market.no_value
                      ).toString(),
                    }}
                    odds={0}
                    size={0}
                    side={"lay"}
                  />
                </Box>
                <Box
                  className={`bet-buttons ${
                    openSlip === market.provider_market_id && slipSide === "lay"
                      ? "selected"
                      : "not-selected"
                  } scrollable`}
                >
                  <BetButton
                    line={
                      slipSide === "back" ? market.yes_value : market.no_value
                    }
                    wholeOdds={false}
                    oddsChange={false}
                    slipSide={slipSide}
                    openSlip={openSlip}
                    onCancel={() => setOpenBetSlip(null)}
                    market={{
                      ...market,
                      market_id: fancyMarket?.market_id,
                      delay_timer: fancyMarket?.delay_timer,
                    }}
                    event={event}
                    calculateExposure={calculateExposure}
                    runner={{
                      runner_id: market.provider_market_id,
                      runner_name: (slipSide === "back"
                        ? market.yes_value
                        : market.no_value
                      ).toString(),
                    }}
                    isFancy={true}
                    customClass={"odds-2"}
                    onClick={() => handleClick("lay")}
                    odds={market.no_value}
                    size={market.no_rate}
                    side={"lay"}
                  />
                </Box>
                <Box
                  className={`bet-buttons ${
                    openSlip === market.provider_market_id &&
                    slipSide === "back"
                      ? "selected"
                      : "not-selected"
                  } scrollable`}
                >
                  <BetButton
                    line={
                      slipSide === "back" ? market.yes_value : market.no_value
                    }
                    wholeOdds={false}
                    oddsChange={false}
                    slipSide={slipSide}
                    openSlip={openSlip}
                    onCancel={() => setOpenBetSlip(null)}
                    market={{
                      ...market,
                      market_id: fancyMarket?.market_id,
                      delay_timer: fancyMarket?.delay_timer,
                    }}
                    event={event}
                    calculateExposure={calculateExposure}
                    runner={{
                      runner_id: market.provider_market_id,
                      runner_name: (slipSide === "back"
                        ? market.yes_value
                        : market.no_value
                      ).toString(),
                    }}
                    isFancy={true}
                    customClass={"odds-2"}
                    onClick={() => handleClick("back")}
                    odds={market.yes_value}
                    size={market.yes_rate}
                    side={"back"}
                  />
                </Box>
                <Box className={`bet-buttons more-odds`}>
                  <BetButton
                    line={
                      slipSide === "back" ? market.yes_value : market.no_value
                    }
                    wholeOdds={false}
                    oddsChange={false}
                    slipSide={slipSide}
                    openSlip={openSlip}
                    onCancel={() => setOpenBetSlip(null)}
                    market={{
                      ...market,
                      market_id: fancyMarket?.market_id,
                      delay_timer: fancyMarket?.delay_timer,
                    }}
                    event={event}
                    calculateExposure={calculateExposure}
                    runner={{
                      runner_id: market.provider_market_id,
                      runner_name: (slipSide === "back"
                        ? market.yes_value
                        : market.no_value
                      ).toString(),
                    }}
                    odds={0}
                    size={0}
                    side={"back"}
                  />
                </Box>
                <Box className={`bet-buttons more-odds`}>
                  <BetButton
                    line={
                      slipSide === "back" ? market.yes_value : market.no_value
                    }
                    wholeOdds={false}
                    oddsChange={false}
                    slipSide={slipSide}
                    openSlip={openSlip}
                    onCancel={() => setOpenBetSlip(null)}
                    market={{
                      ...market,
                      market_id: fancyMarket?.market_id,
                      delay_timer: fancyMarket?.delay_timer,
                    }}
                    event={event}
                    calculateExposure={calculateExposure}
                    runner={{
                      runner_id: market.provider_market_id,
                      runner_name: (slipSide === "back"
                        ? market.yes_value
                        : market.no_value
                      ).toString(),
                    }}
                    odds={0}
                    size={0}
                    side={"back"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {openSlip === market.provider_market_id && isMobile && (
        <BetSlip
          line={slipSide === "back" ? market.yes_value : market.no_value}
          isFancy={true}
          customClass={"odds-2"}
          wholeOdds={false}
          oddsChange={false}
          slipSide={slipSide}
          openSlip={openSlip}
          onCancel={() => setOpenBetSlip(null)}
          market={{
            ...market,
            market_id: fancyMarket?.market_id,
            delay_timer: fancyMarket?.delay_timer,
          }}
          event={event}
          calculateExposure={calculateExposure}
          runner={{
            runner_id: market.provider_market_id,
            runner_name: (slipSide === "back"
              ? market.yes_value
              : market.no_value
            ).toString(),
          }}
          odds={slipSide === "back" ? market.yes_rate : market.no_rate}
        />
      )}
    </Box>
  );
};

const FancyMarkets = ({
  market,
  isInplay,
  setShowIconInfo,
  openSlip,
  setOpenBetSlip,
  isLogginIn,
}) => {
  const [prevExpo, setPrevExpo] = useState();
  const [exposure, setExposure] = useState();
  const { showToast } = useContext(ToastContext);
  const [runners, setRunners] = useState([]);
  useEffect(() => {
    getExposureByMarket();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showToast && showToast.bet) getExposureByMarket();
    // eslint-disable-next-line
  }, [showToast]);

  useEffect(() => {
    if (!openSlip || !(openSlip?.market_id === market.market_id))
      setExposure(null);
  }, [openSlip]);

  useEffect(() => {
    if (market) {
      let temp = [
        {
          runner_name: "Odd",
          back: [{ price: market.yes_rate }],
          lay: [{ price: null }],
          runner_id: `${market.provider_market_id}_odd`,
          provider_status: market.provider_status,
        },
        {
          runner_name: "Even",
          back: [{ price: market.no_rate }],
          lay: [{ price: null }],
          runner_id: `${market.provider_market_id}_even`,
          provider_status: market.provider_status,
        },
      ];
      setRunners(temp);
    }
  }, [market]);

  const calculateExposure = (stake, odds, runner_id, market_id, side) => {
    let profit = 0;
    console.log(stake, odds, runner_id, market_id, "lkfjdslkf");
    let temp = {};
    if (market.market_id === market_id) {
      runners.forEach((runner) => {
        if (runner.runner_id.toString() === runner_id) {
          if (side === "back") {
            profit =
              calcProfit(odds, stake, market.market_type === "match_odd") || 0;
          } else if (side === "lay") {
            profit =
              -calcProfit(odds, stake, market.market_type === "match_odd") || 0;
          }
        } else {
          if (side === "back") {
            profit = -stake || 0;
          } else if (side === "lay") {
            profit = stake || 0;
          }
        }
        if (prevExpo && prevExpo[runner.runner_id]) {
          profit = profit + prevExpo[runner.runner_id];
        }
        temp[runner.runner_id] = profit;
      });

      setExposure(temp);
    } else {
      setExposure(null);
    }
  };

  const getExposureByMarket = async () => {
    if (market.provider_market_id && isLogginIn) {
      const res = await eventServices.getExposureByMarket(
        market.provider_market_id
      );
      // console.log(res);
      if (res && res.length > 0) {
        const marketExpo = res[0];
        let temp = {};
        marketExpo.runners.forEach((r) => {
          temp[r.runner_id] = r.profit;
        });
        // console.log(temp);
        setPrevExpo(temp);
      } else {
        setPrevExpo(null);
      }
    }
  };

  return (
    <Box className={"market-list-item "}>
      <Box className={"heading "}>
        <Box className={"title"}>
          <Box className={"header"}>{market.market_name}</Box>
          <Stack
            direction={"row"}
            spacing={0.5}
            className={"app-market-indicators"}
          >
            {isInplay && <CustomIcon name={"Inplay"} />}
            <CustomIcon
              onClick={() => setShowIconInfo("Faster bet acceptance")}
              name={"stopwatch-race"}
            />
            <CustomIcon
              onClick={() => setShowIconInfo("No commission")}
              name={"0percent"}
            />
          </Stack>
        </Box>
        <Box className={"right-title-box "}>
          <Box className={"markets__action"}>BACK</Box>
          <Box className={"markets__action"}>LAY</Box>
        </Box>
      </Box>
      <Box className={"market-wrap"}>
        <Box className={"list-item"}>
          {runners?.map((runner) => {
            return (
              <BetPanel
                isInplay={isInplay}
                runner={runner}
                openSlip={openSlip}
                setOpenBetSlip={setOpenBetSlip}
                market={market}
                calculateExposure={calculateExposure}
                exposure={exposure}
                prevExpo={prevExpo}
                setExposure={setExposure}
              />
            );
          })}
          {/* {
                        market?.runners?.map((runner)=>{
                            return(
                                <BetPanel runner={runner} openSlip={openSlip} setOpenBetSlip={setOpenBetSlip} market={market} calculateExposure={calculateExposure} exposure={exposure} prevExpo={prevExpo} />
                            )
                        })
                    } */}
        </Box>
      </Box>
    </Box>
  );
};
