import { Box, Button, Dialog } from "@mui/material";
import CustomIcon from "../customIcon";
import { useState } from "react";
import authServices from "../../services/authServices";
import { useDispatch } from "react-redux";
import { login, updateUserID } from "../../redux/reducers/user";
import { useContext } from "react";
import { ToastContext } from "../../context/context";
import {
  changeActiveOneClickStake,
  changeOneClickStake,
  changeStake,
} from "../../redux/reducers/stakes";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { getIP } from "../../utils/functions";

export default function LoginModal({ open, onClose = () => {} }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();
  const { setShowToast } = useContext(ToastContext);

  const onLogin = async () => {
    const ip = await getIP();
    const data = {
      user_name: username,
      password,
      ip_address: ip,
    };
    const res = await authServices.login(data);
    if (res && !res.response_error && res.data) {
      if (res.data.is_two_factor_authentication) {
        dispatch(updateUserID(res.data.user_id));
        handleClose();
      } else {
        sessionStorage.setItem("token", res.token);
        dispatch(login(res.data));
        console.log("isMobile..........", isMobile);
        if (isMobile) {
          console.log("isMobile..........", res.data.mobile_stake);
          dispatch(changeStake(res.data.mobile_stake));
        } else {
          dispatch(changeStake(res.data.stake));
        }
        let oneTemp = [...res.data.one_click_stake];
        oneTemp.length = 3;
        dispatch(changeOneClickStake(oneTemp));
        dispatch(changeActiveOneClickStake(oneTemp[0]));
        handleClose();
      }
    } else {
      setShowToast({
        error: true,
        message: "Login failed. Please try again",
      });
    }
  };

  const handleClose = () => {
    onClose();
    setPassword("");
    setUsername("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: "10px",
          maxWidth: "none",
        },
      }}
    >
      <Box className={"login-page"}>
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <CustomIcon name={"modal-close"} color={"white"} fontSize={14} />
        </Box>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Box className={"login-form"}>
            <Box className={"logo"} />
            <Box className={"control-wrapper"}>
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value.trim())}
                className="text-input"
                placeholder="Username"
              />
            </Box>
            <Box className={"control-wrapper"}>
              <input
                value={password}
                className="text-input"
                onChange={(e) => setPassword(e.target.value.trim())}
                placeholder="Password"
                type={`${showPass ? "text" : "password"}`}
              />
              <Box
                className={"show-password"}
                onClick={() => setShowPass(!showPass)}
                sx={{
                  padding: "1px 6px",
                  "&:before": {
                    content: showPass ? '"\\e963"' : '"\\e962"',
                    fontFamily: "icomoon",
                  },
                }}
              ></Box>
            </Box>
            <Box className={"control-wrapper"}>
              <Button
                type="submit"
                disabled={!username || !password}
                sx={{ width: "100%" }}
                onClick={() => onLogin()}
                className="btn-login button"
              >
                Login
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}
