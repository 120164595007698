import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EVENTSTATUS } from '../../constants/constants';
import { formatUnixTimestamp } from '../../utils/functions';
import CustomIcon from '../customIcon';

export default function EventName({ sportId, event, sportName }) {
  const navigate = useNavigate();
  return (
    <Box
      className={'market-title-with-scores'}
      onClick={() =>
        navigate(
          `/sport/${event.provider_game_id}/game-details/${
            event ? event.provider_event_id : 37373737
          }/${event?.league_id}`,
          { state: { ...event, game_id: sportId } }
        )
      }
    >
      <Box className={'info-box'} sx={{ borderRight: '1px solid #f2f2f2' }}>
        <Box className={'info'}>
          <Box width={10}></Box>
          {event?.provider_status === EVENTSTATUS.INPLAY ? (
            <Box className={'label'}>In Play</Box>
          ) : (
            <Box className={'line date'}>
              <Box component={'span'}>
                {' '}
                {formatUnixTimestamp(event?.event_time)}{' '}
              </Box>
            </Box>
          )}
          {/* <Box lineHeight={1} className={"info cricket"}>
                        <Box className={"line"}>4 Ov</Box>
                        <Box className={"line"}>8/1</Box>
                        <Box className={"line"}>Tgt 127</Box>
                    </Box> */}
        </Box>
      </Box>
      <Box className={'competitors'}>
        <Box className={'width-wrapper'}>
          {event.runners.length > 3 ? (
            <Box className={'line'}>
              <Box className={'name'}>
                {event && event.event_name ? event.event_name : 'Afghanistan'}
              </Box>
            </Box>
          ) : (
            <Box>
              <Box className={'line'}>
                <Box className={'name'}>
                  {event && event.runners
                    ? event.runners[0]?.runner_name
                    : 'Bangladesh'}
                </Box>
              </Box>
              <Box className={'line'}>
                <Box className={'name'}>
                  {event && event.runners
                    ? event.runners[1]?.runner_name
                    : 'Afghanistan'}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 1,
            right: 1,
          }}
        >
          {event?.is_stream_available && event?.is_live_stream && (
            <CustomIcon name={'video-tv'} fontSize={8} color={'#257b23'} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
