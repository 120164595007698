import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bets: [],
};

const betsSlice = createSlice({
  name: "bets",
  initialState,
  reducers: {
    addBet: (state, action) => {
      state.bets=[];
      state.bets.push(action.payload);
      console.log(state.bets)
    //   state.bets.push(action.payload);
    },
    removeBet: (state, action) => {
      state.bets = state.bets.filter((bet) => bet.betId !== action.payload);
    },
    updateAmount: (state, action) => {
      const bet = state.bets.find((bet) => bet.betId === action.payload.betId);
      if (bet) {
        bet.bet_amount = action.payload.amount;
      }
    },
    updateOdds: (state, action) => {
      const bet = state.bets.find((bet) => bet.betId === action.payload.betId);
      if (bet) {
        bet.odds = action.payload.odds;
      }
    },
    clearBets: (state) => {
      state.bets = [];
    },
  },
});

export const { addBet, clearBets, removeBet, updateAmount, updateOdds } = betsSlice.actions;

export default betsSlice.reducer;
