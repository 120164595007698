import { API } from "../constants/constants";
import { handleCatch, handleResponse } from "../utils/functions";
import { server } from "../utils/server";

const getSportLink = () => {
  return server
    .get(`${API.SPORTBOOKSLOTGETOR}`)
    .then((res) => handleResponse(res.data, false))
    .catch(handleCatch);
};

const sportBook = {
  getSportLink,
};

export default sportBook;