import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTimeZone } from "../../redux/reducers/user";

export default function TimeSettings(){
    const [timer,setTimer] = useState(moment().format("HH:mm:ss"));
    const {settings} = useSelector(state=>state.user);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        if(event.target.value === "0"){
            dispatch(changeTimeZone({value:"+00:00",id:"0"}))
        }
        else if(event.target.value === "1" || event.target.value === "2"){
            dispatch(changeTimeZone({value:"+05:30",id:event.target.value}))
        }
    };

    
    useEffect(()=>{
        setTimeout(()=>{
            setTimer(moment().utcOffset(settings?.utc.value).format("HH:mm:ss"))
        },1000)
    },[timer,settings?.utc])
    return(
        <>
            <Box padding={1}>
                <Box className={"page-title"}>Time Setting</Box>
                <Box className={"page-content-box"}>
                    <Stack direction={"row"} alignItems={"center"} className={"clock"} sx={{display:'flex'}}>
                        <Box className={"date"}>{moment().format("LL")}</Box>
                        <Box className={"time"}>{timer}</Box>
                    </Stack>
                    <Box className={"options"}>
                        <Box className={"app-list"}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    onChange={handleChange}
                                    value={settings?.utc.id}
                                >
                                    <FormControlLabel labelPlacement="start" value="0" control={<Radio size="small" />} label="System time - (GMT +00.00)" />
                                    <FormControlLabel labelPlacement="start" value="1" control={<Radio size="small"/>} label="Your Device time - (GMT +05.30)" />
                                    <FormControlLabel labelPlacement="start" value="2" control={<Radio size="small"/>} label="Indian Standard Time - (GMT +05.30)" />
                                </RadioGroup>
                                </FormControl>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}