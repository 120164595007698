import { Box, Button, Dialog, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import CustomIcon from "../customIcon";
import {
  changeTwoFactor,
  login,
  updateUserID,
} from "../../redux/reducers/user";
import { useState } from "react";
import {
  blockInvalidCharWithDecmial,
  floatWith2DecimalCheck,
} from "../../utils/functions";
import authServices from "../../services/authServices";
import {
  changeActiveOneClickStake,
  changeOneClickStake,
  changeStake,
} from "../../redux/reducers/stakes";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function Modal2Fact({ open, onCancel = () => {}, user_id }) {
  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(false);
  const onReject = () => {
    onCancel();
    setVerificationCode("");
    dispatch(updateUserID(null));
  };

  const handleCodeChanges = (event) => {
    let value = event.target.value.split(" ").join("");
    const newValue = floatWith2DecimalCheck(value);
    if (newValue !== null) {
      if (newValue.toString().length < 7) {
        if (newValue.toString().length >= 4) {
          const formated = newValue.toString().split("");
          formated.splice(3, 0, " ");
          setVerificationCode(formated.join(""));
        } else setVerificationCode(newValue);
      }
    }
  };

  const onLogin = async () => {
    const data = {
      token: verificationCode.split(" ").join(""),
      user_id,
    };
    const res = await authServices.loginTwoAuth(data);
    if (res && !res.response_error && res.data) {
      sessionStorage.setItem("token", res.token);
      dispatch(login(res.data));
      dispatch(changeTwoFactor());
      if (isMobile) {
        dispatch(changeStake(res.data.mobile_stake));
      } else {
        dispatch(changeStake(res.data.stake));
      }
      let oneTemp = [...res.data.one_click_stake];
      oneTemp.length = 3;
      dispatch(changeOneClickStake(oneTemp));
      dispatch(changeActiveOneClickStake(oneTemp[0]));
      onCancel();
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: "10px",
          maxWidth: "600px",
          width: "90%",
        },
      }}
    >
      <Box className={`modal-container`}>
        <Box
          onClick={onReject}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <CustomIcon name={"modal-close"} fontSize={14} />
        </Box>
        <Box className={"modal-component-wrap"} sx={{ textAlign: "center" }}>
          <h3>Security verification</h3>
          <p>Enter the 6-digit code from your authenticator app </p>
          <Box>
            <input
              value={verificationCode}
              onChange={handleCodeChanges}
              style={{ outline: "none" }}
              placeholder="Verification code"
              apptrackclick=""
              onKeyDown={blockInvalidCharWithDecmial}
              class="verification-code-input"
            />
          </Box>
          <Button
            onClick={onLogin}
            disabled={verificationCode.length === 0}
            sx={{ fontWeight: 400, minWidth: "100%" }}
            className="button -primary"
          >
            Verify
          </Button>
          {error && <div class="error-message">Incorrect two factor code</div>}
          {/* <Box
                        sx={{
                            padding: 'var(--mPad)'
                        }}
                    >
                        <Box component={"p"} sx={{fontSize:14,textAlign:"center",my:"14px"}}>Are you over 18?</Box>
                        <Stack direction={"row"} pt={"var(--mPad)"}>
                            <Button onClick={onReject} sx={{fontWeight:400,minWidth:"auto"}} className="button -reject">No</Button>
                        </Stack>
                    </Box> */}
        </Box>
      </Box>
    </Dialog>
  );
}
