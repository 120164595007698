import { Box, Button, Dialog, Stack, } from "@mui/material";

export default function CoinMsg({open,onClose=()=>{}}) {
    return(
        <Dialog open={open}
        // onClose={onClose}
            PaperProps={{
                sx:{
                    margin:0,
                    borderRadius:"10px",
                    width:"75%",
                    maxWidth:"90%",
                }
            }}
        >
            <Box className={`modal-container notification-modal `}>
                <Box className={"modal-component-wrap"}>
                    <Box className={"modal-content"} position={"relative"}>
                        <Stack alignItems={"center"} spacing={1}>
                            <Box component={"h4"}>Please Note</Box>
                            <Box component={'img'} src={`${process.env.PUBLIC_URL}/assets/images/coin.png`} sx={{width:80}} />
                            <Box component={'p'}>100 Points = 1 Casino Point</Box>
                            <Button onClick={onClose} variant="contained">Okay</Button>
                        </Stack>
                    </Box> 
                </Box>
            </Box>
        </Dialog>
    )
}