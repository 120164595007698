import { alpha } from "@mui/material"


const PRIMARY = {
    lighter:"#D6F4EF",
    light:"#77BDBE",
    main:"#0F2327",
    dark:"#07151C",
    darker:"#020B12",
}

const SECONDARY = {
    lighter:"#DDF6F4",
    light:"#8FC9CC",
    main:"#2c4f58",
    dark:"#16303F",
    darker:"#08192A",
}

const TRINARY = {
    lighter:"#D6F6F1",
    light:"#7DCCCB",
    main:"#1F5058",
    dark:"#0F313F",
    darker:"#05192A",
}

const SUCCESS = {
    lighter:"#DFFAD5",
    light:"#88E27E",
    main:"#2AA033",
    dark:"#15732D",
    darker:"#084C26",
}

const ERROR = {
    lighter:"#FBE5D7",
    light:"#EB9D85",
    main:"#BF3E35",
    dark:"#891A25",
    darker:"#5B0A1F",
}

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
  };

const palette = {
    common : {black:"#000",white:"#fff"},
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    trinary:{ ...TRINARY },
    success:{ ...SUCCESS },
    error: { ...ERROR },
    grey: GREY,
    divider: GREY[500_24],
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
    action: {
        active: GREY[600],
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
}

export default palette;