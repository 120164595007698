import {
  Box,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CustomIcon from '../customIcon';
import { useEffect, useState } from 'react';
import authServices from '../../services/authServices';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../../redux/reducers/user';
import userSocket from '../../services/userSocket';
import { useNavigate } from 'react-router-dom';
import { clearStake } from '../../redux/reducers/stakes';
import { userDisconnected } from '../../redux/reducers/socket';

export default function ChangePassword({ open, onCancel = () => {} }) {
  const [newPassword, setNewPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState({
    min: false,
    upperLower: false,
    number: false,
    repeat: false,
  });

  useEffect(() => {
    validate();
  }, [newPassword, repeatPassword]);

  const validate = () => {
    if (newPassword) {
      if (newPassword.length >= 8 && newPassword.length <= 255)
        setError((prev) => ({ ...prev, min: true }));
      else setError((prev) => ({ ...prev, min: false }));
      if (newPassword.match(/[a-z]/) && newPassword.match(/[A-Z]/))
        setError((prev) => ({ ...prev, upperLower: true }));
      else setError((prev) => ({ ...prev, upperLower: false }));
      if (newPassword.match(/[0-9]/))
        setError((prev) => ({ ...prev, number: true }));
      else setError((prev) => ({ ...prev, number: false }));
      if (repeatPassword && newPassword === repeatPassword)
        setError((prev) => ({ ...prev, repeat: true }));
      else setError((prev) => ({ ...prev, repeat: false }));
    }
  };

  const updatePassword = async () => {
    const data = {
      password: newPassword,
    };

    const res = await authServices.updateDeafultPassword(user.user_id, data);
    if (res) {
      onCancel();
      dispatch(clearUser());
      dispatch(clearStake());
      dispatch(userDisconnected());
      userSocket.disconnect();
      navigate('', { replace: true });
      window.location.reload();
      setNewPassword('');
      setRepeatPassword('');
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: '5px',
          maxWidth: '600px',
          width: '90%',
        },
      }}
    >
      <Box className={`modal-container change-password`}>
        <Box
          className={'modal-component-wrap'}
          sx={{ padding: 'var(--xlPad) var(--mPad)' }}
        >
          <Typography
            variant="h1"
            className="title"
            sx={{ margin: '0 0 24px', textAlign: 'center' }}
          >
            {' '}
            Your password needs to be changed{' '}
          </Typography>
          <Box>
            <TextField
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              sx={{ marginBottom: '10px ' }}
              InputProps={{ className: 'small' }}
              placeholder="New Password"
            ></TextField>
            <TextField
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              type="password"
              sx={{ marginBottom: '10px ' }}
              InputProps={{ className: 'small' }}
              placeholder="Repeat Password"
            ></TextField>
            <Box className={'password-change-requirements'}>
              <Box className={'sub-title'}>Password Should:</Box>
              <Stack direction={'row'} spacing={1} className={'requirement'}>
                <CustomIcon
                  name="x"
                  color={error.min ? '#149909' : '#f53d3d'}
                  fontSize={'14px'}
                />
                <Box className={`message ${error.min ? '' : '-invalid'}`}>
                  {' '}
                  Be from 8 up to 255 characters.{' '}
                </Box>
              </Stack>
              <Stack direction={'row'} spacing={1} className={'requirement'}>
                <CustomIcon
                  name="x"
                  color={error.upperLower ? '#149909' : '#f53d3d'}
                  fontSize={'14px'}
                />
                <Box
                  className={`message ${error.upperLower ? '' : '-invalid'}`}
                >
                  {' '}
                  Have at least one upper case and lower case letter.{' '}
                </Box>
              </Stack>
              <Stack direction={'row'} spacing={1} className={'requirement'}>
                <CustomIcon
                  name="x"
                  color={error.number ? '#149909' : '#f53d3d'}
                  fontSize={'14px'}
                />
                <Box className={`message ${error.number ? '' : '-invalid'}`}>
                  {' '}
                  Have at least one number.{' '}
                </Box>
              </Stack>
              <Stack direction={'row'} spacing={1} className={'requirement'}>
                <CustomIcon
                  name="x"
                  color={error.repeat ? '#149909' : '#f53d3d'}
                  fontSize={'14px'}
                />
                <Box className={`message ${error.repeat ? '' : '-invalid'}`}>
                  {' '}
                  Match repeated password.{' '}
                </Box>
              </Stack>
            </Box>
            <Button
              className="button -primary"
              sx={{ width: '100%', fontWeight: '400', mt: '10px' }}
              onClick={updatePassword}
              disabled={
                !error.min ||
                !error.number ||
                !error.upperLower ||
                !error.repeat
              }
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
