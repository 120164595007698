

export const sports = [
    {
        name:"Cricket",
        id:"4",
        bgcolor:"#8C1000",
        hoverColor:"#780e00"
    },
    {
        name:"Football",
        id:"1",
        bgcolor:"#111111",
        hoverColor:"#070707"
    },
    {
        name:"Tennis",
        id:"2",
        bgcolor:"#75A93C",
        hoverColor:"#6b9a37"
    },
    {
        name:"Horse Racing",
        id:"7",
        bgcolor:"#854D46",
        hoverColor:"#78453f"
    },
    {
        name:"Greyhound Racing",
        id:"4339",
        bgcolor:"#215867",
        hoverColor:"#1c4b58"
    },
    {
        name:"E-Sports",
        id:"8",
        bgcolor:"#71388C",
        hoverColor:"#65327d"
    },
    {
        name:"Live Casino",
        id:"10",
        bgcolor: '#0F2327',
        hoverColor: '#1F5158'
    },
]

export const raceData = [
    {
        raceName :"Race A",
        raceTime :"18:50",
        raceId:1100,
    },
    {
        raceName :"Race b",
        raceTime :"18:55",
        raceId:1200,
    },
    {
        raceName :"Race c",
        raceTime :"19:15",
        raceId:1300,
    },
    {
        raceName :"Race D",
        raceTime :"19:40",
        raceId:1400,
    },
    {
        raceName :"Race E",
        raceTime :"20:20",
        raceId:1500,
    },
    {
        raceName :"Race F",
        raceTime :"20:50",
        raceId:1600,
    },
]

export const eventData = [
    {
        eventName:"Team A v Team B",
        inplay:true,
        eventTime:"11/07/23 17:30",
        sportId:4,
        eventId:1,
        compId:1000,
        markets:[
            {
                mtype:"MATCHODDS",
                marketName:"Match Odds",
                suspended:false,
                more:false,
                ballRunning:false,
                marketId:123,
                runners:[
                    {
                       name:"Netherlands Women",
                       back:{
                        odds:1.62,
                        size:44186,
                       },
                       lay:{
                        odds:1.23,
                        size:44186,
                       }
                    },
                    {
                       name:"Scotland Women",
                       back:{
                        odds:4.52,
                        size:44186,
                       },
                       lay:{
                        odds:4.87,
                        size:44186,
                       }
                    },
                ]
            },
            {
                mtype:"MATCHODDS",
                marketName:"Match Odds (Bookmaker)",
                suspended:false,
                more:false,
                ballRunning:false,
                marketId:456,
                runners:[
                    {
                       name:"A",
                       back:{
                        odds:1.62,
                        size:44186,
                       },
                       lay:{
                        odds:1.23,
                        size:44186,
                       }
                    },
                    {
                       name:"B",
                       back:{
                        odds:4.52,
                        size:44186,
                       },
                       lay:{
                        odds:4.87,
                        size:44186,
                       }
                    },
                ]
            },
        ]
    },
    {
        eventName:"Team C v Team D",
        inplay:true,
        eventTime:"11/07/23 17:30",
        sportId:4,
        eventId:2,
        compId:2000,
        markets:[
            {
                mtype:"MATCHODDS",
                suspended:false,
                more:false,
                ballRunning:true,
                marketId:656,
                runners:[
                    {
                       name:"C",
                       back:{
                        odds:1.62,
                        size:44186,
                       },
                       lay:{
                        odds:1.23,
                        size:44186,
                       }
                    },
                    {
                       name:"D",
                       back:{
                        odds:4.52,
                        size:44186,
                       },
                       lay:{
                        odds:4.87,
                        size:44186,
                       }
                    },
                ]
            }
        ]
    },
    {
        eventName:"Team E v Team F",
        inplay:false,
        eventTime:"11/07/23 17:30",
        sportId:4,
        eventId:3,
        compId:3000,
        markets:[
            {
                mtype:"MATCHODDS",
                suspended:true,
                more:false,
                ballRunning:false,
                runners:[
                    {
                       name:"E",
                       back:{
                        odds:1.62,
                        size:44186,
                       },
                       lay:{
                        odds:1.23,
                        size:44186,
                       }
                    },
                    {
                       name:"F",
                       back:{
                        odds:4.52,
                        size:44186,
                       },
                       lay:{
                        odds:4.87,
                        size:44186,
                       }
                    },
                ]
            }
        ]
    },
    {
        eventName:"Team G v Team H",
        inplay:false,
        eventTime:"11/07/23 17:30",
        sportId:4,
        eventId:4,
        compId:1000,
        markets:[
            {
                mtype:"MATCHODDS",
                suspended:false,
                more:true,
                ballRunning:false,
                runners:[
                    {
                       name:"G",
                       back:{
                        odds:1.62,
                        size:44186,
                       },
                       lay:{
                        odds:1.23,
                        size:44186,
                       }
                    },
                    {
                       name:"H",
                       back:{
                        odds:4.52,
                        size:44186,
                       },
                       lay:{
                        odds:4.87,
                        size:44186,
                       }
                    },
                ]
            }
        ]
    }
]

export const compitionData = [
    {
        name:"Comp A",
        id:1000,
        sportId:4,
        events:[
            {
                eventId:1,
                name:"Team A v Team B"
            },
            {
                eventId:4,
                name:"Team G v Team H"
            }
        ]
    },
    {
        name:"Comp B",
        id:2000,
        sportId:4,
        events:[
            {
                eventId:2,
                name:"Team C v Team D"
            },
        ]
    },
    {
        name:"Comp C",
        id:3000,
        sportId:4,
        events:[
            {
                eventId:3,
                name:"Team E v Team F"
            },
        ]
    }
]