

export default function Button(theme)
{
    return {
        MuiButton: {
            styleOverrides: {
              root: {
                  borderRadius:2,
                  padding:"0 16px",
                  fontSize:13,
                  width:"fit-content",
                  boxShadow:"none",
                  height:36,
                  background:'var(--secondary)',
                  "&:hover":{
                    boxShadow:"none"
                  },
                  "&.small": {
                    minHeight: '21px',
                    padding: '0 10px',
                    lineHeight: '21px',
                    fontSize: '10px',
                    minWidth: "auto",
                  },
              },
              sizeSmall: {
                  width:"fit-content",
                  padding:"4px 12px 4px 12px",
              },
              outlined:{
                background:'var(--secondary)'
              }
            }
          }
    }
}