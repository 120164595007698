// Router.js
import React from "react";
import { useRoutes } from "react-router-dom";
import { mobileRoutes, desktopRoutes } from "./routesConfig";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import userSocket from "./services/userSocket";
import { useEffect } from "react";
import { eventConnected, eventDisconnected, oddsConnected, oddsDisconnected, userConnected, userDisconnected } from "./redux/reducers/socket";
import { updateBalanceDetails } from "./redux/reducers/user";
import eventSocket from "./services/eventSocket";
import sportSocket from "./services/sportSocket";
import socketServices from "./services/socketServices";
import authServices from "./services/authServices";
import fancySocketServices from "./services/fancySocketServices";


export default function Router() {
    const { isLogginIn } = useSelector(state=>state.user);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(isLogginIn){
            getBalanceFromApi();
            dispatch(userDisconnected());
            dispatch(oddsDisconnected());
            dispatch(eventDisconnected())
            socketServices.connect(handleOddsConnection);
            fancySocketServices.connect(()=>{});
            // console.log(user.user_id)
           
            // userSocket.chekUserConnected(handleConnection)
            // userSocket.getBalanceData(setBalanceData);
            // eventSocket.connect(handleEventConnection);
            // sportSocket.connect(()=>{});
        }
    },[isLogginIn])

    const getBalanceFromApi = async()=>{
        try {
            const res = await authServices.getBalance();
            if(res){
                setBalanceData(res);
            }
        } catch (error) {
            
        }
    }

    const handleConnection = (connected) =>{
        if(connected){
            // console.log("sfkldjflikdjslik")
            userSocket.joinUser();
            dispatch(userConnected())
        }
        else{
            dispatch(userDisconnected());
        }
    }
    const handleOddsConnection = (connected) =>{
        if(connected){
            dispatch(oddsConnected())
            // setTimeout(()=>{
                sportSocket.connect(()=>{});
                eventSocket.connect(handleEventConnection);
                userSocket.connect(handleConnection);
                userSocket.getBalanceData((data)=> dispatch(updateBalanceDetails(data)));
                
            // },2000)
        }
        else{
            dispatch(oddsDisconnected());
        }
    }

    const handleEventConnection = (connected) =>{
        if(connected){
            dispatch(eventConnected())
        }
        else{
            dispatch(eventDisconnected());
        }
    }
    
    const setBalanceData = (data) =>{
        dispatch(updateBalanceDetails(data))
    }

    const routes = isMobile ? mobileRoutes : desktopRoutes;
    const element = useRoutes(routes);
    return element;
}
