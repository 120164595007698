import { Box, Button, Dialog, Stack } from '@mui/material';

export default function LadderModal({ open, data, onClose = () => {} }) {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: '10px',
          maxWidth: '600px',
          width: '90%',
        },
      }}
    >
      <Box className={`modal-container ladder-modal `}>
        <Box className={'modal-component-wrap'}>
          <Box className={'modal-header '}>{data?.market_name} - Ladder</Box>
          <Box className={'modal-content'}>
            <Box className={'ladder-rows'}>
              <Stack direction={'row'} className="row header">
                <Box className={'col'}>Runs</Box>
                <Box className={'col'}>P&L</Box>
              </Stack>
              <Box className={'ladder-content'}>
                {data &&
                  data.runners &&
                  data.runners.map((runner) => {
                    return (
                      <Stack
                        direction={'row'}
                        className="row"
                        justifyContent={'center'}
                      >
                        <Box className={'col'}>{runner.runner_name}</Box>
                        <Box
                          className={`col ${
                            runner.profit >= 0 ? 'positive' : 'negative'
                          }`}
                        >
                          {runner.profit}
                        </Box>
                      </Stack>
                    );
                  })}
              </Box>
            </Box>
          </Box>
          <Box className={'modal-actions'}>
            <Button
              onClick={onClose}
              sx={{ width: '100%' }}
              className="button -outline"
            >
              OK
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
