

export default function Drawer(theme)
{
    return {
        MuiModal: {
            styleOverrides: {
              backdrop: {
                  backgroundColor:"transparent"
              },
            }
          }
    }
}