import {
  Box,
  Button,
  useMediaQuery,
  Divider,
  Drawer,
  Stack,
  Typography,
  Menu,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment/moment";
import { LeftSideBarTabs, RightSideBarTabs } from "../utils/sidebar";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Iconify from "./Iconify";
import CustomIcon from "./customIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeTimeZone } from "../redux/reducers/user";
import { useContext, useEffect, useState } from "react";
import { LoginContext, ToastContext } from "../context/context";
import { clearUser } from "../redux/reducers/user";
import betServices from "../services/betServices";
import eventServices from "../services/eventServices";
import authServices from "../services/authServices";
import userSocket from "../services/userSocket";
import SportMenu from "../components/sportMenu";
import { changeOneClickStatus, clearStake } from "../redux/reducers/stakes";
import accountServices from "../services/accountServices";
import { userDisconnected } from "../redux/reducers/socket";
import { getLogoLink } from "../utils/functions";
import LinkText from "./miniComponents/linkText";

export default function Header({
  openLeftNav,
  setOpenLeftNav,
  openRightNav,
  setOpenRightNav,
}) {
  const { isLogginIn, balanceData } = useSelector((state) => state.user);
  const { user_socket } = useSelector((state) => state.sockets);
  const [showSearch, setShowSearch] = useState(false);
  // const [balanceData,setBalanceData] = useState();
  const { setOpenLogin } = useContext(LoginContext);
  const { setShowToast } = useContext(ToastContext);
  const [logoLink, setlogoLink] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLargeScreen = useMediaQuery("(min-width:767px)");
  const showMenu = [
    {
      path: "/sport",
      showMenu: true,
    },
    {
      path: "/racing",
      showMenu: true,
    },
    {
      path: "/my-markets",
      showMenu: true,
    },
  ];

  const handleLogout = () => {
    dispatch(clearUser());
    dispatch(clearStake());
    dispatch(userDisconnected());
    userSocket.disconnect();
    navigate("", { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    if (user_socket.isConnected) {
      // userSocket.connect();
      // userSocket.getBalanceData(setBalanceData);
      userSocket.logoutUser(handleLogout);
      userSocket.userError(handleLogout);
      userSocket.getExposureAndOpenBets(handleBetsChanges);
      // userSocket.joinUser();
      // setTimeout(()=>{
      //     // getBalance();
      // },2000)
    }
  }, [user_socket.isConnected]);

  const handleBetsChanges = () => {
    setShowToast({
      error: false,
      message: "",
      bet: true,
    });
  };

  useEffect(() => {
    // console.log(pathname);
  }, [pathname]);
  useEffect(() => {
    getLogo();
  }, []);

  const getLogo = async () => {
    try {
      setlogoLink(await getLogoLink());
    } catch (error) {
      console.log(error);
    }
  };

  // const getBalance = async() =>{
  //     const res = await authServices.getBalance();
  //     if(res)
  //     {
  //         setBalanceData({...res});
  //     }

  // }

  const { menu } = useSelector((state) => state.sports);
  return (
    <Box>
      <Box
        sx={{ bgcolor: "var(--primary)" }}
        className={`${pathname.includes("game-details") ? "" : "sticky-top"}`}
      >
        <NotificationContainer />
        <Box
          className={`app-panel ${!showSearch ? "visible" : ""}`}
          sx={{ height: 60, display: "flex" }}
          justifyContent={"space-between"}
        >
          <Box sx={{ display: "flex" }} alignItems={"center"}>
            <MobileView className="custom-class">
              <LeftSideBar
                openLeftNav={openLeftNav}
                setOpenLeftNav={setOpenLeftNav}
              />
              {showMenu.find(
                (p) =>
                  pathname.includes(p.path) &&
                  p.showMenu &&
                  !pathname.includes("game-details") &&
                  isLargeScreen
              ) || pathname == "/" ? (
                <Box
                  className="pointer"
                  sx={{ padding: "16px 16px", color: "#fff", margin: 0 }}
                  onClick={() => setOpenLeftNav(true)}
                >
                  <CustomIcon
                    className={"menuicon"}
                    name={"menuicon"}
                    color={"#fff"}
                  />
                </Box>
              ) : (
                <Box
                  className="pointer"
                  sx={{ padding: "16px 16px", color: "#fff", margin: 0 }}
                  onClick={() => navigate(-1)}
                >
                  <CustomIcon
                    className={"back-arrow"}
                    name={"back-arrow"}
                    color={"#fff"}
                  />
                </Box>
              )}
            </MobileView>
            <RightSideBar
              className="pointer"
              balanceData={balanceData}
              openRightNav={openRightNav}
              setOpenRightNav={setOpenRightNav}
              handleLogout={handleLogout}
            />
            {/* <Box className="pointer" marginLeft={"10px"} width={"120px"} paddingY={2} onClick={()=>navigate(isBrowser && isLogginIn ?'/sport"' : isMobile? "/sport" : "/sport")}> */}
            <Box
              className="pointer"
              marginLeft={"10px"}
              width={"120px"}
              paddingY={2}
              onClick={() =>
                navigate(
                  isBrowser && isLogginIn ? "/sport" : isMobile ? "/sport" : ""
                )
              }
            >
              {logoLink && (
                <img src={logoLink} alt="" height={"100%"} width={"100%"} />
              )}
            </Box>
            <BrowserView className="custom-class">
              <Box
                className="pointer"
                sx={{
                  paddingLeft: "10px",
                }}
              >
                <Timer />
              </Box>
            </BrowserView>
          </Box>
          {isBrowser ? (
            <Box sx={{ display: "flex" }} alignItems={"center"} padding={1}>
              {isLogginIn ? (
                <InfoBar handleLogout={handleLogout} />
              ) : (
                <LinkText
                  sx={{ mr: "30px" }}
                  onClick={() => setOpenLogin(true)}
                >
                  <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                    <CustomIcon name={"signout"} fontSize={12} />
                    <Typography>Login</Typography>
                  </Stack>
                </LinkText>
              )}
            </Box>
          ) : (
            <Box sx={{ display: "flex" }} alignItems={"center"}>
              <Box
                onClick={() => setShowSearch(true)}
                className="pointer"
                sx={{
                  fontSize: "20px",
                  width: "36px",
                  height: "36px",
                  marginLeft: "var(--sPad)",
                  marginRight: "var(--sPad)",
                  color: "var(--header-button-color)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomIcon name={"search"} color={"#fff"} fontSize={"20px"} />
              </Box>
              {isLogginIn ? (
                <Box
                  className="pointer"
                  bgcolor={"var(--header-right-button-menu-bg-color)"}
                  color={"var(--header-right-button-signup-color)"}
                  height={40}
                  width={70}
                  ml={2}
                  alignItems={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  onClick={() => setOpenRightNav(true)}
                >
                  <CustomIcon name={"user"} fontSize={"14px"} />
                  <Typography lineHeight={1} fontSize={"12px"}>
                    {Math.trunc(balanceData?.available_credit)}
                  </Typography>
                </Box>
              ) : (
                <Button
                  onClick={() => setOpenLogin(true)}
                  sx={{
                    fontSize: 12,
                    padding: "0 10px",
                    marginRight: "5px",
                    backgroundColor: "var(--header-right-button-menu-bg-color)",
                    color: "var(--header-right-button-signup-color)",
                    border:
                      "1px solid var(--header-right-button-menu-bg-color)",
                    fontWeight: "400",
                    minWidth: "auto",
                  }}
                >
                  Login
                </Button>
              )}
            </Box>
          )}
        </Box>

        {isMobile ? (
          <Box>
            <SearchBar show={showSearch} setShow={setShowSearch} />
          </Box>
        ) : (
          <></>
        )}
        <OneClickSetting />
      </Box>
      <BrowserView>
        <SportMenu />
      </BrowserView>
    </Box>
  );
}

const LeftSideBar = ({ openLeftNav, setOpenLeftNav }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { isLogginIn } = useSelector((state) => state.user);
  const { menu } = useSelector((state) => state.sports);

  const renderMenuItem = (info) => {
    let link = info.link;

    if (!info.link) {
      if (info.type === "Casino") link = `/live-casino/${info.provider_id}`;
      else if (info.type === "Sport") {
        if (info.provider_id === "7" || info.provider_id === "4339") {
          let linkrace = "/sport/racing/";
          if (isMobile) {
            linkrace = "/racing/";
          }
          link = `${linkrace}${info.provider_id}`;
        } else {
          link = `/sport/${info.provider_id}`;
        }
      }
    }

    return (
      <>
        <Stack
          className="pointer"
          direction={"row"}
          alignItems={"center"}
          spacing={1}
          sx={{ padding: "8px 0px" }}
          onClick={() => {
            navigate(link);
            setOpenLeftNav(false);
          }}
        >
          <CustomIcon
            className={"lm-" + info?.icon}
            name={info?.icon}
            minWidth={"32px"}
            fontSize={20}
          />
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography sx={{ textTransform: "capitalize", fontWeight: "700" }}>
              {info?.menu_name}{" "}
            </Typography>
            {info.is_new && <span className="new-label">New</span>}
          </Stack>
        </Stack>
        <Divider />
      </>
    );
  };

  return (
    <>
      <Drawer
        SlideProps={{
          easing: "cubic-bezier(0, 0, 0.2, 1)",
          timeout: 300,
        }}
        sx={{
          width: 260,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 260,
            boxSizing: "border-box",
          },
        }}
        onClose={() => setOpenLeftNav(false)}
        open={openLeftNav}
      >
        <Box
          className="pointer"
          sx={{ marginBottom: "80px", padding: "0px 16px", marginTop: "15px" }}
        >
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              sx={{ padding: "8px 0px" }}
              onClick={() => {
                navigate("");
                setOpenLeftNav(false);
              }}
            >
              <CustomIcon
                className={"lm-home"}
                name={"home"}
                fontSize={20}
                minWidth={"32px"}
              />
              <Typography sx={{ fontWeight: "700 !important" }}>
                {"Home"}
              </Typography>
            </Stack>
            <Divider />
          </>
          {isLogginIn && (
            <>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                sx={{ padding: "8px 0px" }}
                onClick={() => {
                  navigate("/my-markets");
                  setOpenLeftNav(false);
                }}
              >
                <CustomIcon
                  className={"lm-mymarkets"}
                  name={"mymarkets"}
                  fontSize={20}
                  minWidth={"32px"}
                />
                <Typography sx={{ fontWeight: "700 !important" }}>
                  {"My Markets"}
                </Typography>
              </Stack>
              <Divider />
            </>
          )}
          {menu?.map((info, index) => renderMenuItem(info))}
          {/* {LeftSideBarTabs.filter((i)=>isLogginIn || (!isLogginIn && !i.checkLogin)).map((info, index) => (
                    <>
                        <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{padding:"8px 0px"}} onClick={()=>{navigate(info?.link);setOpenLeftNav(false)}}>
                        <CustomIcon className={'lm-'+info?.icon} name={info?.icon} minWidth={'32px'} fontSize={20}/>
                            <Typography sx={{textTransform:"capitalize",fontWeight:"700"}} >{info?.name} </Typography>
                    {
                        info.is_new &&
                    <span className="new-label">New</span>
                    }
                        </Stack>
                        <Divider/>
                    </>

                ))} */}
        </Box>
      </Drawer>
    </>
  );
};

const Timer = () => {
  const [timer, setTimer] = useState(moment().format("HH:mm:ss"));
  const [selectedZone, setSelectedZone] = useState(1);
  const [showTimeZones, setShowTimeZones] = useState(false);
  const { isLogginIn, settings } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (selectedZone === 1) {
  //       setTimer(moment().format("HH:mm:ss"));
  //     } else if (selectedZone === 2) {
  //       setTimer(moment().utcOffset("+05:30").format("HH:mm:ss"));
  //     } else {
  //       setTimer(moment().utcOffset("+00:00").format("HH:mm:ss"));
  //     }
  //   }, 1000);
  //   // eslint-disable-next-line
  // }, [timer, selectedZone]);

  useEffect(() => {
    setTimeout(() => {
      if (settings?.utc) {
        // console.log(settings?.utc)
        setTimer(moment().utcOffset(settings?.utc.value).format("HH:mm:ss"));
      } else {
        setTimer(moment().format("HH:mm:ss"));
      }
    }, 1000);
  }, [timer, settings?.utc]);

  useEffect(() => {
    window.addEventListener("mousedown", hideTimeZones);
    return () => window.removeEventListener("mousedown", hideTimeZones);
    // eslint-disable-next-line
  }, [showTimeZones]);

  const hideTimeZones = (e) => {
    // document.body.className.includes("menu-item");
    if (showTimeZones && !e.target.className.includes("menu-item"))
      setShowTimeZones(false);
  };

  const handleSelectZone = (index) => {
    setShowTimeZones(false);
    setSelectedZone(index);
    if (index === 1) {
      dispatch(changeTimeZone({ value: "+05:30", id: index }));
    } else if (index === 2) {
      dispatch(changeTimeZone({ value: "+05:30", id: index }));
    } else {
      dispatch(changeTimeZone({ value: "+00:00", id: "0" }));
    }
  };
  return (
    <Stack direction={"row"} spacing={"5px"}>
      <Typography sx={{ fontSize: 12, color: "white", paddingTop: "2px" }}>
        {moment().format("MMM DD, YYYY")}
      </Typography>
      <Typography sx={{ fontWeight: "bold", fontSize: 15, color: "white" }}>
        {timer}
      </Typography>
      {isLogginIn && (
        <Box display={"inline"} sx={{ fontSize: 12 }}>
          (&nbsp;
          <Box
            sx={{
              display: "inline-table",
              padding: "2px 0",
              position: "relative",
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                color: "#ffecc6",
                cursor: "pointer",
                ":hover": { color: "white" },
              }}
              onClick={() => setShowTimeZones(true)}
            >
              {selectedZone === 1
                ? moment().format("Z")
                : selectedZone === 2
                ? "+05:30"
                : "+00:00"}
              &nbsp;
              {/* <Box
                  sx={{
                    width: 8.3,
                    height: 6,
                    bgcolor: "#ffecc6",
                    display: "inline-table",
                  }}
                ></Box> */}
              <CustomIcon name={"sm-down-arrow"} fontSize={14} />
            </Box>
            <Box
              component={"ul"}
              className="dropdown timer"
              sx={{
                visibility: showTimeZones ? "visible !important" : "hidden",
              }}
            >
              <Box component={"li"}>
                <Box
                  onClick={() => handleSelectZone(0)}
                  className={"menu-item"}
                >
                  System time - (GMT +00:00)
                </Box>
              </Box>
              <Box component={"li"}>
                <Box
                  onClick={() => handleSelectZone(1)}
                  className={"menu-item"}
                >
                  Your computer time - (GMT {moment().format("Z")})
                </Box>
              </Box>
              <Box component={"li"}>
                <Box
                  onClick={() => handleSelectZone(2)}
                  className={"menu-item"}
                >
                  India Standard time - (GMT +05:30)
                </Box>
              </Box>
            </Box>
          </Box>
          &nbsp;)
        </Box>
      )}
    </Stack>
  );
};
const RightSideBar = ({
  openRightNav,
  setOpenRightNav,
  balanceData,
  handleLogout,
}) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleClick = (info) => {
    console.log("info..........................", info);
    if (info.link) navigate(info.link);
    if (info.name === "Signout") {
      handleLogout();
    }
    setOpenRightNav(false);
  };

  // const handleLogout = () => {
  //   dispatch(clearUser());
  //   dispatch(clearStake());
  //   dispatch(userDisconnected());
  //   userSocket.disconnect();
  //   navigate("", { replace: true });
  //   window.location.reload();
  // };

  return (
    <>
      <Drawer
        SlideProps={{
          easing: "cubic-bezier(0, 0, 0.2, 1)",
          timeout: 300,
        }}
        sx={{
          width: 260,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 260,
            boxSizing: "border-box",
          },
        }}
        onClose={() => setOpenRightNav(false)}
        anchor="right"
        open={openRightNav}
      >
        <Box sx={{ padding: "0px 16px", paddingBottom: "0px" }}>
          <Box
            sx={{
              padding: "16px 0px",
              textAlign: "center",
              fontWeight: 700,
              fontSize: "20px",
            }}
          >
            {user?.user_name}
          </Box>
          <Divider />
          <Box>
            <Stack
              sx={{
                alignItems: "center",
                fontWeight: 700,
                margin: "10px 5px",
                height: "fit-content",
              }}
              direction={"row"}
              spacing={2}
            >
              <Iconify color={"#000"} fontSize={"20px"} icon={"mdi:bank"} />
              <Typography
                sx={{ marginLeft: "10px !important" }}
                color={"#000"}
                fontWeight={700}
              >
                Balance Information
              </Typography>
            </Stack>
            <Stack direction={"column"} spacing={2}>
              <Stack
                justifyContent={"space-between"}
                sx={{ marginTop: "10px !important" }}
                fontSize={"14px"}
                direction={"row"}
              >
                <Typography variant="body3" fontSize={"14px"} color={"#000"}>
                  Available Credit:
                </Typography>
                <Typography
                  variant="body3"
                  color={"#000"}
                  fontWeight={"bolder"}
                >
                  {Math.trunc(balanceData?.available_credit)}
                </Typography>
              </Stack>
              <Stack
                justifyContent={"space-between"}
                sx={{ marginTop: "10px !important" }}
                fontSize={"14px"}
                direction={"row"}
              >
                <Typography variant="body3" fontSize={"14px"} color={"#000"}>
                  Credit Limit:
                </Typography>
                <Typography variant="body3" color={"#000"}>
                  {Math.trunc(balanceData?.credit_limit)}
                </Typography>
              </Stack>
              <Stack
                justifyContent={"space-between"}
                sx={{ marginTop: "10px !important" }}
                fontSize={"14px"}
                direction={"row"}
              >
                <Typography variant="body3" fontSize={"14px"} color={"#000"}>
                  Winnings:
                </Typography>
                <Typography
                  variant="body3"
                  className={`${
                    Math.trunc(balanceData?.winnings) >= 0
                      ? "positive"
                      : "negative"
                  }`}
                >
                  {Math.trunc(balanceData?.winnings)
                    ? Math.trunc(balanceData?.winnings)
                    : ""}
                </Typography>
              </Stack>
              <Stack
                justifyContent={"space-between"}
                sx={{ marginTop: "10px !important" }}
                direction={"row"}
                marginBottom={"16px !important"}
              >
                <Typography variant="body3" fontSize={"14px"} color={"#000"}>
                  Net Exposure:
                </Typography>
                <Typography variant="body3" className="negative">
                  {Math.trunc(balanceData?.total_net_exposure)}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Divider />
          {/* <Stack sx={{alignItems:'center',fontWeight:700,margin:"8px 0",height:'fit-content'}} direction={"row"} spacing={2} onClick={()=>{navigate("settings");setOpenRightNav(false)}}>
                           
                            <CustomIcon name={"setting"} sx={{width:"25px"}} fontSize={"14px"} />
                            <Typography color={"var(--primary)"} marginLeft={"8px !important"} fontWeight={700} fontSize={"14px"}>Settings</Typography>
                    </Stack>
                    <Divider/> */}
          {RightSideBarTabs?.map((info, index) => (
            <>
              <Stack
                direction={"row"}
                alignItems={"center"}
                color={"#000"}
                spacing={1}
                sx={{ padding: "10px 0px" }}
                className="pointer"
                onClick={() => handleClick(info)}
              >
                <CustomIcon
                  name={info?.icon}
                  color={"#000"}
                  sx={{ width: "25px" }}
                  fontSize={"14px"}
                />
                <Typography fontWeight={700} fontSize={"16px"} color={"#000"}>
                  {info?.name}
                </Typography>
              </Stack>
              <Divider />
            </>
          ))}
          {/* <TestBanner/> */}
        </Box>
      </Drawer>
    </>
  );
};

const InfoBar = ({ handleLogout }) => {
  const { user } = useSelector((state) => state.user);
  return (
    <Box
      sx={{
        float: "right",
        margin: "0",
        padding: "0px 20px",
      }}
    >
      <Box
        component={"ul"}
        sx={{
          listStyle: "outside none none",
          marginBottom: "0px",
          float: "right",
          li: {
            display: "inline-table",
            verticalAlign: "middle",
            paddingLeft: "20px",
          },
        }}
      >
        <Box component={"li"}>
          <SearchEvents />
        </Box>
        <Box component={"li"}></Box>
        <Box component={"li"} fontSize={"11px"}>
          <Box component={"p"}>Logged in as {user.user_name}</Box>
          <Box component={"p"}>
            Last logged in:{" "}
            {moment.unix(user?.last_login).format("DD/MM/YYYY HH:mm")}
          </Box>
        </Box>
        <Box component={"li"}>
          <AccountMenu />
        </Box>
        <Box component={"li"}>
          <LinkText onClick={handleLogout}>
            <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
              <CustomIcon name={"signout"} fontSize={12} />
              <Typography variant="body2">Logout</Typography>
            </Stack>
          </LinkText>
        </Box>
      </Box>
    </Box>
  );
};

const SearchBar = ({ show, setShow }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const { sports } = useSelector((state) => state.sports);
  const navigate = useNavigate();

  useEffect(() => {
    if (!show) {
      setQuery("");
      setResults([]);
    }
  }, [show]);

  useEffect(() => {
    if (query && query.length > 2) {
      searchEvent();
    } else {
      setResults([]);
    }
  }, [query]);

  async function searchEvent() {
    const res = await eventServices.searchEventByName(query);
    if (res && res.length > 0) {
      setResults(res);
    } else {
      setResults([]);
    }
  }

  const handleClick = (event) => {
    console.log(event);
    if (sports && sports.length > 0) {
      const sport = sports.find((_sport) => _sport.game_id === event.game_id);
      setShow(false);
      navigate(
        `/sport/${sport.game_name}/game-details/${
          event ? event.provider_event_id : 37373737
        }/${event ? event.league_id : 37373737}`,
        { state: event }
      );
    }
  };

  return (
    <>
      <Box className={`app-panel search-panel ${show ? "visible" : ""}`}>
        <input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          type="text"
          className="search-term "
          placeholder="Search Events"
        />
        <Button className="button" onClick={() => setShow(false)}>
          <CustomIcon name={"modal-close"} />
        </Button>
      </Box>
      <Box className={`search-result ${show ? "visible" : ""}`}>
        <Box className={`app-list`}>
          {query && query.length > 2 ? (
            results.length > 0 ? (
              results?.map((result) => {
                return (
                  <Box
                    className={`app-list-item`}
                    onClick={() => handleClick(result)}
                  >
                    <Box className={"search-event-title"}>
                      {result.event_name}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box className={`app-list-item`}>
                <Box className={"search-event-title"}>
                  {" "}
                  No events found matching '{query}'{" "}
                </Box>
              </Box>
            )
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

const AccountMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("mousedown", hideMenu);
    return () => window.removeEventListener("mousedown", hideMenu);
    // eslint-disable-next-line
  }, [openMenu]);

  const hideMenu = (e) => {
    // document.body.className.includes("menu-item");
    if (openMenu && !e.target.className.includes("menu-item"))
      setOpenMenu(false);
  };

  const handleClick = (link) => {
    setOpenMenu(false);
    navigate(`/account/${link}`);
  };

  const menu = [
    {
      link: "my-bets",
      title: "My Bets",
    },
    {
      link: "betting-pnl",
      title: "Betting Profit and Loss",
    },
    // {
    //   link: "accountStatement",
    //   title: "Account Statement",
    // },
    {
      link: "transfer",
      title: "Transfer Statement",
    },
    {
      link: "settings",
      title: "Settings",
    },
    {
      link: "change-password",
      title: "Change password",
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        margin: "0 auto",
        padding: "10px",
      }}
    >
      <Stack direction={"row"} spacing={0.2} alignItems={"normal"}>
        <Box sx={{ marginRight: "3px !important" }}>
          <CustomIcon name={"uniF013"} fontSize={12} color={"#ffecc6"} />
        </Box>

        <LinkText onClick={() => setOpenMenu(true)}>
          <Typography variant="body2">Account</Typography>
        </LinkText>
      </Stack>
      <Box
        component={"ul"}
        className="dropdown"
        sx={{ visibility: openMenu ? "visible !important" : "hidden" }}
      >
        {menu.map((item, index) => {
          return (
            <Box
              key={index}
              component={"li"}
              onClick={() => handleClick(item.link)}
            >
              <Box className={"menu-item"}>{item.title}</Box>
            </Box>
          );
        })}
        {/* <Box component={"li"}>
                    <Box  className={"menu-item"}>
                        My Bets
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Betting Profit and Loss
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Account Statement
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Transfer Statement
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Security
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Change password
                    </Box>
                </Box> */}
      </Box>
    </Box>
  );
};

const SearchEvents = () => {
  const [active, setActive] = useState(false);
  const [query, setQuery] = useState("");
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const handleBlurr = () => {
    setTimeout(() => {
      setActive(false);
      setQuery("");
    }, 300);
  };

  useEffect(() => {
    if (query && query.length > 2) onSearchEvents();
  }, [query]);

  const onSearchEvents = async () => {
    try {
      const res = await eventServices.searchEventByName(query);
      if (res) {
        setEvents(res);
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const { sports } = useSelector((state) => state.sports);
  const handleClick = (event) => {
    console.log(event);
    if (sports && sports.length > 0) {
      const sport = sports.find((_sport) => _sport.game_id === event.game_id);
      navigate(
        `/sport/${sport.game_name}/game-details/${
          event ? event.provider_event_id : 37373737
        }/${event ? event.league_id : 37373737}`,
        { state: event }
      );
    }
    // console.log(data);
    // navigate(
    //   `/sport/${data.game_name}/game-details/${
    //     event ? event.provider_event_id : 37373737
    //   }/${event ? event.league_id : 37373737}`,
    //   { state: event }
    // );
    // navigate(
    //   `/sport/${data.provider_game_id}/${data.league_id}/${data.game_event_id}`
    // );
  };

  return (
    <Box position={"relative"}>
      <Box
        sx={{
          zIndex: "1000",
          position: "absolute",
          top: active ? "-9px" : "50%",
          left: "34px",
          transform: "translateY(-50%)",
          fontSize: active ? "10px" : "12px",
          color: active ? "#fff" : "#666",
          transition: "top 0.3s ease, font-size 0.3s ease, color 0.5s ease",
          userSelect: "none",
          pointerEvents: "none",
        }}
      >
        Search Events
      </Box>
      <Box
        sx={{
          display: "inline-block",
          position: "relative",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "30px",
            marginTop: "-8px",
            fontSize: "14px",
            textAlign: "center",
            pointerEvents: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Box sx={{ bgcolor: "#999999", width: 14, height: 14 }}></Box> */}
          <CustomIcon color={"#999999"} name={"search"} fontSize={14} />
        </Box>
        <Box
          component={"input"}
          onFocus={() => setActive(true)}
          onBlur={handleBlurr}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{
            height: "36px",
            padding: "8px 4px",
            fontSize: "12px",
            paddingLeft: "30px !important",
            width: "300px",
            border: "1px solid #DDDDDD",
            transition: "linear 0.2s",
            borderRadius: "3px",
            background: "#FFFFFF",
            color: "#666666",
            maxWidth: "100%",
            "&:focus": {
              borderColor: "#0f2327",
              background: "#fff",
              color: "#0f2327",
            },
            "&:focus-visible": {
              outline: "none",
            },
          }}
        ></Box>
      </Box>
      <Box
        component={"ul"}
        sx={{
          position: "absolute",
          background: "#fff",
          border: "1px solid #DDDDDD",
          borderTop: "none",
          width: "300px",
          zIndex: "999",
          display: query.length > 2 ? "block" : "none",
          li: {
            paddingLeft: "0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#DDDDDD",
              textDecoration: "none",
              color: "#24555e !important",
            },
            p: {
              margin: "0",
              lineHeight: "1.8",
              padding: "5px 10px",
              display: "block",
              width: "100%",
              color: "#0f2327",
              fontSize: "12px",
            },
          },
        }}
      >
        {events && events.length === 0 ? (
          <Box component={"li"}>
            <Box component={"p"}>No events found matching '{query}'</Box>
          </Box>
        ) : (
          events &&
          events.map((event) => {
            return (
              <Box onClick={() => handleClick(event)} component={"li"}>
                <Box component={"p"}>{event.event_name}</Box>
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};

const OneClickSetting = () => {
  const { is_one_click_bet_enable, active_one_click_stake } = useSelector(
    (state) => state.stake
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSwitchOnclick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(changeOneClickStatus());
  };

  return (
    <>
      {is_one_click_bet_enable && !(pathname === "/settings") && (
        <Box
          sx={{
            background: "var(--negative)",
            color: "var(--white)",
            position: "relative",
            textAlign: "center",
            padding: "var(--mPad) 0",
            fontSize: "10px",
            textDecoration: "underline",
          }}
          onClick={() => navigate("/settings")}
        >
          1 CLICK BET IS ON - ACTIVE STAKE {active_one_click_stake}
          <Box
            className={"-warning"}
            sx={{
              display: "block",
              position: "absolute",
              left: "var(--mPad)",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Box sx={{ height: "24px", display: "inline-block" }}>
              <Box
                onClick={handleSwitchOnclick}
                className={`toggle-container ${true ? "on" : "off"}`}
              >
                <Box className={"toggle-button"}></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const NotificationContainer = () => {
  const { isLogginIn, user } = useSelector((state) => state.user);
  const [msg, setMsg] = useState();
  const [showMsg, setShowMsg] = useState(true);
  useEffect(() => {
    if (isLogginIn) {
      getNotification();
    }
  }, [isLogginIn]);

  const getNotification = async () => {
    try {
      const res = await accountServices.getNotification(user.user_id);
      if (res && res.notification) {
        setMsg(res.notification);
      }
    } catch (error) {}
  };
  if (msg && showMsg && isLogginIn) {
    return (
      <Box
        sx={{
          display: "flex",
          padding: "10px 0",
          color: "var(--notifications-container-color)",
          background: "var(--notifications-container-bg-color)",
          position: "relative",
          lineHeight: 1.15,
        }}
      >
        <Box
          sx={{
            display: "inline-block",
            position: "relative",
            flexGrow: "1",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              wordBreak: "keep-all",
              whiteSpace: "nowrap",
              paddingLeft: "100%",
              willChange: "transform",
              animationName: "marquee",
              // animationDuration:`${msg?.length * 200}ms`,
              animationDuration: `15000ms`,
              animationIterationCount: "infinite",
              animationTimingFunction: "linear",
              fontSize: 14,
              "@keyframes marquee": {
                "0%": {
                  transform: "translateZ(0)",
                },
                "100%": {
                  transform: "translate3d(-100%,0,0)",
                },
              },
            }}
          >
            {msg}
          </Box>
        </Box>
        <Box
          onClick={() => setShowMsg(false)}
          sx={{
            padding: "0 15px",
            display: "inline-block",
            textDecoration: "none",
            color: "var(--notifications-container-close-btn-color)",
          }}
        >
          <span _ngcontent-ijr-c86=""> x </span>
        </Box>
      </Box>
    );
  }
  return <></>;
};
