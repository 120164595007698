import { createSlice } from "@reduxjs/toolkit";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
const initialState = {
  values: [100, 500, 1000],
  is_one_click_bet_enable: false,
  one_click_stake: [0, 0, 0],
  active_one_click_stake: 0,
};

const stakesSlice = createSlice({
  name: "stakes",
  initialState: initialState,
  reducers: {
    changeStake: (state, action) => {
      state.values = [...action.payload];
    },
    changeOneClickStatus: (state, action) => {
      state.is_one_click_bet_enable = !state.is_one_click_bet_enable;
    },
    changeOneClickStake: (state, action) => {
      state.one_click_stake = [...action.payload];
    },
    changeActiveOneClickStake: (state, action) => {
      state.active_one_click_stake = action.payload;
    },
    clearStake: () => initialState,
  },
});

export const {
  changeStake,
  clearStake,
  changeOneClickStatus,
  changeOneClickStake,
  changeActiveOneClickStake,
} = stakesSlice.actions;

export default stakesSlice.reducer;
