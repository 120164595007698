import { Box, Button, Dialog } from '@mui/material';
import { calcProfit, showNumberWithDecimal } from '../../utils/functions';

export default function BetConfirmation({
  open,
  onPlaceBet = () => {},
  onCancel = () => {},
  betData,
}) {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onPlaceBet();
    }
  };

  return (
    <Dialog
      open={open}
      onKeyPress={handleKeyPress}
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: '10px',
          maxWidth: '600px',
          width: '90%',
        },
      }}
    >
      <Box
        className={`modal-container bet-confirm-modal ${
          betData.type === 'back' ? 'back' : 'lay'
        }`}
      >
        <Box className={'modal-component-wrap'}>
          <Box className={'modal-header -has-event-name'}>
            {betData.event_name}
            <Box className={'subtitle'}>
              {betData.isFancy
                ? ''
                : betData.type === 'back'
                ? 'Backing'
                : 'Laying'}{' '}
              <Box component={'strong'}>{betData.runner_name}</Box>
            </Box>
          </Box>
          <Box className={'modal-content'}>
            <Box component={'ul'} className="details">
              {betData.isFancy && (
                <Box component={'li'}>
                  <Box component={'p'}>Runs</Box>
                  <Box component={'p'}>
                    <Box component={'em'}>{betData.line}</Box>
                  </Box>
                </Box>
              )}
              <Box component={'li'}>
                <Box component={'p'}>Odds (H-J)</Box>
                <Box component={'p'}>
                  <Box component={'em'}>{betData.odds}</Box>
                </Box>
              </Box>
              <Box component={'li'}>
                <Box component={'p'}>Stake</Box>
                <Box component={'p'}>
                  <Box component={'em'}>{betData.bet_amount}</Box>
                </Box>
              </Box>
              <Box component={'li'}>
                <Box component={'p'}>
                  {betData.type === 'back' ? 'Profit' : 'Liability'}
                </Box>
                <Box component={'p'}>
                  <Box component={'em'}>
                    {Math.trunc(
                      calcProfit(
                        betData.odds,
                        betData.bet_amount,
                        betData.wholeOdds
                      )
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={'modal-actions'}>
            <Button onClick={onCancel} className="button cancel">
              Cancel
            </Button>
            <Button
              onClick={onPlaceBet}
              variant="contained"
              className="button submit"
            >
              <Box className={'place-bet-btn__wrap'}>
                <Box component={'span'} className="content action">
                  Place Bet
                </Box>
                <Box component={'span'} className="content info-text">
                  {betData.type === 'back' ? 'Profit' : 'Liability'}:{' '}
                  {Math.trunc(
                    calcProfit(
                      betData.odds,
                      betData.bet_amount,
                      betData.wholeOdds
                    )
                  )}
                </Box>
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
